import { Spin } from "antd"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import { useStateValue } from "../_shared/context/AppStateStore"
import useLoginCheck from "../_shared/hooks/useLoginCheck"
import AuthPage from "./AuthPage"
import LoggedApp from "./LoggedApp"

// ----------------------------------------------------------------------------

function _RoutedApp(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------
    const [{ user }] = useStateValue()
    const checkingLogin = useLoginCheck()

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            <Spin spinning={checkingLogin} style={{ height: 600 }} size="large">
                {!checkingLogin && <>{user ? <LoggedApp /> : <AuthPage />}</>}
            </Spin>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_RoutedApp.propTypes = {
    className: PropTypes.string.isRequired,
}

_RoutedApp.defaultProps = {}

// ----------------------------------------------------------------------------

const RoutedApp = styled(_RoutedApp)`
    & {
    }
`
// ----------------------------------------------------------------------------

export default RoutedApp
