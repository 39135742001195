export function exists(val) {
    return val !== undefined && val !== null
}

export function rangeMap(value, inRange, outRange) {
    const [inMin, inMax] = inRange
    const [outMin, outMax] = outRange
    const scaled = (value - inMin) / (inMax - inMin)
    const result = scaled * (outMax - outMin) + outMin
    return result
}
