import PropTypes from "prop-types"
import React, { useEffect } from "react"
import styled from "styled-components"

import BottomSheet from "../../../../_shared/components/BottomSheet"
import AppInput from "../../../../_shared/components/FormItems/AppInput"
import Checkbox from "../../../../_shared/components/FormItems/Checkbox"
import { useStateValue } from "../../../../_shared/context/AppStateStore"
import useForm from "../../../../_shared/hooks/useForm"

// ----------------------------------------------------------------------------

function _MachinesBottomSheet(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, open, defaultValues, onCancel, onConfirm } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [{ user }] = useStateValue()
    const [formValues, setFormValue, setFormValues, clearFormValues] = useForm({
        parent: user.email,
    })
    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        if (!open) {
            clearFormValues()
        } else if (defaultValues) {
            if (defaultValues.id) {
                setFormValues(defaultValues)
            } else {
                setFormValues({ ...defaultValues, parent: user.username })
            }
        }
        // eslint-disable-next-line
    }, [defaultValues, open])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function handleFormValueChange(key) {
        return function (value) {
            setFormValue(key, value)
        }
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <BottomSheet
            title={defaultValues?.id ? "Modifica utente" : "Crea utente"}
            description="Compila tutti i campi per procedere con la creazione dell'utente"
            open={open}
            onCancel={onCancel}
            onConfirm={() => onConfirm(formValues)}
            className={`${className}`}
            snapPoint={80}
        >
            <span className="helper">
                Compila i campi qui sotto per procedere con la creazione della
                macchina.
            </span>
            <div className="content">
                <AppInput
                    className="field"
                    label="Nome"
                    value={formValues?.name}
                    onChange={handleFormValueChange("name")}
                />
                <AppInput
                    className="field"
                    label="E-mail"
                    value={formValues?.email}
                    onChange={handleFormValueChange("email")}
                />
                <AppInput
                    className="field"
                    label="Password"
                    value={formValues?.password}
                    onChange={handleFormValueChange("password")}
                />

                <Checkbox
                    label="L'utente è un amministratore"
                    value={formValues?.admin}
                    onChange={handleFormValueChange("admin")}
                    className="field"
                />
                {/* <label className="check-wrapper">
                    <Checkbox
                        checked={formValues?.admin}
                        onChange={(v) =>
                            handleFormValueChange("admin")(v.target.checked)
                        }
                    />
                    <FieldLabel className="">
                        L'utente è un amministratore
                    </FieldLabel>
                </label> */}
            </div>
        </BottomSheet>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_MachinesBottomSheet.propTypes = {
    className: PropTypes.string.isRequired,
    onConfirm: PropTypes.func,
}

_MachinesBottomSheet.defaultProps = {}

// ----------------------------------------------------------------------------

const MachinesBottomSheet = styled(_MachinesBottomSheet)`
    & {
        .helper {
            display: block;
            margin-bottom: 20px;
            font-size: 18px;
        }
        .content {
            /* display: flex; */
            .field {
                width: 300px;
                margin-bottom: 20px;
            }
        }
        .check-wrapper {
            display: flex;
            gap: 10px;
        }
    }
`
// ----------------------------------------------------------------------------

export default MachinesBottomSheet
