import "../../index.css"

import PropTypes from "prop-types"
import React from "react"
import {
    ThemeProvider as StyledComponentsThemeProvider,
    createGlobalStyle,
    css,
} from "styled-components"

// ----------------------------------------------------------------------------

export default function ThemeProvider(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { children } = props

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    const theme = {
        spacing: {
            xs: 7,
            s: 20,
            m: 40,
            l: 60,

            formItemHeight: 38,
            screenPadding: 200,
        },

        typography: {},

        shadow: css`
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
        `,

        screenSizes: {
            sm: "576px",
            md: "768px",
        },
        colors: {
            primary: "#0062FF",

            error: "#EA4335",
            error200: "rgba(234, 67, 53, .25)",
            error300: "#FFAEB3",

            grey700: "#92929D",
            grey600: "#696974",
            grey500: "#C7C7C7",
            grey400: "#DCDCE0",
            grey300: "#F1F1F5",
            grey200: "#FAFAFB",
            grey100: "#e2e2ea",

            light: "#fff",
            dark: "#000",

            tableRowSelected: "#8B9BB3",
            inputBorder: "#78788C",
        },
    }

    /* eslint-disable react/prop-types  */
    const GlobalStyle = createGlobalStyle`
    `
    /* eslint-enable react/prop-types  */

    return (
        <StyledComponentsThemeProvider theme={theme}>
            <GlobalStyle />
            {children}
        </StyledComponentsThemeProvider>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

ThemeProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
}

ThemeProvider.defaultProps = {}
