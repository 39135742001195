import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

// ----------------------------------------------------------------------------

function _PageTitle(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return <h1 className={`${className}`}>{props.children}</h1>
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_PageTitle.propTypes = {
    className: PropTypes.string.isRequired,
}

_PageTitle.defaultProps = {}

// ----------------------------------------------------------------------------

const PageTitle = styled(_PageTitle)`
    & {
        text-align: center;
    }
`
// ----------------------------------------------------------------------------

export default PageTitle
