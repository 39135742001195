import { Dropdown } from "antd"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import { ReactComponent as DropdownIcon } from "../../../../assets/icons/dropdown.svg"
import { useStateValue } from "../../../../context/AppStateStore"

// ----------------------------------------------------------------------------

function _HeaderRight(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [{ user }, dispatch] = useStateValue()

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function renderUserDropdown() {
        return (
            <div className="user-dropdown">
                <div
                    className="logout-btn"
                    onClick={() => dispatch({ type: "SIGN_OUT" })}
                >
                    <span>Logout</span>
                </div>
            </div>
        )
    }

    function renderUser() {
        return (
            <Dropdown
                overlay={renderUserDropdown()}
                trigger="click"
                getPopupContainer={(node) => node.parentElement}
            >
                <div className="user">
                    <span className="username">
                        {user?.username ?? user?.fullName ?? user?.businessName}
                    </span>
                    <DropdownIcon />
                </div>
            </Dropdown>
        )
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return <div className={`${className}`}>{renderUser()}</div>
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_HeaderRight.propTypes = {
    className: PropTypes.string.isRequired,
}

_HeaderRight.defaultProps = {}

// ----------------------------------------------------------------------------

const HeaderRight = styled(_HeaderRight)`
    & {
        display: flex;
        align-items: center;
        justify-content: center;

        .search {
            margin-right: ${({ theme }) => theme.spacing.s}px;
        }
        .settings {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: ${({ theme }) => theme.spacing.m}px;
            cursor: pointer;
        }
        .user {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            .username {
                display: block;
                margin-right: ${({ theme }) => theme.spacing.xs}px;
            }
        }
        .user-dropdown {
            background-color: white;
            border-radius: 10px;
            ${({ theme }) => theme.shadow};
            overflow: hidden;
            .logout-btn {
                height: ${({ theme }) => theme.spacing.formItemHeight}px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0px ${({ theme }) => theme.spacing.s}px;
                cursor: pointer;
                transition: all 200ms ease;
                font-weight: 500;
                &:hover {
                    background-color: ${({ theme }) => theme.colors.error200};
                    color: ${({ theme }) => theme.colors.error};
                }
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default HeaderRight
