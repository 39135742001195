import PropTypes from "prop-types"
import React from "react"
import { FaChartLine } from "react-icons/fa"
import { FiEdit3, FiEye, FiTrash2 } from "react-icons/fi"
import styled from "styled-components"

import FloatingButton from "../FloatingButton"

// ----------------------------------------------------------------------------

function _TableActions(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, onEdit, onDelete, onViewChart, onView } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function renderEdit() {
        if (!onEdit) {
            return
        }
        return <FloatingButton onClick={onEdit} icon={<FiEdit3 />} />
    }

    function renderDelete() {
        if (!onDelete) {
            return
        }
        return <FloatingButton onClick={onDelete} icon={<FiTrash2 />} />
    }

    function renderViewChart() {
        if (!onViewChart) {
            return
        }
        return <FloatingButton onClick={onViewChart} icon={<FaChartLine />} />
    }

    function renderView() {
        if (!onView) {
            return
        }
        return <FloatingButton onClick={onView} icon={<FiEye />} />
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            {renderView()}
            {renderViewChart()}
            {renderEdit()}
            {renderDelete()}
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_TableActions.propTypes = {
    className: PropTypes.string.isRequired,
}

_TableActions.defaultProps = {}

// ----------------------------------------------------------------------------

const TableActions = styled(_TableActions)`
    & {
        display: flex;
        align-items: center;
        gap: 10px;
    }
`
// ----------------------------------------------------------------------------

export default TableActions
