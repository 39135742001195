import PropTypes from "prop-types"
import React, { useContext, useEffect, useMemo, useState } from "react"
import styled from "styled-components"

import * as api from "../../../../../../api"
import AppSelect from "../../../../../_shared/components/FormItems/AppSelect"
import SteppedBottomSheet from "../../../../../_shared/components/SteppedBottomSheet"
import { useStateValue } from "../../../../../_shared/context/AppStateStore"
import { _WizardContext } from "../_context/wizardContext"

const { Step } = SteppedBottomSheet
// ----------------------------------------------------------------------------

function _AxesStep(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, setLoading } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const { state, dispatch } = useContext(_WizardContext)
    // context state destructuring

    const { sensor, selectedVector, datas } = state
    const [{ token }] = useStateValue()
    const [vectors, setVectors] = useState()
    const [selectedOption, setSelectedOption] = useState()

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    const vectorOptions = useMemo(() => {
        if (!vectors) return []

        const vector =
            vectors &&
            Object.keys(vectors).map((key) => ({
                label: key,
                value: key,
            }))

        return vector
    }, [selectedOption, vectors])

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        setLoading(true)
        fetchVectors()
            .then((values) => {
                // filter out axes with all 0 values
                const vectors = {}
                Object.keys(values?.data ?? {}).forEach((key) => {
                    const vv = Object.values(values?.data[key])
                    if (!vv.every((v) => v === 0)) {
                        vectors[key] = values?.data[key]
                    }
                })
                setVectors(vectors)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    async function fetchVectors() {
        try {
            const response = await api.createResource("/ensys_app", token, {
                cmd: "countBatch",
                data: JSON.stringify({
                    start: datas[0],
                    end: datas[1],
                    sensor_id: sensor.id,
                }),
            })
            const data = await JSON.parse(response)
            return data
        } catch (e) {
            // eslint-disable-next-line
            console.log(e)
        }
    }

    function setSelectedVector(value) {
        const availableData = value.map((val) => Object.keys(vectors[val]))

        dispatch({ type: "SET_SELECTED_VECTOR", payload: value })
        dispatch({
            type: "SET_AVAILABLE_DATA",
            payload: availableData,
        })
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <Step.Wrapper>
            <Step.Header
                title={`${state.label} - Sensore ${state.sensor.id}`}
                subTitle="Seleziona gli assi su cui effettuare le analisi"
            />
            <Step.Content>
                <div className={`${className}`}>
                    <AppSelect
                        className="field"
                        multiple
                        allowClear
                        value={selectedVector && selectedVector}
                        onChange={(v) => {
                            setSelectedOption(v)
                            setSelectedVector(v)
                        }}
                        options={vectorOptions}
                        label="Assi"
                        placeholder="Select a vector"
                        showArrow
                    />
                </div>
            </Step.Content>
            <Step.Footer
                canGoNext={selectedVector && selectedVector.length > 0}
            />
        </Step.Wrapper>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_AxesStep.propTypes = {
    className: PropTypes.string.isRequired,
}

_AxesStep.defaultProps = {}

// ----------------------------------------------------------------------------

const AxesStep = styled(_AxesStep)`
    & {
        .field {
            width: 300px;
            margin-right: 10px;
        }

        .content {
            display: flex;
            max-width: 50%;
            .field {
                width: 300px;
                margin-top: 15px;
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default AxesStep
