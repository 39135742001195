import _ from "lodash"

import { rangeMap } from "../../../../../_shared/toolkit"

export function getDimensions(containerWidth, data) {
    const MARGIN = { top: 0, right: 0, bottom: 0, left: 0 }
    const lx = data.length
    const ly = data[0].length
    const isHorizontal = lx > ly
    const ratio = lx / ly
    let width, height
    if (isHorizontal) {
        width = containerWidth
        height = _.clamp(width / ratio, 200, 500)
    } else {
        height = 500
        width = _.clamp(height * ratio, 300, containerWidth)
    }
    const boundsWidth = width - MARGIN.left - MARGIN.right
    const boundsHeight = height - MARGIN.top - MARGIN.bottom
    return {
        width,
        height,
        boundsWidth,
        boundsHeight,
    }
}

export function renderXTicks(ticks, limit = 5) {
    const a = _.range(limit)
    const inRange = [0, limit - 1]
    const outRange = [0, ticks.length - 1]
    const posRange = [0, 100]
    const result = a
        .map((i) => {
            return rangeMap(i, inRange, outRange)
        })
        .map((i) => {
            return ticks[Math.round(i)]
        })
    const pos = a.map((i) => {
        return rangeMap(i, inRange, posRange)
    })
    return result.map((tick, i) => {
        const offsetLeft = pos[i]
        return (
            <div
                key={tick}
                className="xtick"
                style={{ left: `${offsetLeft}%` }}
            >
                {tick}
            </div>
        )
    })
}

export function renderyTicks(min, max, limit = 5) {
    const a = _.range(limit)
    const inRange = [0, limit - 1]
    const outRange = [min, max]
    const posRange = [0, 100]
    const result = a
        .map((i) => {
            return rangeMap(i, inRange, outRange)
        })
        .map((i) => {
            return Math.round(i)
        })
    result.reverse()
    const pos = a.map((i) => {
        return rangeMap(i, inRange, posRange)
    })
    return result.map((tick, i) => {
        const offsetTop = pos[i]
        return (
            <div key={tick} className="ytick" style={{ top: `${offsetTop}%` }}>
                {tick}
            </div>
        )
    })
}

export function getDataImage(data, colorScale) {
    if (!data) {
        return ""
    }
    const width = data.length
    const height = data[0].length
    const canvas = document.createElement("canvas")
    canvas.width = width
    canvas.height = height

    const c = canvas.getContext("2d")
    data.forEach((xAxis, ix) => {
        xAxis.reverse()
        xAxis.forEach((yAxis, iy) => {
            c.fillStyle = colorScale(yAxis)
            c.fillRect(ix, iy, 1, 1)
        })
    })

    canvas.style.opacity = 1
    canvas.style.position = "fixed"
    document.body.appendChild(canvas)
    const png = canvas.toDataURL()
    document.body.removeChild(canvas)
    return png
}
