import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import Content from "../Content"
import Footer from "../Footer"
import Header from "../Header"

// ----------------------------------------------------------------------------

function _Wrapper(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, children } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    const header = children?.find((child) => child.type === Header)
    const content = children?.find((child) => child.type === Content)
    const footer = children?.find((child) => child.type === Footer)

    return (
        <div className={`${className}`}>
            {header}
            {content}
            {footer}
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Wrapper.propTypes = {
    className: PropTypes.string.isRequired,
}

_Wrapper.defaultProps = {}

// ----------------------------------------------------------------------------

const Wrapper = styled(_Wrapper)`
    & {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
        width: 100%;
    }
`
// ----------------------------------------------------------------------------

export default Wrapper
