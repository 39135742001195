import PropTypes from "prop-types"
import React, { useEffect, useMemo } from "react"
import styled from "styled-components"

import BottomSheet from "../../../../_shared/components/BottomSheet"
import AppDatePicker from "../../../../_shared/components/FormItems/AppDatePicker"
import AppInput from "../../../../_shared/components/FormItems/AppInput"
import AppSelect from "../../../../_shared/components/FormItems/AppSelect"
import { useStateValue } from "../../../../_shared/context/AppStateStore"
import useForm from "../../../../_shared/hooks/useForm"
import useRestResource from "../../../../_shared/hooks/useRestResource"
import DataMapper from "../helpers/DataMapper"

// ----------------------------------------------------------------------------

function _BatchBottomSheet(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, open, onConfirm, defaultValues, onCancel } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [formValues, setFormValue, setFormValues] = useForm()
    const [{ token }] = useStateValue()
    const [sensors] = useRestResource("/get_sensors", token)

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    const sensorOptions = useMemo(() => {
        return sensors?.map((sensor) => ({
            value: sensor?.id,
            label: sensor?.id,
        }))
    }, [sensors])

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        if (defaultValues) {
            setFormValues(DataMapper.BEtoFE(defaultValues))
        }
        // eslint-disable-next-line
    }, [defaultValues])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function handleConfirm() {
        onConfirm?.(DataMapper.FEtoBE(formValues))
    }

    function handleFormValueChange(key) {
        return function (value) {
            setFormValue(key, value)
        }
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <BottomSheet
            open={open}
            onCancel={onCancel}
            onConfirm={handleConfirm}
            title={defaultValues?.id ? "Modifica batch" : "Crea batch"}
            className={`${className}`}
            snapPoint={80}
        >
            <AppSelect
                className="field"
                label="Sensor"
                options={sensorOptions}
                value={formValues.sensor}
                onChange={handleFormValueChange("sensor")}
            />
            <AppDatePicker
                className="field"
                label="Time"
                value={formValues.time}
                onChange={handleFormValueChange("time")}
            />
            <AppInput
                className="field"
                label="Time delta"
                value={formValues.deltatime}
                onChange={handleFormValueChange("deltatime")}
            />
            <AppInput
                className="field"
                label="Mode"
                value={formValues.mode}
                onChange={handleFormValueChange("mode")}
            />
            <AppInput
                className="field"
                label="Length"
                value={formValues.length}
                onChange={handleFormValueChange("length")}
            />
            <AppInput
                className="field"
                label="Data"
                value={formValues.data}
                onChange={handleFormValueChange("data")}
            />
        </BottomSheet>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_BatchBottomSheet.propTypes = {
    className: PropTypes.string.isRequired,
}

_BatchBottomSheet.defaultProps = {}

// ----------------------------------------------------------------------------

const BatchBottomSheet = styled(_BatchBottomSheet)`
    & {
        .field {
            width: 400px;
            margin-bottom: 20px;
        }
    }
`
// ----------------------------------------------------------------------------

export default BatchBottomSheet
