import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import SearchFilter from "./SearchBox"
import { ReactComponent as Arrow } from "../../../assets/icons/dropdown.svg"
import TableSelect from "../../FormItems/TableSelect"

// ----------------------------------------------------------------------------

function _TopParams(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, sort, onSortChange, onSearch, searchValue, onClick } =
        props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function renderSearchBox() {
        return (
            <SearchFilter
                placeholder="Cerca"
                value={searchValue}
                onChange={onSearch}
                onClick={onClick}
            />
        )
    }

    function renderSortSelector({ label, value }) {
        console.table({ label, value })
        return (
            <div className="sort-select-inner">
                <div className="selector-text">
                    <span className="label">Ordine:</span>
                    <span className="value">{value.label}</span>
                </div>
                <div className="selector-arrow">{arrowDown}</div>
            </div>
        )
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    const arrowDown = (
        <div className="arrow-down">
            <Arrow />
        </div>
    )

    return (
        <div className={`${className}`}>
            <div className="left">{onSearch && renderSearchBox()}</div>
            {/* <div className="right">
                <TableSelect
                    className="sort-select"
                    value={sort}
                    renderSelector={renderSortSelector}
                    onChange={onSortChange}
                />
            </div> */}
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_TopParams.propTypes = {
    className: PropTypes.string.isRequired,
    pagination: PropTypes.object,
    onChange: PropTypes.func,
    sort: PropTypes.any,
    onFilter: PropTypes.func,
}

_TopParams.defaultProps = {}

// ----------------------------------------------------------------------------

const TopParams = styled(_TopParams)`
    & {
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-bottom: 30px;
        h1.table-title {
            margin: 0;
            padding: 0;
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
        }
        .left,
        .right {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
        }

        .left {
            .page-size-select {
                margin-left: ${({ theme }) => theme.spacing.s}px;
                cursor: pointer;
                .page-size-select-inner {
                    display: flex;
                    align-items: center;

                    .selector-text {
                        font-size: 18px;
                        display: block;
                        .label {
                            color: ${({ theme }) => theme.colors.grey600};
                        }
                        .value {
                            color: black;
                        }
                    }

                    .arrow-down {
                        margin-left: ${({ theme }) => theme.spacing.xs}px;
                    }
                }
            }
        }
        .right {
            .sort-select {
                margin-left: ${({ theme }) => theme.spacing.s}px;
                cursor: pointer;
            }
            .sort-select-inner {
                display: flex;
                align-items: center;

                background-color: white;
                border-radius: 10px;
                overflow: hidden;
                height: 48px;
                /* width: 300px; */
                .selector-text {
                    height: 100%;
                    padding: 0px 10px;
                    border-right: 1px solid
                        ${({ theme }) => theme.colors.grey300};
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .label {
                        color: ${({ theme }) => theme.colors.grey600};
                        margin-right: 4px;
                    }
                    .value {
                    }
                }
                .selector-arrow {
                    width: ${({ theme }) => theme.spacing.formItemHeight}px;
                    height: ${({ theme }) => theme.spacing.formItemHeight}px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .arrow-down {
                        margin-left: ${({ theme }) => theme.spacing.xs}px;
                    }
                }
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default TopParams
