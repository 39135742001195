import { useEffect, useState } from "react"

import * as api from "../../../../../api"
import { exists } from "../../../../_shared/toolkit"

export default function useStatus(taskId, token) {
    const [ready, setReady] = useState(false)
    const [error, setError] = useState(null)

    useEffect(() => {
        setReady(false)
        setError(false)
        //eslint-disable-next-line
    }, [taskId, token])

    useEffect(() => {
        const timer = setInterval(() => {
            getStatus().then((status) => {
                if (status === "pending") {
                    setReady(false)
                    setError(false)
                }
                if (status === "ready") {
                    setReady(true)
                    setError(false)
                    clearInterval(timer)
                }
                if (status === "error") {
                    setError(true)
                    setReady(false)
                    clearInterval(timer)
                }
            })
        }, 1000)

        return () => {
            clearInterval(timer)
        }
        //eslint-disable-next-line
    }, [taskId, token])

    function getStatus() {
        if (!exists(taskId) || !exists(token)) {
            return Promise.resolve("error")
        }
        return api
            .createResource("/ensys_app", token, {
                cmd: "getResult",
                data: JSON.stringify({
                    id: taskId,
                }),
            })
            .then((response) => {
                const data = JSON.parse(response)

                /** @type {{stato: string}} */
                const { stato } = data
                if (!stato) {
                    return "error"
                }
                if (stato === "PENDING") {
                    return "pending"
                }
                if (stato === "OutputReady") {
                    return "ready"
                }
                if (stato.includes("FAILURE")) {
                    return "error"
                }
                return "error"
            })
            .catch(() => {
                return "error"
            })
    }

    return [ready, error]
}
