import moment from "moment"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import styled from "styled-components"

import BottomSheet from "../../../../_shared/components/BottomSheet"
import AppInput from "../../../../_shared/components/FormItems/AppInput"
import AppSelect from "../../../../_shared/components/FormItems/AppSelect"

// ----------------------------------------------------------------------------

function _FaultsAndSolutionsModal(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, open, onConfirm, onCancel, rows } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const initialValues = {
        righeSel: [],
        guasto: "",
        soluzione: "",
    }

    const [form, setForm] = useState(initialValues)
    const [loading, setLoading] = useState(false)

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        if (!open) {
            reset()
        }
        //eslint-disable-next-line
    }, [open])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function reset() {
        setForm(initialValues)
    }

    function handleConfirm() {
        setLoading(true)
        Promise.resolve(onConfirm(form)).finally(() => setLoading(false))
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <BottomSheet
            title="Inserisci tipologia guasto"
            snapPoint={80}
            open={open}
            onConfirm={handleConfirm}
            className={`${className}`}
            loading={loading}
            onCancel={() => {
                onCancel()
                reset()
            }}
        >
            <AppSelect
                label="Seleziona le righe"
                className="field"
                value={form.righeSel}
                onChange={(sel) => setForm({ ...form, righeSel: sel })}
                multiple
                options={rows?.map((row) => {
                    return {
                        label: `${row.sensor} - ${moment(row.time_being).format("DD/MM/YYYY HH:mm:ss")}`,
                        value: JSON.stringify({
                            sensor: row.sensor,
                            time_being: row.time_begin,
                        }),
                    }
                })}
            />
            <AppInput
                label="Guasto"
                className="field"
                value={form.guasto}
                onChange={(v) => setForm({ ...form, guasto: v })}
            />
            <AppInput
                label="Soluzione"
                className="field"
                value={form.soluzione}
                onChange={(v) => setForm({ ...form, soluzione: v })}
            />
        </BottomSheet>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_FaultsAndSolutionsModal.propTypes = {
    className: PropTypes.string.isRequired,
}

_FaultsAndSolutionsModal.defaultProps = {}

// ----------------------------------------------------------------------------

const FaultsAndSolutionsModal = styled(_FaultsAndSolutionsModal)`
    & {
        .field {
            width: 400px;
            margin-bottom: 20px;
            max-width: 400px;
        }
    }
`
// ----------------------------------------------------------------------------

export default FaultsAndSolutionsModal
