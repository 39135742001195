import { useEffect, useState } from "react"

export default function useAsyncMemo(
    callback,
    dependencies,
    returnLoading = false,
) {
    const [resource, setResource] = useState()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getResource()
        // eslint-disable-next-line
    }, dependencies ?? [])

    async function getResource() {
        try {
            setLoading(true)
            const newRes = await callback()
            setResource(newRes)
        } catch (e) {
            // eslint-disable-next-line
            console.error(e)
        } finally {
            setLoading(false)
        }
    }

    if (returnLoading) {
        return [resource, loading, getResource]
    }

    return resource
}
