import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

// ----------------------------------------------------------------------------

function _Content(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, children } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return <div className={`${className}`}>{children}</div>
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Content.propTypes = {
    className: PropTypes.string.isRequired,
}

_Content.defaultProps = {}

// ----------------------------------------------------------------------------

const Content = styled(_Content)`
    & {
        flex: 1;
        width: 100%;
        overflow: auto;
    }
`
// ----------------------------------------------------------------------------

export default Content
