import encode from "form-urlencoded"
import URLJoin from "url-join"

// ----------------------------------------------------------------------------

export class APINotOkError extends Error {
    constructor(statusCode, serverResponse) {
        const { message = `HTTP status code: ${statusCode}`, ...restResponse } =
            serverResponse

        // console.log("SERVER RESPONSE: ", restResponse)

        super(message)
        this.name = "APINotOkError"
        this.statusCode = statusCode
        this.serverMessage = message
        this.errorKey = serverResponse && serverResponse.errorKey
        this.serverResponse = restResponse
    }
}

// ----------------------------------------------------------------------------

const BASE_URL = getBaseURL()
// eslint-disable-next-line no-unused-vars
const ACCESS_TOKEN = "QFj9SwbgEU6gD5reuc2rOJhAJZVd7DO0"

// ----------------------------------------------------------------------------

export function getBaseURL() {
    return "https://vibes.ensys.it/api"
}

// ----------------------------------------------------------------------------
// Auth
// ----------------------------------------------------------------------------

export function signInWithUsernameOrEmail(usernameOrEmail, password) {
    const relativeUrl = `/auth/login`
    const url = URLJoin(getBaseURL(), relativeUrl)
    const body = {
        username_or_email: usernameOrEmail,
        password,
    }
    return executePOSTRequest(url, null, body)
}

// ----------------------------------------------------------------------------
// Helpers
// ----------------------------------------------------------------------------

// eslint-disable-next-line no-unused-vars
function getUrl(endpoint) {
    return URLJoin(BASE_URL, endpoint)
}

// ----------------------------------------------------------------------------
// REST Resources
// ----------------------------------------------------------------------------

export function executeGETRequest(resourceURI, token, body) {
    const headers = {
        Authorization: `Bearer ${token}`,
    }
    return executeRequest(resourceURI, "get", null, headers)
}

export function executePOSTRequest(resourceURI, token, body, headers) {
    const newHeaders = headers || {}
    if (token) {
        newHeaders["Authorization"] = `Bearer ${token}`
    }
    return executeRequest(resourceURI, "post", body, newHeaders)
}

export function executePUTRequest(resourceURI, token, resource) {
    //const url = URLJoin(resourceURI, resourceID)
    const headers = {}
    if (token) {
        headers["Authorization"] = `Bearer ${token}`
    }
    return executeRequest(resourceURI, "put", resource, headers)
}

export function executeDELETERequest(resourceURI, token, body, headers) {
    const newHeaders = headers || {}
    if (token) {
        newHeaders["Authorization"] = `Bearer ${token}`
    }
    return executeRequest(resourceURI, "delete", body, newHeaders)
}

// ----------------------------------------------------------------------------

export function getResource(uri, token) {
    return executeGETRequest(URLJoin(getBaseURL(), uri), token)
}

export function createResource(resourceURI, token, resource) {
    const url = URLJoin(BASE_URL, resourceURI)

    return executePOSTRequest(url, token, resource)
}

export function updateResource(resourceURI, token, resourceID, resource) {
    const url = URLJoin(BASE_URL, resourceURI, resourceID)
    return executePUTRequest(url, token, resource)
}

export function deleteResource(resourceURI, token, resource) {
    const url = URLJoin(BASE_URL, resourceURI)

    return executeDELETERequest(url, token, resource)
}

export function getResourceList(resourceURI, token, options) {
    const { page, sort, filters } = options ?? {}
    const pageSize = options["page-size"] ?? 10
    const url = URLJoin(getBaseURL(), resourceURI)

    const params = {
        ...filters,
        "page-size": pageSize,
    }

    if (page) {
        params.page = page
    }

    if (sort && sort.order && sort.key) {
        const sortOperator = sort.order === "descend" ? "-" : ""
        params.sort = `${sortOperator}${sort.key}`
    }

    const urlWithQuery = urlWithQueryParams(url, params)
    // console.log(params?.filter)
    // console.log(decodeURIComponent(urlWithQuery))
    return executeGETRequest(urlWithQuery, token, { isList: true })
}

function urlWithQueryParams(url, params) {
    const encodedParams = encode(params)

    return encodedParams ? `${url}?${encodedParams}` : url
}

// ----------------------------------------------------------------------------

async function executeRequest(endPoint, method, body, headers) {
    const requestHeaders = {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...headers,
    }

    let requestBody
    if (body && typeof body !== "string") {
        requestBody = JSON.stringify(body)
    } else {
        requestBody = body
    }

    const requestData = {
        method,
        headers: requestHeaders,
        body: requestBody,
    }

    return fetch(endPoint, requestData).then((response) => {
        return response.text().then(function (text) {
            let responseBody
            try {
                responseBody = JSON.parse(text)
            } catch {
                responseBody = text
            }

            if (response.ok) {
                return responseBody
            } else {
                throw new APINotOkError(response.status, responseBody)
            }
        })
    })
}

// ----------------------------------------------------------------------------
