import { message } from "antd"
import PropTypes from "prop-types"
import React, { useState } from "react"
import styled from "styled-components"

import { signInWithUsernameOrEmail } from "../../../api"
import Logo from "../../_shared/assets/vibes_logo.png"
import AuthInput from "../../_shared/components/FormItems/AppInput"
import { useStateValue } from "../../_shared/context/AppStateStore"

// ----------------------------------------------------------------------------

function _AuthPage(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------
    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [, dispatch] = useStateValue()

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    async function handleAuth(e) {
        e.preventDefault()

        try {
            const response = await signInWithUsernameOrEmail(email, password)
            if (response && response.token) {
                dispatch({
                    type: "SIGN_IN_FULFILLED",
                    payload: {
                        user: response?.user ?? {
                            name: "Vibes",
                            surname: "User",
                            fullName: "Vibes User",
                        },
                        token: response.token,
                    },
                })
            }
        } catch (error) {
            message.error("Login non riuscito. Verificare le credenziali")
        }
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={props.className}>
            <div className="left">
                <div className="left-box">
                    <form onSubmit={handleAuth}>
                        <div className="logo">
                            <img src={Logo} alt="" className="logo" />
                        </div>
                        <div className="header">
                            <h2 className="welcome">Welcome back!</h2>
                            <p className="helper-text">
                                Inserisci le credenziali di accesso al tuo
                                account.
                            </p>
                        </div>
                        <div className="auth-fields">
                            <AuthInput
                                label="E-mail"
                                value={email}
                                onChange={setEmail}
                                type="text"
                                className="auth-field"
                            />
                            <AuthInput
                                label="Password"
                                value={password}
                                onChange={setPassword}
                                type="password"
                                className="auth-field"
                            />
                        </div>

                        <button type="submit" className="submit-btn">
                            Login
                        </button>
                    </form>
                </div>
            </div>
            <div className="right">
                <div className="image-wrapper" />
                <div className="mask">
                    <div className="text">Ensys Vibes</div>
                </div>
            </div>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_AuthPage.propTypes = {
    className: PropTypes.string.isRequired,
}

_AuthPage.defaultProps = {}

// ----------------------------------------------------------------------------

const AuthPage = styled(_AuthPage)`
    & {
        width: 100vw;
        min-height: 100vh;
        display: flex;
        flex-direction: row;
        overflow-x: hidden;
        .left,
        .right {
            width: 50%;
            min-height: 100vh;
        }
        .left {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-left: 74px;
            padding-top: 50px;
            padding-bottom: 50px;
            form {
                width: 440px;
            }
            .logo {
                margin-bottom: 50px;
            }
            .header {
                margin-bottom: 40px;
                .welcome {
                    font-size: 40px;
                    letter-spacing: -1px;
                    color: ${({ theme }) => theme?.colors?.textDark};
                }
                .helper-text {
                    font-size: 18px;
                    line-height: 24px;
                    color: ${({ theme }) => theme?.colors?.textDark};
                    margin-top: 15px;
                }
            }
            .auth-field {
                margin-top: 18px;
                max-width: unset;
            }
            .forgot-password {
                display: block;
                background-color: transparent;
                border: none;
                outline: none;
                text-decoration: underline;
                margin-left: auto;
                margin-top: 12px;
                font-size: 13px;
                cursor: pointer;
            }
            .submit-btn {
                height: 56px;
                width: 100%;
                border: none;
                outline: none;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 18px;
                border-radius: 12px;
                margin-top: 50px;
                color: ${({ theme }) => theme?.colors?.neutralLight};
                background-color: ${({ theme }) => theme?.colors?.accent};
                cursor: pointer;
            }
        }
        .right {
            position: relative;
            object-fit: contain;
            .image-wrapper {
                width: 100%;
                height: 100%;
                max-width: 100%;
                max-height: 100%;
                overflow: hidden;
                background: linear-gradient(
                    ${({ theme }) => theme.colors.primary},
                    #fff
                );
            }
            .mask {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                display: flex;
                align-items: center;
                justify-content: center;

                .text {
                    padding-left: 110px;
                    padding-right: 110px;
                    padding-top: 300px;
                    font-size: 40px;
                    line-height: 65px;
                    color: ${({ theme }) => theme?.colors?.neutralLight};
                }
            }
        }
        @media (max-width: 1376px) {
            .right .mask .text {
                padding-left: 50px;
                padding-right: 50px;
            }
        }
        @media (max-width: 1200px) {
            .right {
                display: none;
            }
            .left {
                width: 100%;
                justify-content: center;
                padding-left: 0px;
            }
        }
        @media (max-width: 450px) {
            .left .left-box {
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .left form {
                width: 90%;
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default AuthPage
