import PropTypes from "prop-types"
import React, { useEffect } from "react"
import styled from "styled-components"

import BottomSheet from "../../../../_shared/components/BottomSheet"
import AppInput from "../../../../_shared/components/FormItems/AppInput"
import Checkbox from "../../../../_shared/components/FormItems/Checkbox"
import useForm from "../../../../_shared/hooks/useForm"

// ----------------------------------------------------------------------------

function _ChangePasswordBottomSheet(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, open, defaultValues, onCancel, onConfirm } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [formValues, setFormValue, setFormValues, clearFormValues] = useForm()

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        if (!open) {
            clearFormValues()
        } else if (defaultValues) {
            setFormValues(defaultValues)
        }
        // eslint-disable-next-line
    }, [defaultValues, open])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function handleFormValueChange(key) {
        return function (value) {
            setFormValue(key, value)
        }
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <BottomSheet
            title="Cambio password"
            open={open}
            onCancel={onCancel}
            onConfirm={() => onConfirm(formValues)}
            className={`${className}`}
            snapPoint={80}
        >
            <div className="content">
                <AppInput
                    className="field"
                    label="Nuova password"
                    value={formValues?.name}
                    onChange={handleFormValueChange("password")}
                />
                <AppInput
                    className="field"
                    label="Conferma nuova password"
                    value={formValues?.email}
                    onChange={handleFormValueChange("confirmPassword")}
                />
            </div>
        </BottomSheet>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_ChangePasswordBottomSheet.propTypes = {
    className: PropTypes.string.isRequired,
    onConfirm: PropTypes.func,
}

_ChangePasswordBottomSheet.defaultProps = {}

// ----------------------------------------------------------------------------

const ChangePasswordBottomSheettomSheet = styled(_ChangePasswordBottomSheet)`
    & {
        .helper {
            display: block;
            margin-bottom: 20px;
            font-size: 18px;
        }
        .content {
            /* display: flex; */
            .field {
                width: 300px;
                margin-bottom: 20px;
            }
        }
        .check-wrapper {
            display: flex;
            gap: 10px;
        }
    }
`
// ----------------------------------------------------------------------------

export default ChangePasswordBottomSheettomSheet
