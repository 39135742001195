import PropTypes from "prop-types"
import React, { useMemo, useState } from "react"
import styled from "styled-components"

import BottomSheet from "../../../../_shared/components/BottomSheet"
import AppSelect from "../../../../_shared/components/FormItems/AppSelect"
import { useStateValue } from "../../../../_shared/context/AppStateStore"
import useRestResource from "../../../../_shared/hooks/useRestResource"

// ----------------------------------------------------------------------------

function _MachineUserBottomSheet(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, onCancel, onConfirm, open } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [{ token }] = useStateValue()
    const [users] = useRestResource("/get_users", token)

    const [user, setUser] = useState(null)

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    const options = useMemo(() => {
        return users?.map((user) => ({
            label: `${user?.name} ${user?.email}`.trim(),
            value: user?.name,
        }))
    }, [users])

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function handleConfirm() {
        user && onConfirm?.(user)
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <BottomSheet
            title="Assegna utente"
            className={`${className}`}
            onCancel={onCancel}
            onConfirm={handleConfirm}
            open={open}
        >
            <AppSelect
                className="field"
                value={user}
                onChange={(v) => setUser(v)}
                options={options}
                label="Utente"
            />
        </BottomSheet>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_MachineUserBottomSheet.propTypes = {
    className: PropTypes.string.isRequired,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
}

_MachineUserBottomSheet.defaultProps = {}

// ----------------------------------------------------------------------------

const MachineUserBottomSheet = styled(_MachineUserBottomSheet)`
    & {
        .field {
            width: 300px;
            margin-right: 10px;
        }
    }
`
// ----------------------------------------------------------------------------

export default MachineUserBottomSheet
