import PropTypes from "prop-types"
import React, { useEffect } from "react"
import styled from "styled-components"

import BottomSheet from "../../../../_shared/components/BottomSheet"
import AppInput from "../../../../_shared/components/FormItems/AppInput"
import AppSelect from "../../../../_shared/components/FormItems/AppSelect"
import useForm from "../../../../_shared/hooks/useForm"
import DataMapper from "../helpers/DataMapper"

// ----------------------------------------------------------------------------

function _SensorBottomSheet(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, open, onConfirm, defaultValues, onCancel, machines } =
        props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [formValues, setFormValue, setFormValues] = useForm()

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        if (defaultValues) {
            setFormValues(DataMapper.BEtoFE(defaultValues))
        }
        // eslint-disable-next-line
    }, [defaultValues])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function handleConfirm() {
        onConfirm?.(DataMapper.FEtoBE(formValues))
    }

    function handleFormValueChange(key) {
        return function (value) {
            setFormValue(key, value)
        }
    }

    function getMachinesOptions() {
        return (
            machines?.map?.((machine) => ({
                key: machine?.serial,
                value: machine?.serial,
                label: `${machine?.tag} (${machine?.brand})`,
            })) ?? []
        )
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <BottomSheet
            open={open}
            onCancel={onCancel}
            onConfirm={handleConfirm}
            title={defaultValues?.id ? "Modifica sensore" : "Crea sensore"}
            className={`${className}`}
        >
            <AppInput
                className="field"
                label="ID"
                disabled={defaultValues?.id}
                value={formValues.id}
                onChange={handleFormValueChange("id")}
            />
            <AppInput
                className="field"
                label="Tag"
                disabled={defaultValues?.id}
                value={formValues.tag}
                onChange={handleFormValueChange("tag")}
            />
            <AppInput
                className="field"
                label="Sensor password"
                disabled={defaultValues?.id}
                value={formValues.password}
                onChange={handleFormValueChange("password")}
            />
            <AppInput
                className="field"
                label="Increment"
                disabled={defaultValues?.id}
                value={formValues.increment}
                onChange={handleFormValueChange("increment")}
            />

            <AppSelect
                label="Macchina"
                className="field"
                value={formValues.machineserial}
                options={getMachinesOptions()}
                onChange={handleFormValueChange("machineserial")}
            />
        </BottomSheet>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_SensorBottomSheet.propTypes = {
    className: PropTypes.string.isRequired,
    machines: PropTypes.array,
}

_SensorBottomSheet.defaultProps = {}

// ----------------------------------------------------------------------------

const SensorBottomSheet = styled(_SensorBottomSheet)`
    & {
        .sheet-content {
            display: flex;
            flex-direction: row;
            gap: 10px;
        }

        .field {
            width: 400px;
            margin-bottom: 20px;
        }
    }
`
// ----------------------------------------------------------------------------

export default SensorBottomSheet
