import { Spin } from "antd"
import classNames from "classnames"
import PropTypes from "prop-types"
import React, {
    createContext,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react"
import styled from "styled-components"

import Step from "./Step"

// ----------------------------------------------------------------------------

const BottomSheetContext = createContext({
    current: 0,
    next: () => {},
    back: () => {},
    dismiss: () => {},
    confirm: () => {},
    isLast: false,
})

export const useBottomSheet = () => useContext(BottomSheetContext)

function _SteppedBottomSheet(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const {
        className,
        children,
        open,
        onConfirm,
        onCancel,
        title,
        description,
        loading,
    } = props

    // console.log(children.map((c) => c.type))

    const steps = React.Children.toArray(children)?.filter(
        (child) => child.type === Step,
    )

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [current, setCurrent] = useState(0)
    const [localLoading, setLocalLoading] = useState(false)
    const sheetRef = useRef(null)

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        function kd(evt) {
            // if escape pressed
            if (evt.keyCode === 27 && open) {
                dismiss()
            }
        }
        window.addEventListener("keydown", kd)

        return () => {
            window.removeEventListener("keydown", kd)
        }
        //eslint-disable-next-line
    }, [open])

    useEffect(() => {
        if (open) {
            document.body.style.overflow = "hidden"
        } else {
            document.body.style.overflow = ""
        }
        //eslint-disable-next-line
    }, [open])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function next() {
        if (current < steps.length - 1) {
            setCurrent(current + 1)
        }
    }

    function back() {
        if (current > 0) {
            setCurrent(current - 1)
        }
    }

    function dismiss() {
        setCurrent(0)
        onCancel()
    }

    function confirm() {
        if (onConfirm && !loading) {
            setLocalLoading(true)
            return Promise.resolve(onConfirm()).finally(() => {
                setLocalLoading(false)
                setCurrent(0)
            })
        }
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    const contextValue = {
        current,
        next,
        back,
        dismiss,
        confirm,
        title,
        description,
        isLast: current === steps.length - 1,
        loading: loading || localLoading,
    }

    return (
        <BottomSheetContext.Provider value={contextValue}>
            <div
                className={classNames(className, {
                    open,
                })}
            >
                <div className="mask" />
                <div className="sheet" ref={sheetRef}>
                    <Spin
                        spinning={loading || localLoading}
                        style={{ flex: 1, display: "flex", height: "100%" }}
                    >
                        {open && steps[current]}
                    </Spin>
                </div>
            </div>
        </BottomSheetContext.Provider>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_SteppedBottomSheet.propTypes = {
    className: PropTypes.string.isRequired,
}

_SteppedBottomSheet.defaultProps = {}

// ----------------------------------------------------------------------------

const SteppedBottomSheet = styled(_SteppedBottomSheet)`
    & {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;

        transform: translateY(100%);
        transition: transform 0.3s ease-in-out;
        overflow-x: hidden;

        z-index: 998;

        ::-webkit-scrollbar {
            display: none;
        }

        .sheet {
            position: fixed;
            top: ${(props) => {
                return 100 - (props?.snapPoint ?? 60)
            }}vh;
            left: 0;

            display: flex;
            flex-direction: column;

            box-sizing: border-box;
            padding: 40px 40px 20px 40px;
            border-radius: 20px 20px 0 0;

            width: 100vw;
            height: ${(props) => props?.snapPoint ?? 60}vh;

            background-color: ${(props) => props.theme.colors.light};
            z-index: 999;

            overflow: hidden;

            ::-webkit-scrollbar {
                display: none;
            }

            .ant-spin-nested-loading {
                height: 100%;
                display: flex;
                flex-direction: column;
                flex: 1;
                width: 100%;
                overflow: hidden;
                .ant-spin-container {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    overflow: hidden;
                }
            }
        }

        .mask {
            position: fixed;
            opacity: 0;
            transition: opacity 0.1s ease 0.3s;
            z-index: 998;
        }

        &.open {
            transform: translateY(0);

            .mask {
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                transform: scale(1);
                opacity: 1;
                background-color: rgba(0, 0, 0, 0.5);
                backdrop-filter: blur(5px);
            }

            /* MOBILE */

            @media (max-width: 576px) {
                .drawer {
                    min-width: 100vw;
                    width: 100vw;
                    max-width: 100vw;
                    height: 80vh;
                }
            }
        }
    }
`
// ----------------------------------------------------------------------------

SteppedBottomSheet.Step = Step

export default SteppedBottomSheet
