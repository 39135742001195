import { DatePicker } from "antd"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import FieldLabel from "../FieldLabel"
import ValidationMessage from "../ValidationMessage"

// ----------------------------------------------------------------------------

function _AppDatePicker(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------
    const { value, onChange, label, className, error, format, ...pickerProps } =
        props

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className} ${error ? "has-error" : ""}`}>
            <FieldLabel>{label}</FieldLabel>
            <DatePicker
                className="picker"
                value={value}
                onChange={onChange}
                format={format || "DD MMM YYYY"}
                {...pickerProps}
            />
            <ValidationMessage>{error}</ValidationMessage>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_AppDatePicker.propTypes = {
    className: PropTypes.string.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func,
    label: PropTypes.string,
    error: PropTypes.any,
    height: PropTypes.number,
    paddingHorizontal: PropTypes.number,
    borderRadius: PropTypes.number,
    showTime: PropTypes.object,
    format: PropTypes.string,
}

_AppDatePicker.defaultProps = {
    error: false,
}

// ----------------------------------------------------------------------------
const defaultHeight = 56
const defaultPadding = 23
const defaultBorderRadius = 8
const AppDatePicker = styled(_AppDatePicker)`
    & {
        position: relative;
        &.has-error {
            .ant-picker {
                2px solid ${({ theme }) => theme?.colors?.error};
            }
        }
        .label {
            display: block;
            font-size: 16px;
            line-height: 16px;
            color: ${({ theme }) => theme?.colors?.grey300};
            margin-bottom: 10px;
        }
        .picker {
            height: ${({ height }) => height ?? defaultHeight}px;
        }
        .ant-picker {
            height: ${({ height }) => height ?? defaultHeight}px;
            width: 100%;
            border-radius: ${({ borderRadius }) =>
                borderRadius ?? defaultBorderRadius}px;
            border: 2px solid ${({ theme }) => theme?.colors?.inputBorder};
            background: ${({ theme }) => theme.colors.menuBackgroundColor};
            margin-bottom: 0;
            box-shadow: none !important;
            padding-left: ${({ paddingHorizontal }) =>
                paddingHorizontal ?? defaultPadding}px;
            padding-right: ${({ paddingHorizontal }) =>
                paddingHorizontal ?? defaultPadding}px;
        }
    }
`
// ----------------------------------------------------------------------------

export default AppDatePicker
