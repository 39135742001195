function distance(latLng1, latLng2) {
    const [lat1, lng1] = latLng1
    const [lat2, lng2] = latLng2
    const R = 6371e3
    const w1 = (lat1 * Math.PI) / 180
    const w2 = (lat2 * Math.PI) / 180
    const dw = ((lat2 - lat1) * Math.PI) / 180
    const dl = ((lng2 - lng1) * Math.PI) / 180
    const a =
        Math.sin(dw / 2) * Math.sin(dw / 2) +
        Math.cos(w1) * Math.cos(w2) * Math.sin(dl / 2) * Math.sin(dl / 2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    const d = R * c
    return d // in meters
}

export function separateMarkers(groups, current) {
    const { latLng } = current

    const overlapGroup = groups.find((group) => {
        return group.some((sensor) => distance(sensor.latLng, latLng) < 100)
    })

    if (overlapGroup) {
        overlapGroup.push(current)
    } else {
        groups.push([current])
    }

    return groups
}

export const statusColors = {
    ok: "#0f7d00",
    error: "#de3900",
    mixed: "#e49c00",
}

export function getClusterStatus(group) {
    const allOk = group.every((sensor) => sensor.numAlarms === 0)
    const allErr = group.every((sensor) => sensor.numAlarms > 0)
    if (allOk) {
        return "ok"
    }
    if (allErr) {
        return "err"
    }
    return "mixed"
}
