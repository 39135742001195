import { message } from "antd"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useState } from "react"
import { FiEdit, FiLink } from "react-icons/fi"
import styled from "styled-components"

import * as api from "../../../../api"
import AppButton from "../../../_shared/components/AppButton"
import FloatingButton from "../../../_shared/components/FloatingButton"
import Graph from "../../../_shared/components/Graph"
import ProgressBar from "../../../_shared/components/LoadingItems/ProgressBar/Index"
import PageTitle from "../../../_shared/components/PageTitle"
import Table from "../../../_shared/components/Table"
import { useStateValue } from "../../../_shared/context/AppStateStore"
import useRestResource from "../../../_shared/hooks/useRestResource"
import useSearchFilter from "../../../_shared/hooks/useSearchFilter"
import Chart from "../Associations/Chart"
import SensorBottomSheet from "./SensorBottomSheet"
import SensorModelBottomSheet from "./TaskBottomSheet"
import useWizardReducer from "./TaskBottomSheet/_context/_reducer"
import WizardContext from "./TaskBottomSheet/_context/wizardContext"
import getColumns from "./helpers/getColumns"

// ----------------------------------------------------------------------------

function _Sensors(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const { state } = useWizardReducer()
    const { datas, sensor, selectedVector } = state

    const [sort, setSort] = useState({ value: "", label: "", direction: "" })

    const [selectedItem, setSelectedItem] = useState()
    const [{ token }] = useStateValue()
    const [sensors, setSensors] = useState()
    const [loading, setLoading] = useState(false)

    const [filteredSensors, filter, setFilter] = useSearchFilter(sensors)
    const [selectedAssignmentItem, setSelectedAssignmentItem] = useState(null)

    const [step, setStep] = useState(1)

    const [machines] = useRestResource("/get_machines", token)

    const fetchAlgsToDo = useCallback(async () => {
        try {
            const response = await api.getResource("/ensys_app", token, {
                cmd: "setAlgToDo",
                data: JSON.stringify({
                    customer_id: "god",
                    start: datas[0],
                    end: datas[1],
                    sensor_id: sensor.id,
                    assiScelti: selectedVector,
                }),
            })
            await JSON.parse(response)
            return response
        } catch (e) {
            // eslint-disable-next-line
            console.log(e)
        }
    }, [datas, sensor, selectedVector, token])

    const [chartTaskId, setChartTaskId] = useState(null)

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        if (step === 3) {
            fetchAlgsToDo()
        }
    }, [step, fetchAlgsToDo])

    useEffect(() => {
        if (machines) {
            fetchSensors()
        }
        //eslint-disable-next-line
    }, [machines])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    async function fetchSensors() {
        let r = []
        setLoading(true)
        try {
            for (const machine of machines) {
                const s = await api.createResource("/get_sensor", token, {
                    machineserial: machine.serial,
                })
                r = r.concat(s)
            }
            setSensors(r)
        } catch (e) {
            // eslint-disable-next-line
            console.log(e)
            message.error("Impossibile recuperare i sensori")
        } finally {
            setLoading(false)
        }
    }

    function handleTableAction(row) {
        setSelectedAssignmentItem(row)
    }

    function handleTableAction2(row) {
        setSelectedItem(row)
    }

    async function handleReassign(sensor, newMachine) {
        try {
            const response = await api.createResource("/ensys_app", token, {
                cmd: "changeSensorMachine",
                data: JSON.stringify({
                    sensore: sensor.id,

                    macchinaNuova: newMachine,
                }),
            })

            JSON.parse(response)?.status === "ok"
                ? message.success("Operazione riuscita")
                : message.error("Operazione non riuscita")
            setSelectedItem(null)
            fetchSensors()
        } catch (error) {
            // eslint-disable-next-line
            console.log(error)
        }
    }

    async function handleCreateSensor(values) {
        try {
            if (selectedItem?.id)
                handleReassign(selectedItem, values?.machineserial)
            else {
                const machinebrand =
                    machines?.find?.(
                        (machine) => machine.serial === values?.machineserial,
                    )?.brand ?? null
                await api.createResource("/new_sensor", token, {
                    ...values,
                    machinebrand,
                })

                setSelectedItem(null)
                fetchSensors()
                message.success("Macchina creata con successo")
            }
        } catch (error) {
            message.error(
                "Impossibile creare il sensore. Verificare i dati o riprovare più tardi",
            )
            // eslint-disable-next-line
            console.error("[Error] in handleCreateSensor: ", error)
        }
    }

    function renderActions(row) {
        return (
            <div className="actions">
                <FloatingButton
                    onClick={() => handleTableAction(row)}
                    icon={<FiLink />}
                />
                <FloatingButton
                    onClick={() => handleTableAction2(row)}
                    icon={<FiEdit />}
                />
            </div>
        )
    }

    function handleOpen() {
        if (selectedAssignmentItem) {
            return true
        }
        return false
    }

    function createGraph(model, datas, formValues) {
        setStep(3)
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------
    function normalView() {
        return (
            <div className={`${className}`}>
                <AppButton
                    onClick={() => setSelectedItem({})}
                    className="create-btn"
                >
                    Crea sensore
                </AppButton>
                <PageTitle>Sensori</PageTitle>
                <Table
                    dataSource={filteredSensors}
                    columns={getColumns(renderActions)}
                    pagination={false}
                    disableSelection
                    hidePagination
                    onDelete={() => {}}
                    onSearch={setFilter}
                    searchValue={filter}
                    sort={sort}
                    loading={loading}
                    onSortChange={setSort}
                    rowKey={(row) => row.id}
                    locale={{
                        triggerDesc: "Ordina in ordine decrescente",
                        triggerAsc: "Ordina in ordine crescente",
                        cancelSort: "Annulla ordinamento",
                    }}
                />
                <SensorBottomSheet
                    open={!!selectedItem}
                    defaultValues={selectedItem}
                    onCancel={() => setSelectedItem(null)}
                    onConfirm={handleCreateSensor}
                    machines={machines}
                    onPreview={(taskId) => setChartTaskId(taskId)}
                />
                <WizardContext>
                    <SensorModelBottomSheet
                        open={handleOpen()}
                        onCancel={() => setSelectedAssignmentItem(null)}
                        machine={selectedAssignmentItem}
                        onConfirm={createGraph}
                        upperStep={step}
                        setUpperStep={setStep}
                        onPreview={(taskId) => setChartTaskId(taskId)}
                    />
                </WizardContext>
                <Chart
                    taskId={chartTaskId}
                    onClose={() => setChartTaskId(null)}
                />
            </div>
        )
    }

    function creationView() {
        return (
            <div className={`${className}`}>
                <div className="content">
                    {" "}
                    <PageTitle>
                        Stiamo elaborando la predizione sul sensore selezionato.
                    </PageTitle>
                    <div className="description">
                        I tempi di attesa potrebbero essere molto lunghi, ti
                        consigliamo di tornare su questa pagina tra qualche
                        momento.
                    </div>
                    <ProgressBar onLoadComplete={() => setStep(4)} />
                </div>
            </div>
        )
    }

    function graphView() {
        return (
            <div className={`${className}`}>
                <Graph />
            </div>
        )
    }

    function renderSwitch() {
        switch (step) {
            case 1:
                return normalView()
            // case 2:
            //     return loadingView()
            case 3:
                return creationView()
            case 4:
                return graphView()
            default:
                return "View"
        }
    }

    return renderSwitch()
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Sensors.propTypes = {
    className: PropTypes.string.isRequired,
}

_Sensors.defaultProps = {}

// ----------------------------------------------------------------------------

const Sensors = styled(_Sensors)`
    & {
        display: flex;
        flex-direction: column;
        .create-btn {
            width: 200px;
            align-self: flex-end;
            margin-bottom: 20px;
        }
        .description {
            flex: 1;

            font-size: 14px;
            font-weight: normal !important;
            text-align: center;
            margin-bottom: 20px;

            /* color: ${(props) => props.theme.colors.textPrimary}; */
        }
        /* .content {
            width: 50%;
            margin: auto;
        } */
    }
`
// ----------------------------------------------------------------------------

export default Sensors
