import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import Header from "./Header"

// ----------------------------------------------------------------------------

export const CONTENT_PADDING = 50

function _Layout(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            <Header />
            <main className="main-content">{props.children}</main>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Layout.propTypes = {
    className: PropTypes.string.isRequired,
    chilren: PropTypes.object,
}

_Layout.defaultProps = {}

// ----------------------------------------------------------------------------

const Layout = styled(_Layout)`
    & {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        background-color: #f1f1f5;
        flex: 1;
        .main-content {
            padding: ${CONTENT_PADDING}px;
        }
    }
`
// ----------------------------------------------------------------------------

export default Layout
