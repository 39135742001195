import moment from "moment"

const DataMapper = {
    FEtoBE: (values) => {
        return {
            ...values,
            time: values.time ? values.time.toISOString() : undefined,
        }
    },
    BEtoFE: (values) => {
        return { ...values, time: values.time ? moment(values.time) : null }
    },
}

export default DataMapper
