import { useEffect, useState } from "react"

export function useDelayedState(initialState, timeout = 500) {
    const [state, setState] = useState(initialState)
    const [tempState, setTempState] = useState(initialState)

    function forceState(newState) {
        setState(newState)
        setTempState(newState)
    }

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setState(tempState)
        }, timeout)
        return () => clearTimeout(timeoutId)
        //eslint-disable-next-line
    }, [tempState])

    return [tempState, state, setTempState, forceState]
}
