import { Result, Spin } from "antd"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import styled from "styled-components"

import { createResource } from "../../../../../api"
import BottomSheet from "../../../../_shared/components/BottomSheet"
import AppTable from "../../../../_shared/components/Table"
import { useStateValue } from "../../../../_shared/context/AppStateStore"

// ----------------------------------------------------------------------------

function _ListMachineUsersBottomSheet(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, open, machineserial, onClose } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [{ token }] = useStateValue()
    const [users, setUsers] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        if (!machineserial) {
            setLoading(false)
            setUsers([])
            setError(null)
            return
        }
        setLoading(true)
        createResource("/get_users_from_machine", token, {
            machineserial,
        })
            .then((data) => {
                setUsers(data)
                setError(null)
            })
            .catch((err) => {
                setError(err)
            })
            .finally(() => {
                setLoading(false)
            })
        //eslint-disable-next-line
    }, [machineserial])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <BottomSheet
            open={open}
            onCancel={onClose}
            cancelButtonLabel="Chiudi"
            cancelButtonRole="primary"
            className={`${className}`}
            snapPoint={60}
            title="Utenti associati alla macchina"
        >
            <Spin spinning={loading} style={{ minHeight: 300 }}>
                {error ? (
                    <Result
                        status="error"
                        title="Errore durante l'elaborazione dei dati"
                    />
                ) : (
                    <AppTable
                        hideHeader
                        disableSelection
                        hidePagination
                        dataSource={users}
                        columns={getColumns()}
                        pagination={false}
                    />
                )}
            </Spin>
        </BottomSheet>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_ListMachineUsersBottomSheet.propTypes = {
    className: PropTypes.string.isRequired,
}

_ListMachineUsersBottomSheet.defaultProps = {}

// ----------------------------------------------------------------------------

const ListMachineUsersBottomSheet = styled(_ListMachineUsersBottomSheet)`
    & {
    }
`
// ----------------------------------------------------------------------------

export default ListMachineUsersBottomSheet

function getColumns() {
    return [
        {
            key: "username",
            title: "Username",
            dataIndex: "username",
        },
    ]
}
