import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import { ReactComponent as SearchIcon } from "../../../assets/icons/Search.svg"

// ----------------------------------------------------------------------------

function _SearchFilter(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------
    const { value, onChange, placeholder } = props

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function handleChange(e) {
        onChange && onChange(e.target.value)
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={props.className}>
            <input
                value={value}
                onChange={handleChange}
                placeholder={placeholder}
                className="search-input"
            />
            {/* <button className="icon" onClick={onClick}>
                <SearchIcon />
            </button> */}
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_SearchFilter.propTypes = {
    className: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
}

_SearchFilter.defaultProps = {}

// ----------------------------------------------------------------------------

const SearchFilter = styled(_SearchFilter)`
    & {
        height: 48px;
        width: 350px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0px 20px;
        border-radius: 8px;
        background: ${({ theme }) => theme?.colors?.light};
        .search-input {
            margin-right: 15px;
            background: transparent;
            height: 100%;
            border: none;
            outline: none;
            font-size: 15px;
            /* ::placeholder {
                color: ${({ theme }) => theme?.colors?.grey200};
            } */
        }
        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
`
// ----------------------------------------------------------------------------

export default SearchFilter
