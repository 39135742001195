import { Alert, Typography } from "antd"
import PropTypes from "prop-types"
import React, { useContext, useEffect, useMemo, useState } from "react"
import styled from "styled-components"

import * as api from "../../../../../../api"
import AppButton from "../../../../../_shared/components/AppButton"
import AppSelect from "../../../../../_shared/components/FormItems/AppSelect"
import SteppedBottomSheet from "../../../../../_shared/components/SteppedBottomSheet"
import { useStateValue } from "../../../../../_shared/context/AppStateStore"
import { _WizardContext } from "../_context/wizardContext"
import AlgorithmForm from "./AlgorithmForm"

const { Step } = SteppedBottomSheet
const { Title } = Typography

// ----------------------------------------------------------------------------

function _AlgorithmsStep(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, setLoading, onPreview } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const { state, dispatch } = useContext(_WizardContext)

    const {
        selectedAlgorithm,
        sensor,
        selectedVectors,
        availableData,
        fetchedAlgPar,
        tagArray,
        lastOut,
    } = state

    const [{ token }] = useStateValue()
    const [algorithms, setAlgorithms] = useState([])
    const [selectValue, setSelectValue] = useState()

    const [currentAlgo, setCurrentAlgo] = useState()
    const [algFormState, setAlgFormState] = useState([])
    const [parametersValues, setParametersValues] = useState([])

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    const algorithmsOptions = useMemo(() => {
        const algorithm =
            algorithms &&
            algorithms.map((value) => ({
                label:
                    value?.titolo ??
                    `algoritmo ${value.algoritmo} livello ${value.liv} nota ${value.nota}`,
                value: JSON.stringify(value),
            }))
        return algorithm
    }, [algorithms])

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        fetchAlgorithms().then((values) => {
            setAlgorithms(values?.data)
        })
        return () => {
            dispatch({ type: "SET_FETCHED_ALG_PAR", payload: null })
        }
    }, [lastOut])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    async function fetchAlgorithms() {
        setLoading(true)
        try {
            const response = await api.createResource("/ensys_app", token, {
                cmd: "getListaAlg",
                data: JSON.stringify({
                    sensor_id: sensor.id,
                    assiScelti: selectedVectors,
                    datiDisponibili: lastOut ? [lastOut] : availableData[0],
                    listaTag: tagArray,
                }),
            })
            const data = await JSON.parse(response)
            return data
        } catch (e) {
            // eslint-disable-next-line
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    function setSelectedAlgorithm(value) {
        dispatch({ type: "SET_SELECTED_ALGORITHMS", payload: value })
        setCurrentAlgo(value)
    }

    function renderSelectionInfo(a) {
        if (!a) {
            return null
        }
        const alg = JSON.parse(a).algoritmo
        const info = algorithms.find((a) => a.algoritmo === alg)?.info
        if (!info) {
            return null
        }
        return (
            <Alert
                message={<span dangerouslySetInnerHTML={{ __html: info }} />}
            />
        )
    }

    function asFloats(payload) {
        return payload.map((axis) => {
            return {
                ...axis,
                parametri: axis.parametri.map((param) => {
                    return {
                        ...param,
                        data: Object.keys(param.data).reduce((acc, key) => {
                            let v = param.data[key]
                            if (typeof v === "string") {
                                v = parseFloat(v)
                            }
                            return {
                                ...acc,
                                [key]: v,
                            }
                        }, {}),
                    }
                }),
            }
        })
    }

    function handleAlgFormSave() {
        const saveSimple = () => {
            const dictionary = Object.assign({}, ...algFormState.map((x) => x))

            if (parsedAlgorithm && selectedAlgorithm) {
                dispatch({
                    type: "SET_LAST_OUT",
                    payload: parsedAlgorithm.dati_out,
                })
                dispatch({
                    type: "SET_FETCHED_ALG_PAR",
                    payload: {
                        algoritmo: parsedAlgorithm.algoritmo,
                        liv: parsedAlgorithm.liv,
                        note: parsedAlgorithm.note,
                        id: parsedAlgorithm.id,
                        title: parsedAlgorithm.titolo,
                        info: parsedAlgorithm.info,
                        ...dictionary,
                    },
                })
                dispatch({ type: "SET_SELECTED_ALGORITHMS", payload: null })
                setCurrentAlgo(null)
                setAlgFormState([])
            }
        }
        const savePerAxis = () => {
            const algorithm = parsedAlgorithm

            const payload = {
                algoritmo: algorithm.algoritmo,
                liv: algorithm.liv,
                note: algorithm.note,
                id: algorithm.id,
                title: algorithm.titolo,
                info: algorithm.info,
                parametri: asFloats(algFormState),
            }
            dispatch({
                type: "SET_FETCHED_ALG_PAR",
                payload,
            })
            dispatch({
                type: "SET_LAST_OUT",
                payload: parsedAlgorithm.dati_out,
            })
            dispatch({ type: "SET_SELECTED_ALGORITHMS", payload: null })

            setCurrentAlgo(null)
            setAlgFormState([])
        }

        if (parametersValues?.perOgniAsse) {
            savePerAxis()
        } else {
            saveSimple()
        }
    }

    function renderAlgPreview(alg, idx) {
        const { title, info, algoritmo } = alg
        const algWithoutInfo = { ...alg, info: undefined, title: undefined }
        return (
            <Alert
                key={idx}
                icon={<div className="alg-icon">{idx + 1}</div>}
                message={
                    <div>
                        <Title level={5}>{title ?? algoritmo}</Title>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: info ?? JSON.stringify(algWithoutInfo),
                            }}
                        />
                    </div>
                }
                style={{
                    backgroundColor: "#e4e4e4",
                    borderColor: "#9c9c9c",
                    maxWidth: 400,
                    borderRadius: 8,
                    alignItems: "flex-start",
                }}
                showIcon
            />
        )
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    const parsedAlgorithm = useMemo(() => {
        return JSON.parse(selectedAlgorithm)
    }, [selectedAlgorithm])

    return (
        <Step.Wrapper>
            <Step.Header
                title={`${state.label} - Sensore ${state.sensor.id}`}
                subTitle="Seleziona gli algoritmi e/o i modelli da applicare"
            />
            <Step.Content>
                <div className={`${className}`}>
                    <div className="form-row">
                        <div className="left">
                            {!currentAlgo && (
                                <AppSelect
                                    className="field"
                                    allowClear
                                    value={selectValue}
                                    onChange={(v) => setSelectValue(v)}
                                    options={algorithmsOptions}
                                    label="ALGORITHM"
                                    placeholder="Select an algorithm"
                                    showArrow
                                />
                            )}
                            {!currentAlgo && (
                                <AppButton
                                    className="select-btn"
                                    onClick={() => {
                                        setSelectedAlgorithm(selectValue)
                                        setSelectValue(null)
                                    }}
                                >
                                    Seleziona algoritmo
                                </AppButton>
                            )}
                        </div>
                        <div className="right">
                            {renderSelectionInfo(selectValue)}
                        </div>
                    </div>

                    <AlgorithmForm
                        parsedAlgorithm={parsedAlgorithm}
                        currentAlgo={currentAlgo}
                        onConfirm={() => {
                            setCurrentAlgo(null)
                            setAlgFormState([])
                        }}
                        info={renderSelectionInfo(selectedAlgorithm)}
                        formState={algFormState}
                        setFormState={setAlgFormState}
                        parametersValues={parametersValues}
                        setParametersValues={setParametersValues}
                    />

                    {!currentAlgo && fetchedAlgPar.length > 0 && (
                        <div style={{ marginTop: 20 }}>
                            <Title level={4}>Algoritmi selezionati</Title>
                            <div className="algs">
                                {fetchedAlgPar.map(renderAlgPreview)}
                            </div>
                        </div>
                    )}
                </div>
            </Step.Content>
            <Step.Footer
                nextLabel={currentAlgo ? "Aggiungi algoritmo" : "Avanti"}
                backLabel={
                    currentAlgo ? "Annulla selezione algoritmo" : "Indietro"
                }
                canGoNext={!!currentAlgo}
                canConfirm={!currentAlgo && fetchedAlgPar?.length > 0}
                canPreview={!currentAlgo && fetchedAlgPar?.length > 0}
                onNext={currentAlgo ? handleAlgFormSave : undefined}
                onBack={
                    currentAlgo
                        ? () => {
                              setCurrentAlgo(null)
                              setAlgFormState([])
                              setParametersValues([])
                          }
                        : undefined
                }
                onPreview={onPreview}
            />
        </Step.Wrapper>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_AlgorithmsStep.propTypes = {
    className: PropTypes.string.isRequired,
}

_AlgorithmsStep.defaultProps = {}

// ----------------------------------------------------------------------------

const AlgorithmsStep = styled(_AlgorithmsStep)`
    & {
        .algs {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .alg-icon {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: ${({ theme }) => theme.colors.primary};
                color: white;
                margin-top: 3px;
            }
        }

        .field {
            width: 300px;
            margin-right: 10px;
        }
        .select-btn {
            margin-top: 10px;
        }
        .form-row {
            display: flex;
            gap: 20px;

            .right {
                flex: 1;
                padding-top: 25px;
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default AlgorithmsStep
