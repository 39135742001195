import { Buffer } from "buffer"

import Color from "colorjs.io"
import zlib from "react-zlib-js"

import * as api from "../../../../../api"

export function loadChartData(taskId, token) {
    return new Promise((resolve, reject) => {
        api.createResource("/ensys_app", token, {
            cmd: "getResult",
            data: JSON.stringify({
                id: taskId,
                opzione: "dataZip",
            }),
        })
            .then((data) => {
                const decoded = Buffer.from(data, "base64")

                zlib.unzip(decoded, (err, buffer) => {
                    if (err) {
                        reject(err)
                    } else {
                        const decodedString = buffer.toString("utf8")

                        try {
                            const v = JSON.parse(decodedString)
                            resolve(v)
                        } catch (err) {
                            reject(err)
                        }
                    }
                })
            })
            .catch((err) => {
                reject(err)
            })
    })
}

export function getColor(value, { min, max, minColor, maxColor }) {
    const c1 = new Color(minColor)
    const c2 = new Color(maxColor)
    const range = c1.range(c2)
    const scaledValue = (value - min) / (max - min)
    return range(scaledValue).toString({ format: "hex" })
}
