export default function getColumns(renderActions) {
    return [
        {
            title: "Serial",
            key: "serial",
            dataIndex: "serial",
            sorter: (a, b) => a?.serial?.localeCompare(b.serial),
        },
        {
            title: "Brand",
            key: "brand",
            dataIndex: "brand",
            sorter: (a, b) => a?.brand?.localeCompare(b.brand),
        },
        {
            title: "Tag",
            key: "tag",
            dataIndex: "tag",
            sorter: (a, b) => a?.tag?.localeCompare(b.tag),
        },
        {
            key: "actions",
            render: renderActions,
        },
    ]
}
