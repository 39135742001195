import { Modal } from "antd"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import styled from "styled-components"

import BottomSheet from "../../../../_shared/components/BottomSheet"
import AppInput from "../../../../_shared/components/FormItems/AppInput"
import AppSelect from "../../../../_shared/components/FormItems/AppSelect"
import { useStateValue } from "../../../../_shared/context/AppStateStore"
import useForm from "../../../../_shared/hooks/useForm"
import useRestResource from "../../../../_shared/hooks/useRestResource"

// ----------------------------------------------------------------------------

function _MachinesBottomSheet(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, open, defaultValues, onCancel, onConfirm } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [formValues, setFormValue, setFormValues] = useForm()

    // -------------------------------------
    // Effects
    // -------------------------------------

    const [sensorsMap, setSensorsMap] = useState()
    const [doubtSensor, setDoubtSensor] = useState()

    const [selectedSensors, setSelectedSensors] = useState()
    const [openModal, setOpenModal] = useState()

    const [{ token }] = useStateValue()

    const [sensors] = useRestResource("/get_sensors", token)

    useEffect(() => {
        console.log("selectedSensors :", selectedSensors)
    }, [selectedSensors])

    useEffect(() => {
        setSensorsMap(
            sensors?.map((sensor) => ({
                value: sensor?.id,
                label:
                    sensor?.id +
                    (sensor.machineserial
                        ? " (" + sensor.machineserial + ")"
                        : ""),
            })),
        )
        //    sensors && setSensorsMap(sensors.map((x) => x?.id))
    }, [sensors])

    useEffect(() => {
        if (defaultValues) {
            setFormValues(defaultValues)
        }
    }, [defaultValues])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function handleFormValueChange(key) {
        return function (value) {
            setFormValue(key, value)
        }
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    function addSensorsConfirm(v, add) {
        add
            ? setSelectedSensors(v)
            : setSelectedSensors(selectedSensors?.filter((x) => x !== v))
        setOpenModal(false)
    }

    function addSensor(v) {
        setDoubtSensor(v)
        const sensor = sensors.find((x) => x.id === v[v.length - 1])
        sensor.machineserial !== null
            ? setOpenModal(true)
            : setSelectedSensors(v)
    }

    return (
        <BottomSheet
            title={defaultValues?.id ? "Modifica macchina" : "Crea macchina"}
            open={open}
            onCancel={onCancel}
            onConfirm={() => onConfirm(formValues, selectedSensors)}
            className={`${className}`}
        >
            <span className="helper">
                Compila i campi qui sotto per procedere con la creazione della
                macchina.
            </span>
            <div className="content">
                <AppInput
                    className="field"
                    label="SERIAL"
                    value={formValues?.serial}
                    onChange={handleFormValueChange("serial")}
                />
                <AppInput
                    className="field"
                    label="BRAND"
                    value={formValues?.brand}
                    onChange={handleFormValueChange("brand")}
                />
                <AppInput
                    className="field"
                    label="TAG"
                    value={formValues?.tag}
                    onChange={handleFormValueChange("tag")}
                />
                <AppSelect
                    className="field"
                    multiple
                    allowClear
                    value={selectedSensors}
                    onChange={(v) => {
                        addSensor(v)
                    }}
                    options={sensorsMap}
                    label="Sensori associati"
                    placeholder="Select a sensor"
                    showArrow
                />
            </div>
            <Modal
                title="Riassocia sensore"
                centered
                open={openModal}
                onOk={() => addSensorsConfirm(doubtSensor, true)}
                onCancel={() => addSensorsConfirm(doubtSensor, false)}
            >
                <p>
                    Il sensore ha già una macchina associata. Sei sicuro di
                    volerlo riassociare a questa nuova macchina?
                </p>
            </Modal>
        </BottomSheet>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_MachinesBottomSheet.propTypes = {
    className: PropTypes.string.isRequired,
    onConfirm: PropTypes.func,
}

_MachinesBottomSheet.defaultProps = {}

// ----------------------------------------------------------------------------

const MachinesBottomSheet = styled(_MachinesBottomSheet)`
    & {
        .helper {
            display: block;
            margin-bottom: 20px;
            font-size: 18px;
        }
        .content {
            display: flex;
            .field {
                width: 300px;
                margin-right: 10px;
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default MachinesBottomSheet
