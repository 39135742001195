import dayjs from "dayjs"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import styled from "styled-components"

import BottomSheet from "../../../../_shared/components/BottomSheet"
import AppDatePicker from "../../../../_shared/components/FormItems/AppDatePicker"
import AppInput from "../../../../_shared/components/FormItems/AppInput"
import AppSelect from "../../../../_shared/components/FormItems/AppSelect"
import { useStateValue } from "../../../../_shared/context/AppStateStore"
import useForm from "../../../../_shared/hooks/useForm"
import useRestResource from "../../../../_shared/hooks/useRestResource"

// ----------------------------------------------------------------------------

function _EventsBottomSheet(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, open, onSubmit, onClose } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [values, onChange, , reset] = useForm()

    const [{ token }] = useStateValue()

    const [machines, machinesLoading] = useRestResource(
        "/get_usermachines",
        token,
        {
            waitFor: [open],
        },
    )

    const [loading, setLoading] = useState(false)

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        if (!open) {
            reset()
        }
        //eslint-disable-next-line
    }, [open])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function isoWithoutMillis(date) {
        return dayjs(date).format("YYYY-MM-DDTHH:mm:ss")
    }

    function handleConfirm() {
        setLoading(true)
        const payload = {
            ...values,
            time_event: values.time_event
                ? isoWithoutMillis(values.time_event)
                : "None",
        }
        return Promise.resolve(onSubmit(payload)).finally(() => {
            setLoading(false)
        })
    }

    function update(field) {
        return function (value) {
            onChange(field, value)
        }
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <BottomSheet
            snapPoint={80}
            title="Inserisci evento"
            onCancel={onClose}
            onConfirm={handleConfirm}
            open={open}
            className={`${className}`}
            loading={loading || machinesLoading}
        >
            <AppSelect
                label="Macchina"
                className="field"
                options={machines?.map((m) => ({
                    label: m.machineserial,
                    value: m.machineserial,
                }))}
                value={values?.machine_serial}
                onChange={update("machine_serial")}
            />
            <AppInput
                label="Tipologia"
                className="field"
                value={values?.eventtype}
                onChange={update("eventtype")}
            />
            <AppDatePicker
                label="Data e ora"
                className="field"
                value={values?.time_event}
                onChange={update("time_event")}
                showTime
                format="DD/MM/YYYY HH:mm"
            />
        </BottomSheet>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_EventsBottomSheet.propTypes = {
    className: PropTypes.string.isRequired,
}

_EventsBottomSheet.defaultProps = {}

// ----------------------------------------------------------------------------

const EventsBottomSheet = styled(_EventsBottomSheet)`
    & {
        .field {
            width: 400px;
            max-width: 400px;
            margin-bottom: 20px;
        }
    }
`
// ----------------------------------------------------------------------------

export default EventsBottomSheet
