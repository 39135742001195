import PropTypes from "prop-types"
import React, { useState } from "react"
import styled from "styled-components"

import FieldLabel from "../FieldLabel"
import ValidationMessage from "../ValidationMessage"

// ----------------------------------------------------------------------------

function _AppInput(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------
    const {
        value,
        onChange,
        label,
        type,
        placeholder,
        disabled,
        error,
        onBlur,
        onFocus,
    } = props

    let message
    let invalid

    if (typeof error === "string") {
        message = error
        invalid = !!message
    } else {
        message = error?.message
        invalid = error?.invalid
    }
    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------
    const [isFocused, setIsFocused] = useState(false)

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function handleChange(e) {
        onChange && onChange(e.target.value)
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${props.className} ${invalid ? "invalid" : ""}`}>
            <FieldLabel>{label}</FieldLabel>
            <input
                type={type ?? "text"}
                value={value}
                onChange={handleChange}
                className={`auth-input ${isFocused ? "focused" : ""}`}
                onFocus={(e) => {
                    onFocus && onFocus(e)
                    setIsFocused(true)
                }}
                onBlur={(e) => {
                    onBlur && onBlur(e)
                    setIsFocused(false)
                }}
                placeholder={placeholder}
                disabled={disabled}
            />
            <ValidationMessage>{message}</ValidationMessage>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_AppInput.propTypes = {
    className: PropTypes.string.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func,
    label: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    error: PropTypes.any,
    disabled: PropTypes.bool,
    height: PropTypes.number,
    borderRadius: PropTypes.number,
    paddingHorizontal: PropTypes.number,
    preventBorder: PropTypes.bool,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
}

_AppInput.defaultProps = {}

// ----------------------------------------------------------------------------
const defaultHeight = 56
const defaultBorderRadius = 8
const defaultPaddingHorizontal = 23
const AppInput = styled(_AppInput)`
    & {
        position: relative;
        .label {
            display: block;
            font-size: 16px;
            line-height: 16px;
            color: ${({ theme }) => theme?.colors?.grey300};
            margin-bottom: 10px;
        }
        .auth-input {
            display: block;
            border-width: 2;
            outline: none;
            appearance: none;
            border: none;
            width: 100%;
            max-width: 100%;
            height: ${({ height }) => height ?? defaultHeight}px;
            border-radius: ${({ borderRadius }) =>
                borderRadius ?? defaultBorderRadius}px;
            border: 2px solid ${({ theme }) => theme?.colors?.inputBorder};

            color: ${({ theme }) => theme?.colors?.textDark};
            font-size: 14px;
            padding: 0px
                ${({ paddingHorizontal }) =>
                    paddingHorizontal ?? defaultPaddingHorizontal}px;
            transition: all 300ms ease;
            box-sizing: border-box;

            &.focused {
                /* background-color: ${({ theme, preventBorder }) =>
                    preventBorder
                        ? theme?.colors?.greyBackground200
                        : theme?.colors?.neutralLight}; */
                /* border: 2px solid
                    ${({ theme, preventBorder }) =>
                    preventBorder ? "transparent" : theme?.colors?.accent}; */
                /* color: ${({ theme }) => theme?.colors?.primary}; */
            }
            &:disabled {
                cursor: not-allowed;
            }
            ::placeholder {
                color: ${({ theme }) => theme.colors.grey200};
            }
        }

        &.invalid {
            .auth-input {
                border: 2px solid ${({ theme }) => theme?.colors?.error};
                color: ${({ theme }) => theme?.colors?.error};
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default AppInput
