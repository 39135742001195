export default function getColumns(renderActions) {
    return [
        {
            title: "ID",
            key: "id",
            dataIndex: "id",
        },
        {
            title: "Tag",
            key: "tag",
            dataIndex: "tag",
            sorter: (a, b) => a?.tag?.localeCompare(b.tag),
        },
        {
            title: "Machine brand",
            key: "machinebrand",
            dataIndex: "machinebrand",
            sorter: (a, b) => a?.machinebrand?.localeCompare(b.machinebrand),
        },
        {
            title: "Machine serial",
            key: "machineserial",
            dataIndex: "machineserial",
            sorter: (a, b) => a?.machineserial?.localeCompare(b.machineserial),
        },
        {
            title: "Owner",
            key: "owner",
            dataIndex: "owner",
            sorter: (a, b) => a?.owner?.localeCompare(b.owner),
        },
        {
            key: "actions",
            render: renderActions,
        },
    ]
}
