import { Spin, Tag } from "antd"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { FaCheck, FaHourglassHalf } from "react-icons/fa"
import { MdErrorOutline } from "react-icons/md"
import styled from "styled-components"

import { createResource } from "../../../../../../../api"
import { useStateValue } from "../../../../../../_shared/context/AppStateStore"
import { useMapContext } from "../../MapContext"

// ----------------------------------------------------------------------------

function _TaskList(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, sensorId } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [loading, setLoading] = useState(false)
    const [tasks, setTasks] = useState([])
    const [{ token }] = useStateValue()
    const { openTaskChart } = useMapContext()

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        if (sensorId) {
            setLoading(true)
            createResource("ensys_app", token, {
                cmd: "getTaskSensor",
                data: JSON.stringify({ sensor_id: sensorId }),
            })
                .then((response) => {
                    response = JSON.parse(response)
                    const { data } = response
                    const t = data.map((row) => {
                        return {
                            id: row[0],
                            status: JSON.parse(row[3])["stato"],
                            label: row[1],
                        }
                    })
                    setTasks(t)
                })
                .catch(() => {})
                .finally(() => {
                    setLoading(false)
                })
        }
        //eslint-disable-next-line
    }, [sensorId])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function renderEmpty() {
        return <span className="empty">Nessun task da visualizzare</span>
    }

    function renderTasks() {
        if (!loading && !tasks.length) {
            return renderEmpty()
        }
        return (
            <div className="tasks">
                {tasks.map((task) => {
                    const { id, status, label } = task
                    const isPending = status === "PENDING"
                    const isReady = status === "OutputReady"
                    const isError = status.includes("FAILURE")
                    let statusClass = ""
                    if (isPending) {
                        statusClass = "pending"
                    }
                    if (isReady) {
                        statusClass = "ready"
                    }
                    if (isError) {
                        statusClass = "error"
                    }
                    return (
                        <Tag
                            className="task-tag"
                            color={
                                isPending ? "orange" : isError ? "red" : "green"
                            }
                            onClick={() => openTaskChart(id)}
                        >
                            <div className={`task-btn ${statusClass}`}>
                                <span className="task-icon">
                                    {isPending && <FaHourglassHalf />}
                                    {isReady && <FaCheck />}
                                    {isError && <MdErrorOutline />}
                                </span>
                                <span className="task-label">
                                    {label || `Task ${id}`}
                                </span>
                            </div>
                        </Tag>
                    )
                })}
            </div>
        )
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <Spin spinning={loading} style={{ minHeight: 50 }}>
            <div className={`${className}`}>{renderTasks()}</div>
        </Spin>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_TaskList.propTypes = {
    className: PropTypes.string.isRequired,
}

_TaskList.defaultProps = {}

// ----------------------------------------------------------------------------

const TaskList = styled(_TaskList)`
    & {
        .tasks {
            display: flex;
            flex-direction: column;
            gap: 6px;
            .task-tag {
                cursor: pointer;
                width: 250px;
                overflow: hidden;

                .task-btn {
                    height: 30px;
                    display: flex;
                    align-items: center;
                    gap: 6px;

                    .task-label {
                        font-size: 14px;
                        flex: 1;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }

                    .task-icon {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        svg {
                            width: 15px;
                            height: 15px;
                        }
                    }
                }
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default TaskList
