import { useReducer } from "react"

const initialState = {
    label: "",
    model: null,
    sensor: null,
    tagArray: [],
    datas: null,
    selectedVector: [],
    availableData: [],
    selectedAlgorithm: null,
    fetchedAlgPar: [],
    lastOut: null,
}

function reducer(state, action) {
    switch (action.type) {
        case "SET_MODEL":
            return { ...state, model: action.payload }
        case "SET_SENSOR":
            return { ...state, sensor: action.payload }
        case "SET_TAG_ARRAY":
            return { ...state, tagArray: [...state.tagArray, action.payload] }
        case "SET_DATAS":
            return { ...state, datas: action.payload }
        case "SET_SELECTED_VECTOR":
            return { ...state, selectedVector: action.payload }
        case "SET_AVAILABLE_DATA":
            return {
                ...state,
                availableData: action.payload,
            }
        case "SET_SELECTED_ALGORITHMS":
            return { ...state, selectedAlgorithm: action.payload }
        case "SET_FETCHED_ALG_PAR":
            if (action.payload === null) return state

            return {
                ...state,
                fetchedAlgPar: [...state.fetchedAlgPar, action.payload],
            }
        case "SET_LABEL":
            return { ...state, label: action.payload }
        case "SET_LAST_OUT":
            return { ...state, lastOut: action.payload }
        case "RESET":
            return initialState
        default:
            return state
    }
}

function useWizardReducer() {
    const [state, dispatch] = useReducer(reducer, initialState)
    return { state, dispatch }
}

export default useWizardReducer
