/* eslint-disable no-console */
import { useEffect, useReducer, useRef } from "react"

import * as api from "../../../../api"
import reducer, { useRestResourceActions as actions } from "./_reducer"

export default function useRestResource(resourceUrl, token, options) {
    // ----------------------------------------------------------------------------
    // Inital definitions
    // ----------------------------------------------------------------------------

    const {
        filters,
        fetchOnMount = true,
        waitFor = [],
        refetchOn = [],
        debug = false,
    } = options ?? {}

    const waitForHasItems = waitFor?.length >= 1
    const refetchOnHasItems = refetchOn?.length >= 1

    const initialState = {
        resource: null,
        filters: filters || null,
        isLoading: false,
        error: null,
        isInitialFetch: true,
        waitForFulfilled: false,
        shouldRefetch: false,
        // 'error' | 'success' | 'pending'
        initialHydrationStatus: "error",
    }

    const [state, dispatch] = useReducer(reducer, initialState)
    // debug && console.log(state.filters)

    // debug && console.log(state)
    const previousFilters = useRef({})
    const previousRefetchConditions = useRef([])

    // counter to establish whether incoming resource is from last api call
    const callCounter = useRef(0)

    // ----------------------------------------------------------------------------
    // Effects
    // ----------------------------------------------------------------------------

    // useEffect(() => {
    //     // when cacheId changes, all first-cycle procedures
    //     // should be repeated
    //     dispatch(actions.resetHookState(initialState))
    //     hydrateFromCache()

    //     // eslint-disable-next-line
    // }, [cacheId])

    useEffect(() => {
        if (waitForHasItems && !state.waitForFulfilled) {
            const condition = waitFor.every((i) => !!i)

            condition && dispatch(actions.watiForFulfilled(filters))
        } else {
            !state.waitForFulfilled && dispatch(actions.watiForFulfilled())
        }
        // eslint-disable-next-line
    }, [...waitFor])

    useEffect(() => {
        if (refetchOnHasItems) {
            const itemsDidChange = !refetchOn.every(
                (item, index) => previousRefetchConditions[index] === item,
            )
            if (itemsDidChange && state.waitForFulfilled) {
                debug && console.log("[REFETCH] detected dependency change")
                dispatch(actions.shouldRefetch(filters))
            }
        }
        // eslint-disable-next-line
    }, [...refetchOn])

    useEffect(() => {
        if (state.initialHydrationStatus !== "pending") {
            const { shouldFetch, reason } = getShouldFetch()
            // debug &&
            //     console.table({
            //         shouldFetch: shouldFetch,
            //         waitForFulfilled: state.waitForFulfilled,
            //     })
            if (shouldFetch) {
                debug && console.log("refetch reason", reason)
                fetchResource()
            }
        }
        // eslint-disable-next-line
    }, [
        state.initialHydrationDone,
        state.waitForFulfilled,
        state.shouldRefetch,
        state.filters,
    ])

    // ----------------------------------------------------------------------------
    // Hook functions
    // ----------------------------------------------------------------------------

    function getShouldFetch() {
        const { waitForFulfilled } = state

        // check if filters changed
        const filtersHaveChanged = deeplyCheckFiltersChanged(
            state.filters,
            previousFilters.current,
        )

        // compose items
        if (waitForFulfilled) {
            if (state.isInitialFetch) {
                dispatch(actions.initialFetchDone())
                return {
                    shouldFetch: fetchOnMount,
                    reason: "fetchOnMount is true",
                }
            } else if (filtersHaveChanged) {
                return { shouldFetch: true, reason: "Filters changed" }
            } else if (state.shouldRefetch) {
                return {
                    shouldFetch: true,
                    reason: "refetchOn changes detected",
                }
            }
        }
        return { shouldFetch: false, reson: "No clause was triggered" }
    }

    function deeplyCheckFiltersChanged(filters, prevFilters) {
        // debug && console.table({ filters, prevFilters })
        previousFilters.current = filters
        if (filters && prevFilters) {
            debug &&
                console.log(
                    JSON.stringify(filters) === JSON.stringify(prevFilters),
                )
            const filtersHaveChanged = !(
                JSON.stringify(filters) === JSON.stringify(prevFilters)
            )
            return filtersHaveChanged
        }
        return true
    }

    function resolveResponse(response, currentCounter, error) {
        if (currentCounter === callCounter.current) {
            if (error) {
                dispatch(actions.fetchFail(error))
            } else {
                dispatch(actions.fetchDone(response))
            }
        }
    }

    async function fetchResource() {
        debug && console.log("Fetching resource")
        callCounter.current = callCounter.current + 1
        const currentCounter = callCounter.current
        try {
            let url = `${resourceUrl}`

            if (state?.filters) {
                url = urlWithQueryParams(url, state.filters)
            }

            dispatch(actions.fetchStart())
            const newResource = await api.getResource(url, token)
            resolveResponse(newResource, currentCounter)
        } catch (error) {
            resolveResponse(null, currentCounter, error)
        }
    }

    function urlWithQueryParams(url, params) {
        const urlParams = Object.entries(params)
            .map(
                ([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`,
            )
            .join("&")
        return urlParams ? `${url}?${urlParams}` : url
    }

    function handleFiltersChange(newFilters = {}) {
        dispatch(actions.changeFilters(newFilters))
    }

    // ----------------------------------------------------------------------------
    // Hook return
    // ----------------------------------------------------------------------------

    const { resource, isLoading, error } = state

    return [
        resource,
        isLoading,
        { error, notFound: error?.statusCode === 404 },
        {
            fetch: fetchResource,
            onFiltersChange: handleFiltersChange,
        },
    ]
}
