import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

//------------------------------------------------------------------------------

function _FloatingButton(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { children, onClick, type, icon } = props

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function renderBtnContent() {
        return children ?? icon
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <button className={`${props.className}`} type={type} onClick={onClick}>
            {renderBtnContent()}
        </button>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_FloatingButton.propTypes = {
    className: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    type: PropTypes.string,
    style: PropTypes.string,
    icon: PropTypes.any,
    size: PropTypes.number,
    children: PropTypes.element,
}

_FloatingButton.defaultProps = {
    onClick: () => {},
    type: "submit",
    style: "default",
    size: 40,
}

// ----------------------------------------------------------------------------

const FloatingButton = styled(_FloatingButton)`
    & {
        position: relative;

        margin-right: 10px;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        max-width: ${(props) =>
            props.size ?? _FloatingButton.defaultProps.size}px;
        width: ${(props) => props.size ?? _FloatingButton.defaultProps.size}px;
        height: ${(props) => props.size ?? _FloatingButton.defaultProps.size}px;
        max-height: ${(props) =>
            props.size ?? _FloatingButton.defaultProps.size}px;

        border: none;
        border-radius: 50%;
        background-color: ${(props) => props.theme.colors.grey300};
        color: ${(props) => props.theme.colors.dark} !important;
        cursor: pointer;

        outline: none;
        transition: all 0.2s ease-in-out;

        z-index: 1;

        > * {
            z-index: 3;
        }

        :hover {
            ::before {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                width: 100%;
                height: 100%;
                border-radius: 50%;

                background-color: ${(props) => props.theme.colors.grey500};

                z-index: -1;
            }
        }

        :active {
            transform: scale(0.95);
        }
    }
`
// ----------------------------------------------------------------------------

export default FloatingButton
