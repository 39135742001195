import { Spin, message } from "antd"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import styled from "styled-components"

import * as api from "../../../../api"
import { CONTENT_PADDING } from "../../../_shared/components/Layout"
import { HEADER_HEIGHT } from "../../../_shared/components/Layout/Header"
import { useStateValue } from "../../../_shared/context/AppStateStore"
import useRestResource from "../../../_shared/hooks/useRestResource"
import Map from "./Map"

// ----------------------------------------------------------------------------

function _SensorsMap(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [{ token }] = useStateValue()

    const [machines] = useRestResource("/get_machines", token)

    const [sensors, setSensors] = useState()
    const [loading, setLoading] = useState(false)

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        if (machines) {
            fetchSensors()
        }
        //eslint-disable-next-line
    }, [machines])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    async function getSensorNumAlarms(sensor) {
        try {
            let r = await api.createResource("/ensys_app", token, {
                cmd: "recentAnomaly",
                data: JSON.stringify({
                    seconds: 604800,
                    sensor: sensor.id,
                }),
            })
            r = JSON.parse(r)
            return r?.data?.number_predicted_events ?? 0
        } catch (err) {
            // eslint-disable-next-line
            console.log(err)
            return 0
        }
    }

    async function fetchSensors() {
        let r = []
        setLoading(true)
        try {
            for (const machine of machines) {
                const s = await api.createResource("/get_sensor", token, {
                    machineserial: machine.serial,
                })
                for (const sensor of s) {
                    sensor.numAlarms = await getSensorNumAlarms(sensor)
                }
                r = r.concat(s)
            }
            setSensors(r)
        } catch (e) {
            // eslint-disable-next-line
            console.log(e)
            message.error("Impossibile recuperare i sensori")
        } finally {
            setLoading(false)
        }
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            <Spin spinning={loading} style={{ minHeight: 300, height: "100%" }}>
                <Map sensors={sensors} />
            </Spin>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_SensorsMap.propTypes = {
    className: PropTypes.string.isRequired,
}

_SensorsMap.defaultProps = {}

// ----------------------------------------------------------------------------

const SensorsMap = styled(_SensorsMap)`
    & {
        height: calc(100vh - ${HEADER_HEIGHT}px - ${CONTENT_PADDING * 2}px);
        .ant-spin-nested-loading {
            height: 100%;

            & .ant-spin-container {
                height: 100%;
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default SensorsMap
