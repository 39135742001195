import { Table, message } from "antd"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import styled from "styled-components"

import * as api from "../../../../../api"
import BottomSheet from "../../../../_shared/components/BottomSheet"
import { useStateValue } from "../../../../_shared/context/AppStateStore"
import getColumnsEvents from "../helpers/getColumnsEvents"

// ----------------------------------------------------------------------------

function _MachineEventsBottomSheet(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, onCancel, open, machine } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [{ token }] = useStateValue()

    useEffect(() => {
        machine && getSensors()
    }, [machine, open])

    const [sens, setSens] = useState(null)
    const [sensData, setSensData] = useState(null)

    useEffect(() => {
        const fetchData = async () => {
            const prodData = await Promise.all(
                sens.map(async (sensor) => {
                    return {
                        id: sensor.id,
                        data: await api.createResource("/ensys_app", token, {
                            cmd: "recentAnomaly",
                            data: JSON.stringify({
                                seconds: 604800,
                                sensor: sensor.id,
                            }),
                        }),
                        eventsPredictive: await api.createResource(
                            "/ensys_app",
                            token,
                            {
                                cmd: "getEvents",
                                data: JSON.stringify({
                                    predicted_events: 1,
                                    sensor: sensor.id,
                                    real_events: 0,
                                }),
                            },
                        ),
                        // eventsReal: await api.createResource("/ensys_app", token, {
                        //     cmd: "getEvents",
                        //     data: JSON.stringify({
                        //         predicted_events: 1,
                        //         sensor: sensor.id,
                        //         real_events: 1,
                        //     }),
                        // }),
                    }
                }),
            )

            setSensData(prodData)
            // setProductsData(prodData)
        }
        sens && fetchData()
    }, [sens])
    // eslint-disable-next-line

    async function getSensors() {
        const payload = {
            machineserial: machine.serial,
        }
        api.createResource("/get_sensor", token, payload)
            .then((response) => {
                setSens(response)
            })
            .catch((e) => {
                // eslint-disable-next-line
                console.log(e)
                message.error("Qualcosa è andato storto")
            })
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <BottomSheet
            title="Allarmi macchina"
            className={`${className}`}
            onCancel={onCancel}
            snapPoint={80}
            open={open}
        >
            <Table dataSource={sensData} columns={getColumnsEvents()} />
            {/* { sensData?.map(x => 
                <div className="sensorLine">{`ID:${x?.id}\t -> DATA:${x.data} EVENTS:${x.eventsPredictive}`}</div>
            )}     */}
        </BottomSheet>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_MachineEventsBottomSheet.propTypes = {
    className: PropTypes.string.isRequired,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
}

_MachineEventsBottomSheet.defaultProps = {}

// ----------------------------------------------------------------------------

const MachineEventsBottomSheet = styled(_MachineEventsBottomSheet)`
    & {
        .field {
            width: 200px;
            margin-right: 10px;
        }
        .sensorLine {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            display: flex;
            margin-bottom: 20px;
            width: 100%;
        }
    }
`
// ----------------------------------------------------------------------------

export default MachineEventsBottomSheet
