export default function getColumnsEvents(renderActions) {
    return [
        {
            title: "Id",
            key: "id",
            dataIndex: "id",
            sorter: {
                compare: (a, b) => a.id - b.id,
                multiple: 1,
            },
        },
        {
            title: "data",
            key: "data",
            dataIndex: "data",
            //  sorter: (a, b) => a?.val?.localeCompare(b?.val),
        },
        {
            title: "eventsPredictive",
            key: "eventsPredictive",
            dataIndex: "eventsPredictive",
            //  sorter: (a, b) => a?.val?.localeCompare(b?.val),
        },
        {
            title: "eventsReal",
            key: "eventsReal",
            dataIndex: "eventsReal",
            //  sorter: (a, b) => a?.val?.localeCompare(b?.val),
        },
    ]
}
