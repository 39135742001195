export default function getColumns(renderActions) {
    return [
        {
            title: "Name",
            key: "nane",
            dataIndex: "name",
            sorter: (a, b) => a?.name?.localeCompare(b.name),
        },
        {
            title: "Email",
            key: "email",
            dataIndex: "email",
            sorter: (a, b) => a?.email?.localeCompare(b.email),
        },
        {
            key: "actions",
            render: renderActions,
        },
    ]
}
