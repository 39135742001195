import { Button } from "antd"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

// ----------------------------------------------------------------------------

function _AppButton(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------
    const { role, onClick, loading } = props

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function getDerivedClass() {
        switch (role) {
            case "primary":
                return "primary"
            case "secondary":
                return "secondary"
            case "delete":
                return "delete"
            default:
                return "primary"
        }
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <Button
            htmlType="button"
            loading={loading}
            onClick={onClick && onClick}
            className={`${props.className} ${getDerivedClass()} ${
                props.disabled && "disabled"
            }`}
        >
            {props.children}
        </Button>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_AppButton.propTypes = {
    className: PropTypes.string.isRequired,
    children: PropTypes.any,
    role: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
}

_AppButton.defaultProps = {}

// ----------------------------------------------------------------------------

const AppButton = styled(_AppButton)`
    & {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        padding: 0px 20px;
        cursor: pointer;
        font-weight: 600;
        font-size: 16px;
        transition: all 300ms ease;
        font-weight: 600;

        &.disabled {
            cursor: not-allowed;
        }

        &.primary {
            background: ${({ theme }) => theme.colors.primary};
            color: ${({ theme }) => theme.colors.light};
            border: 2px solid ${({ theme }) => theme.colors.primary};
        }
        &.secondary {
            background: ${({ theme }) => theme.colors.light};
            border: 2px solid ${({ theme }) => theme.colors.primary};
            color: ${({ theme }) => theme.colors.primary};
        }
        &.delete {
            background: ${({ theme }) => theme.colors.error200};
            color: ${({ theme }) => theme.colors.error};
            border: 2px solid transparent;
        }
    }
`
// ----------------------------------------------------------------------------

export default AppButton
