import { useMemo, useState } from "react"

function useSearchFilter(data) {
    const [filter, setFilter] = useState("")

    const filteredData = useMemo(() => {
        const debounceValue = 1
        if (filter.length >= debounceValue) {
            return data.filter((item) => {
                return Object.values(item).some((value) => {
                    if (
                        typeof value === "string" &&
                        value.toLowerCase().includes(filter.toLowerCase())
                    ) {
                        return true
                    }
                    return false
                })
            })
        }
        return data
    }, [filter, data])

    return [filteredData, filter, setFilter]
}

export default useSearchFilter
