import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import styled from "styled-components"

import * as api from "../../../../api"
import PageTitle from "../../../_shared/components/PageTitle"
import AppTable from "../../../_shared/components/Table"
import TableActions from "../../../_shared/components/TableActions"
import { useStateValue } from "../../../_shared/context/AppStateStore"
import getColumnsTasks from "../Machines/helpers/getColumnsTasks"
import Chart from "./Chart"

// ----------------------------------------------------------------------------

function _Associations(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [filter, setFilter] = useState("")
    const [sort, setSort] = useState("new")

    const [tasks, setTasks] = useState()
    const [loading, setLoading] = useState(false)
    const [chart, setChart] = useState(null)

    const [{ token }] = useStateValue()
    // const [machines] = useRestResource("/get_batches", token)

    async function getTasks() {
        setLoading(true)
        const response = await api.createResource("/ensys_app", token, {
            cmd: "getAlgsSequenceList",
            data: JSON.stringify({
                rif: "task_id",
            }),
        })

        try {
            // setTasks()
            const prodData = await Promise.all(
                JSON.parse(response).data?.map(async (task) => {
                    return {
                        id: task[0],
                        val: task[2],
                        state: JSON.parse(task[3])?.stato,
                        label: task[1],
                        sensor: task[5],
                    }
                }),
            )
            setTasks(prodData)
        } catch {}

        setLoading(false)
        return response
    }

    useEffect(() => {
        !tasks && getTasks()
    }, [tasks])

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------
    // to do pagination

    // -------------------------------------
    // Component functions
    // -------------------------------------

    async function handleViewChart(row) {
        setChart(row.id)
    }

    function renderActions(row) {
        return <TableActions onViewChart={() => handleViewChart(row)} />
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            <PageTitle>Tasks</PageTitle>
            <AppTable
                dataSource={tasks}
                columns={getColumnsTasks(renderActions)}
                pagination={false}
                hidePagination
                onDelete={() => {}}
                onSearch={setFilter}
                searchValue={filter}
                sort={sort}
                disableSelection
                onSortChange={setSort}
                rowKey={(row) => row.id}
                loading={loading}
                locale={{
                    triggerDesc: "Ordina in ordine decrescente",
                    triggerAsc: "Ordina in ordine crescente",
                    cancelSort: "Annulla ordinamento",
                }}
                onRow={(record) => {
                    if (record?.state?.includes("FAILURE")) {
                        return {
                            style: { background: "#ffb9b9" },
                        }
                    }
                }}
            />
            <Chart taskId={chart} onClose={() => setChart(null)} />
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Associations.propTypes = {
    className: PropTypes.string.isRequired,
}

_Associations.defaultProps = {}

// ----------------------------------------------------------------------------

const Associations = styled(_Associations)`
    & {
        display: flex;
        flex-direction: column;
        .create-btn {
            width: 200px;
            align-self: flex-end;
            margin-bottom: 20px;
        }
    }
`
// ----------------------------------------------------------------------------

export default Associations
