import { Result, Spin } from "antd"
import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"

import * as api from "../../../../../../../api"
import { useStateValue } from "../../../../../../_shared/context/AppStateStore"
import { _WizardContext } from "../../_context/wizardContext"
import PerAxis from "./PerAxis"
import Simple from "./Simple"

// ----------------------------------------------------------------------------

function _AlgorithmForm(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const {
        className,
        currentAlgo,
        parsedAlgorithm,
        onConfirm,
        info,
        formState,
        setFormState,
        parametersValues,
        setParametersValues,
    } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [{ token }] = useStateValue()

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    const { dispatch } = useContext(_WizardContext)

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        if (currentAlgo) {
            loadAlgorithmParameters()
        } else {
            setParametersValues([])
        }
    }, [currentAlgo])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function isEmptyObject(obj) {
        if (!obj) {
            return true
        }
        return Object.keys(obj).length === 0
    }

    function selectAlgorithm() {
        dispatch({
            type: "SET_FETCHED_ALG_PAR",
            payload: {
                algoritmo: parsedAlgorithm.algoritmo,
                liv: parsedAlgorithm.liv,
                note: parsedAlgorithm.note,
                id: parsedAlgorithm.id,
                title: parsedAlgorithm.titolo,
                info: parsedAlgorithm.info,
            },
        })
        dispatch({ type: "SET_SELECTED_ALGORITHMS", payload: null })
        onConfirm()
    }

    function processResponse(data) {
        try {
            const parsed = JSON.parse(data)
            if (isEmptyObject(parsed.data)) {
                selectAlgorithm()
                return
            }

            setParametersValues(parsed.data)
        } catch {
            setParametersValues([])
            setError(true)
        }
    }

    const loadAlgorithmParameters = async () => {
        if (!currentAlgo) return
        setLoading(true)
        try {
            const response = await api.createResource("/ensys_app", token, {
                cmd: "getParAlg",
                data: JSON.stringify({
                    algoritmo: parsedAlgorithm.algoritmo,
                    faseAlgoritmo: "apply",
                }),
            })

            processResponse(response)
        } catch (e) {
            // eslint-disable-next-line
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    function renderForm() {
        if (loading) {
            return null
        }
        if (parametersValues.perOgniAsse) {
            return (
                <PerAxis
                    parameterValues={parametersValues}
                    parsedAlgorithm={parsedAlgorithm}
                    onConfirm={onConfirm}
                    formState={formState}
                    setFormState={setFormState}
                />
            )
        } else {
            return (
                <Simple
                    parsedAlgorithm={parsedAlgorithm}
                    onConfirm={onConfirm}
                    parametersValues={parametersValues}
                    formState={formState}
                    setFormState={setFormState}
                />
            )
        }
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    if (!currentAlgo) {
        return null
    }

    if (error) {
        return <Result status="error" />
    }

    return (
        <div className={`${className}`}>
            <Spin spinning={loading} style={{ minHeight: 300 }}>
                <div className="form-row">
                    <div className="left">{renderForm()}</div>
                    <div className="right">{info}</div>
                </div>
            </Spin>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_AlgorithmForm.propTypes = {
    className: PropTypes.string.isRequired,
}

_AlgorithmForm.defaultProps = {}

// ----------------------------------------------------------------------------

const AlgorithmForm = styled(_AlgorithmForm)`
    & {
        .left {
            width: 400px;
        }
    }
`
// ----------------------------------------------------------------------------

export default AlgorithmForm
