const DataMapper = {
    FEtoBE: (values) => {
        return {
            ...values,
            id: parseInt(values.id),
            owner: valueOrNull(values.owner),
            machineserial: valueOrNull(values.machineserial),
            machinebrand: valueOrNull(values.machinebrand),
            tag: valueOrNull(values.tag),
            password: valueOrNull(values.password),
        }
    },
    BEtoFE: (values) => {
        return {
            ...values,
        }
    },
}

export default DataMapper

function valueOrNull(value) {
    return value ? value : null
}
