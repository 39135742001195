import { DatePicker, Input } from "antd"
import moment from "moment"
import PropTypes from "prop-types"
import React, { useContext } from "react"
import styled from "styled-components"

import FieldLabel from "../../../../../_shared/components/FormItems/FieldLabel/index"
import SteppedBottomSheet from "../../../../../_shared/components/SteppedBottomSheet"
import { _WizardContext } from "../_context/wizardContext"

const { Step } = SteppedBottomSheet
const { RangePicker } = DatePicker

// ----------------------------------------------------------------------------

function _BaseStep(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const { dispatch, state } = useContext(_WizardContext)
    const { datas, label } = state

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function setDatas(value) {
        dispatch({ type: "SET_DATAS", payload: value })
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <Step.Wrapper>
            <Step.Header
                title={`Creazione di un task associato al sensore ${state.sensor?.id}`}
                subTitle=" Indica un'etichetta per identificare il task e seleziona il periodo di analisi."
            />
            <Step.Content>
                <div className={`${className}`}>
                    <FieldLabel>Etichetta del task</FieldLabel>
                    <Input
                        style={{
                            height: "50px",
                            width: "300px",
                            marginBottom: 20,
                        }}
                        value={state.label}
                        onChange={(e) =>
                            dispatch({
                                type: "SET_LABEL",
                                payload: e.target.value,
                            })
                        }
                    />
                    <FieldLabel>Periodo di analisi</FieldLabel>
                    <RangePicker
                        style={{ height: "50px", width: "300px" }}
                        value={
                            state.datas
                                ? [
                                      moment(state.datas[0]),
                                      moment(state.datas[1]),
                                  ]
                                : null
                        }
                        onChange={(value, dateStrings) => {
                            const [d1, d2] = dateStrings
                            if (d1 && d2) {
                                setDatas(dateStrings)
                            } else {
                                setDatas(null)
                            }
                        }}
                    />
                </div>
            </Step.Content>
            <Step.Footer
                canGoNext={
                    label?.length > 0 && datas !== null && datas.length === 2
                }
            />
        </Step.Wrapper>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_BaseStep.propTypes = {
    className: PropTypes.string.isRequired,
}

_BaseStep.defaultProps = {}

// ----------------------------------------------------------------------------

const BaseStep = styled(_BaseStep)`
    & {
    }
`
// ----------------------------------------------------------------------------

export default BaseStep
