import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import FloatingButton from "../../../FloatingButton"
import { useBottomSheet } from "../.."

// ----------------------------------------------------------------------------

function _Header(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, title, subTitle } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const {
        dismiss,
        title: defaultTitle,
        subTitle: defaultSubTitle,
    } = useBottomSheet()

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    const closeIcon = <span>X</span>

    return (
        <div className={`${className}`}>
            <div className="sheet-title">
                {title || defaultTitle || ""}
                <div className="sheet-description">
                    {subTitle || defaultSubTitle || ""}
                </div>
            </div>

            <FloatingButton icon={closeIcon} onClick={dismiss} />
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Header.propTypes = {
    className: PropTypes.string.isRequired,
}

_Header.defaultProps = {}

// ----------------------------------------------------------------------------

const Header = styled(_Header)`
    & {
        margin-bottom: 20px;
        display: flex;
        flex-direction: row;

        .sheet-title {
            flex: 1;

            font-size: 26px;
            font-weight: 600;
            /* color: ${(props) => props.theme.colors.textPrimary}; */
        }
        .sheet-description {
            flex: 1;

            font-size: 14px;
            font-weight: normal !important;
            text-align: left;

            /* color: ${(props) => props.theme.colors.textPrimary}; */
        }

        .close-icn {
            margin-left: 10px;

            width: 16px;
            height: 16px;
            background-size: 16px 16px;
            background-repeat: no-repeat;
            background-origin: content-box;

            cursor: pointer;

            transition: all 0.2s ease;
        }
    }
`
// ----------------------------------------------------------------------------

export default Header
