import PropTypes from "prop-types"
import React from "react"
import { FiCheck as Icon } from "react-icons/fi"
import styled, { css } from "styled-components"

// ----------------------------------------------------------------------------

function _Checkbox(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, label, value, onChange, error, disabled } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            <div
                className={`box-wrapper ${disabled ? "disabled" : ""}`}
                onClick={() => onChange && !disabled && onChange(!value)}
            >
                <div className="checkbox-box">
                    <Icon />
                </div>
                <label className="checkbox-label">{label}</label>
            </div>
            <div className="error-container" />
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Checkbox.propTypes = {
    className: PropTypes.string.isRequired,
    label: PropTypes.string,
    value: PropTypes.bool,
    onChange: PropTypes.func,
    error: PropTypes.object,
}

_Checkbox.defaultProps = {}

// ----------------------------------------------------------------------------

const Checkbox = styled(_Checkbox)`
    & {
        .box-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            cursor: pointer;

            &.disabled {
                cursor: not-allowed;
                .checkbox-label {
                    cursor: not-allowed;
                }
            }
            .checkbox-box {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 20px;
                height: 20px;
                background-color: ${({ theme }) => theme.colors.light};
                margin-right: 8px;
                border-radius: 4px;
                ${({ value, theme }) => css`
                    border: 2px solid ${theme.colors.inputBorder};
                `}
                svg {
                    stroke: ${({ theme, value }) =>
                        value ? theme.colors.dark : "transparent"};
                    width: 16px;
                    height: 16px;
                }
            }
            .checkbox-label {
                cursor: pointer;
                user-select: none;
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default Checkbox
