import { Divider, Tabs, Tag, message } from "antd"
import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useMemo } from "react"
import { FaCheckCircle } from "react-icons/fa"
import { IoAlertCircle } from "react-icons/io5"
import styled from "styled-components"

import { statusColors } from "../helpers"
import TaskList from "./TaskList"

// ----------------------------------------------------------------------------

function _Popup(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------
    /** @type {{sensorGroup: any[]}} */
    const { className, sensorGroup: sg } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    const sensorGroup = useMemo(() => {
        const group = [...sg]
        group.sort((a, b) => a.id - b.id)
        return group
    }, [sg])

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function renderTab(sensor) {
        return (
            <div className="tab-content">
                <div className="section">
                    <div className="sensor-id">{sensor.id}</div>
                    {sensor.tag && (
                        <div className="sensor-tag">
                            <CopyTag color="blue">{sensor.tag}</CopyTag>
                        </div>
                    )}
                </div>
                <Divider />
                <div className="section">
                    <span className="section-label">Macchina</span>
                    <div className="sensor-machine-brand">
                        <span className="data-label">Brand</span>
                        <CopyTag className="data-value">
                            {sensor.machinebrand}
                        </CopyTag>
                    </div>
                    <div className="sensor-machine-serial">
                        <span className="data-label">Serie</span>
                        <CopyTag className="data-value">
                            {sensor.machineserial}
                        </CopyTag>
                    </div>
                </div>
                <Divider />
                <div className="section">
                    <span className="section-label">Sensore</span>
                    <div className="sensor-machine-brand">
                        <span className="data-label">Coordinate</span>
                        <CopyTag className="data-value">
                            {sensor.latLng.join(", ")}
                        </CopyTag>
                    </div>
                    <div className="sensor-machine-brand">
                        <span className="data-label">Allarmi [indicatore]</span>
                        <Tag
                            color={sensor.numAlarms === 0 ? "green" : "red"}
                            className="data-value"
                        >
                            {String(sensor.numAlarms)}
                        </Tag>
                    </div>
                </div>
                <Divider />
                <div className="section">
                    <span className="section-label">Task</span>
                    <TaskList sensorId={sensor.id} />
                </div>
            </div>
        )
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            <Tabs
                tabPosition="left"
                items={sensorGroup.map((sensor) => {
                    const isOk = sensor.numAlarms === 0
                    return {
                        children: renderTab(sensor),
                        key: sensor.id,
                        label: (
                            <span className="tab-heading">
                                {isOk ? (
                                    <FaCheckCircle
                                        className="icon-ok"
                                        color={statusColors.ok}
                                    />
                                ) : (
                                    <IoAlertCircle
                                        className="icon-err"
                                        color={statusColors.error}
                                    />
                                )}
                                <span className="label">{sensor.id}</span>
                            </span>
                        ),
                    }
                })}
            />
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Popup.propTypes = {
    className: PropTypes.string.isRequired,
}

_Popup.defaultProps = {}

// ----------------------------------------------------------------------------

const PopupContent = styled(_Popup)`
    & {
        .tab-heading {
            display: flex;
            align-items: center;
            gap: 10px;

            svg {
                width: 15px;
                height: 15px;

                &.icon-err {
                    width: 20px;
                    height: 20px;
                }
            }

            .label {
                font-weight: bold;
            }
        }
        .ant-tabs-ink-bar {
            background: #a6a6a6;
        }

        .copy-tag {
            cursor: pointer;
        }

        .tab-content {
            .section {
                display: flex;
                flex-direction: column;
                gap: 10px;

                .section-label {
                    font-weight: bold;
                    font-size: 15px;
                }
            }

            .data-label {
                font-size: 14px;
                display: block;
                margin-bottom: 5px;
            }

            .data-value {
                font-size: 15px;
            }

            .sensor-id {
                font-weight: bold;
                font-size: 16px;
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default PopupContent

function CopyTag(props) {
    async function handleClick() {
        try {
            await navigator.clipboard.writeText(props.copy || props.children)
            message.success("Testo copiato negli appunti!")
        } catch {
            message.error("Non è stato possibile copiare il testo!")
        }
    }
    return (
        <Tag
            {...props}
            className={classNames([props.className, "copy-tag"])}
            onClick={handleClick}
        />
    )
}
