import Color from "colorjs.io"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

// ----------------------------------------------------------------------------

function _ColorScale(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, min, max, minColor, maxColor } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function renderRange() {
        const c1 = new Color(minColor)
        const c2 = new Color(maxColor)
        const range = c2.range(c1)
        const granularity = 300
        const colors = []
        for (let i = 0; i <= 1; i += 1 / granularity) {
            colors.push(range(i).toString({ format: "hex" }))
        }
        return (
            <div className="range">
                {colors.map((color, index) => {
                    return (
                        <div
                            key={index}
                            className="color"
                            style={{ backgroundColor: color }}
                        />
                    )
                })}
            </div>
        )
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            <div className="label">{max?.toFixed(2)}</div>
            {renderRange()}
            <div className="label">{min?.toFixed(2)}</div>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_ColorScale.propTypes = {
    className: PropTypes.string.isRequired,
}

_ColorScale.defaultProps = {}

// ----------------------------------------------------------------------------

const ColorScale = styled(_ColorScale)`
    & {
        margin-left: 36px;
        width: 60px;

        display: flex;
        flex-direction: column;
        align-items: center;

        .label {
            text-align: center;
        }

        .range {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 200px;
            width: 100%;
            border: 1.5px solid black;

            .color {
                flex: 1;
                width: 100%;
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default ColorScale
