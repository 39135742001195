import { message } from "antd"
import dayjs from "dayjs"
import PropTypes from "prop-types"
import React, { useState } from "react"
import styled from "styled-components"

import { createResource } from "../../../../api"
import AppButton from "../../../_shared/components/AppButton"
import PageTitle from "../../../_shared/components/PageTitle"
import AppTable from "../../../_shared/components/Table"
import { useStateValue } from "../../../_shared/context/AppStateStore"
import useAsyncMemo from "../../../_shared/hooks/useAsyncMemo"
import useRestResource from "../../../_shared/hooks/useRestResource"
import EventsBottomSheet from "./EventsBottomSheet"

// ----------------------------------------------------------------------------

function _MachineHistory(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [{ token }] = useStateValue()
    const [machines, machinesLoading] = useRestResource(
        "/get_usermachines",
        token,
    )
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    })

    const [selected, setSelected] = useState(null)
    const [trigger, setTrigger] = useState(0)
    const reload = () => setTrigger((t) => t + 1)

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    const [sensors, sensorsLoading] = useAsyncMemo(
        async () => {
            if (!machines || !machines.length) {
                return
            }

            const promises = []
            for (const m of machines) {
                promises.push(
                    createResource(`get_sensor`, token, {
                        machineserial: m.machineserial,
                    }),
                )
            }
            let s = []
            const res = await Promise.all(promises)
            for (const r of res) {
                if (r && r.length) {
                    s = s.concat(r)
                }
            }

            s = s.filter((i, index, self) => {
                return self.findIndex((t) => t.id === i.id) === index
            })

            return s
        },
        [machines],
        true,
    )

    const [data, loading] = useAsyncMemo(
        async () => {
            if (!sensors || !sensors.length) {
                return
            }

            const promises = []
            for (const s of sensors) {
                promises.push(
                    createResource(`ensys_app`, token, {
                        cmd: "getEvents",
                        data: JSON.stringify({
                            sensor: s.id,
                            predicted_events: 1,
                            real_events: 1,
                        }),
                    }).catch(() => {}),
                )
            }
            const res = await Promise.all(promises)
            let a = []
            for (let r of res) {
                try {
                    r = JSON.parse(r)
                    const { predicted_events: predictedEvents = [] } = r.data
                    const { real_events: realEvents = [] } = r.data
                    a = a.concat(
                        predictedEvents.map((e) => ({
                            ...e,
                            source: "predicted",
                        })),
                    )
                    a = a.concat(
                        realEvents.map((e) => ({ ...e, source: "real" })),
                    )
                } catch {}
            }

            return a
        },
        [sensors, trigger],
        true,
    )

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    async function handleSubmit(formValues) {
        try {
            await createResource(`ensys_app`, token, {
                cmd: "setEventReal",
                data: JSON.stringify(formValues),
            })
            message.success("Evento inserito correttamente")
            setSelected(null)
            reload()
        } catch (err) {
            message.error("Errore nell'inserimento dell'evento")
            // eslint-disable-next-line
            console.log(err)
        }
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            <PageTitle>Storico macchina</PageTitle>
            <div className="flex-left">
                <AppButton
                    onClick={() => {
                        setSelected({})
                    }}
                >
                    Inserisci evento
                </AppButton>
            </div>
            <AppTable
                loading={loading || machinesLoading || sensorsLoading}
                pagination={{ ...pagination, total: data?.length ?? 0 }}
                dataSource={data}
                rowKey={(i) => JSON.stringify(i)}
                setPagination={setPagination}
                hideHeader
                disableSelection
                onChange={(pag) => setPagination(pag)}
                columns={getColumns()}
            />
            <EventsBottomSheet
                open={!!selected}
                onClose={() => setSelected(null)}
                onSubmit={handleSubmit}
                defaultValues={selected}
            />
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_MachineHistory.propTypes = {
    className: PropTypes.string.isRequired,
}

_MachineHistory.defaultProps = {}

// ----------------------------------------------------------------------------

const MachineHistory = styled(_MachineHistory)`
    & {
        .flex-left {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 20px;
        }
    }
`
// ----------------------------------------------------------------------------

export default MachineHistory

function getColumns() {
    return [
        {
            key: "sensor",
            title: "Sensore",
            dataIndex: "sensor",
            sorter: ({ sensor: a }, { sensor: b }) => a - b,
        },
        {
            key: "source",
            title: "Sorgente",
            dataIndex: "source",
            sorter: ({ source: a }, { source: b }) => a.localeCompare(b),
        },
        {
            key: "time",
            title: "Data",
            dataIndex: "time_begin",
            render: (time) => dayjs(time).format("DD/MM/YYYY HH:mm:ss"),
            sorter: ({ time: a }, { time: b }) =>
                dayjs(a).unix() - dayjs(b).unix(),
        },
        {
            key: "type",
            title: "Tipologia",
            dataIndex: "eventtype",
            sorter: ({ eventtype: a }, { eventtype: b }) => a.localeCompare(b),
        },
        {
            key: "guasto",
            title: "Guasto",
            dataIndex: "guasto",
            sorter: ({ guasto: a }, { guasto: b }) => {
                if (a && b) {
                    return a.localeCompare(b)
                }
                return 0
            },
        },
        {
            key: "solizione",
            title: "Soluzione guasto",
            dataIndex: "soluzione_guasto",
            sorter: ({ soluzione_guasto: a }, { soluzione_guasto: b }) => {
                if (a && b) {
                    return a.localeCompare(b)
                }
                return 0
            },
        },
    ]
}
