import React, { createContext } from "react"

import useWizardReducer from "./_reducer"

export const _WizardContext = createContext()

export const Provider = _WizardContext.Provider

function WizardContext({ children }) {
    const reducer = useWizardReducer()
    return <Provider value={reducer}>{children}</Provider>
}

export default WizardContext
