import { Progress } from "antd"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import styled from "styled-components"

// ----------------------------------------------------------------------------
let interval = undefined

function _ProgressBar(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { onLoadComplete } = props

    const [running, setRunning] = useState(true)
    const [progress, setProgress] = useState(0)

    useEffect(() => {
        if (running) {
            interval = setInterval(() => {
                setProgress((prev) => prev + Math.random(0, 5))
            }, 10)
        } else {
            clearInterval(interval)
        }
    }, [running])

    useEffect(() => {
        if (progress >= 100) {
            setRunning(false)
            clearInterval(interval)
            onLoadComplete()
        }
    }, [progress])

    const { className } = props

    return (
        <h1 className={`${className}`}>
            <div className="Row">
                <Progress percent={progress} showInfo={false} />
            </div>
        </h1>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_ProgressBar.propTypes = {
    className: PropTypes.string.isRequired,
}

_ProgressBar.defaultProps = {}

// ----------------------------------------------------------------------------

const ProgressBar = styled(_ProgressBar)`
    & {
    }
    .Row {
        width: 50%; /*Optional*/

        border-spacing: 5px; /*Optional*/
        margin: auto;
        display: flex;
        justify-content: center;
    }
    .Point {
        display: table-cell;
        border-radius: 20px;
        height: 20px;
        width: 20px;
        background-color: #0062ff; /*Optional*/
    }
`
// ----------------------------------------------------------------------------

export default ProgressBar
