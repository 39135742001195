import { message } from "antd"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import styled from "styled-components"

import * as api from "../../../../api"
import AppButton from "../../../_shared/components/AppButton"
import PageTitle from "../../../_shared/components/PageTitle"
import Table from "../../../_shared/components/Table"
import TableActions from "../../../_shared/components/TableActions"
import { useStateValue } from "../../../_shared/context/AppStateStore"
import useRestResource from "../../../_shared/hooks/useRestResource"
import useSearchFilter from "../../../_shared/hooks/useSearchFilter"
import ChangePasswordBottomSheettomSheet from "./ChangePasswordBottomSheet"
import UserMachinesBottomSheet from "./UserMachinesBottomSheet"
import UsersBottomSheet from "./UsersBottomSheet"
import getColumns from "./helpers/getColumns"

// ----------------------------------------------------------------------------

function _Users(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [sort, setSort] = useState({ value: "", label: "", direction: "" })

    const [selectedItem, setSelectedItem] = useState()
    const [{ token }] = useStateValue()
    const [users, , , { fetch: fetchResource }] = useRestResource(
        "/get_users",
        token,
    )

    const [filteredUsers, filter, setFilter] = useSearchFilter(users)
    const [openUserDetail, setOpenUserDetail] = useState()
    const [selectedForMachines, setSelectedForMachines] = useState()

    const [pagination, setPagination] = useState({
        pageSize: 10,
        current: 1,
        total: users?.length,
    })

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------
    useEffect(() => {
        if (users) {
            setPagination({ ...pagination, total: users.length })
        }
    }, [users])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    async function deleteUser(values) {
        try {
            const deleteUsers = users.filter((x) => values.includes(x.email))
            const body = JSON.stringify(deleteUsers)
                .replace("[", "")
                .replace("]", "")
            await api.deleteResource("/delete_user", token, body)

            setSelectedItem(null)

            message.success("Utente eliminato con successo")
            fetchResource()
        } catch (error) {
            message.error(
                "Impossibile eliminare l'utente. Verificare i dati o riprovare più tardi",
            )
            // eslint-disable-next-line no-console
            console.error("[Error] in deleteUser: ", error)
        }
    }

    async function handleCreateUser(values) {
        try {
            if (values.admin === undefined) {
                values.admin = false
            }
            await api.createResource("/new_user", token, values)

            setSelectedItem(null)
            message.success("Utente creato con successo")
            fetchResource()
        } catch (error) {
            message.error(
                "Impossibile creare l'utente. Verificare i dati o riprovare più tardi",
            )
            // eslint-disable-next-line no-console
            console.error("[Error] in handleCreateMachine: ", error)
        }
    }

    async function handleChangePassword(values) {
        try {
            if (
                String(values?.password) !== String(values?.confirmPassword) ||
                (String(values?.password) ===
                    String(values?.confirmPassword)) ===
                    "" ||
                !values?.password ||
                !values.confirmPassword
            ) {
                message.error("Le due password non corrispondono o sono vuote")
                return
            }

            await api.createResource("/ensys_app", token, {
                cmd: "changePwdUser",
                data: JSON.stringify({
                    userRif: openUserDetail.email,
                    newPassword: values?.password,
                }),
            })

            message.success("Password cambiata con successo")
            setOpenUserDetail(null)
        } catch (error) {
            message.error(
                "Impossibile creare l'utente. Verificare i dati o riprovare più tardi",
            )
            // eslint-disable-next-line no-console
            console.error("[Error] in handleCreateMachine: ", error)
        }
    }

    function renderActions(row) {
        return (
            <TableActions
                onEdit={() => setOpenUserDetail(row)}
                onView={() => setSelectedForMachines(row)}
            />
        )
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            <AppButton
                onClick={() => setSelectedItem({})}
                className="create-btn"
            >
                Crea utente
            </AppButton>
            <PageTitle>Utenti</PageTitle>
            <Table
                dataSource={filteredUsers}
                columns={getColumns(renderActions)}
                pagination={pagination}
                setPagination={setPagination}
                onDelete={deleteUser}
                onSearch={setFilter}
                searchValue={filter}
                sort={sort}
                onSortChange={setSort}
                rowKey={(i) => i.email}
                locale={{
                    triggerDesc: "Ordina in ordine decrescente",
                    triggerAsc: "Ordina in ordine crescente",
                    cancelSort: "Annulla ordinamento",
                }}
            />
            <ChangePasswordBottomSheettomSheet
                open={!!openUserDetail}
                defaultValues={selectedItem}
                onCancel={() => setOpenUserDetail(false)}
                onConfirm={handleChangePassword}
            />
            <UsersBottomSheet
                open={!!selectedItem}
                defaultValues={selectedItem}
                onCancel={() => setSelectedItem(null)}
                onConfirm={handleCreateUser}
            />
            <UserMachinesBottomSheet
                user={selectedForMachines}
                onClose={() => setSelectedForMachines(null)}
            />
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Users.propTypes = {
    className: PropTypes.string.isRequired,
}

_Users.defaultProps = {}

// ----------------------------------------------------------------------------

const Users = styled(_Users)`
    & {
        display: flex;
        flex-direction: column;
        .create-btn {
            width: 200px;
            align-self: flex-end;
            margin-bottom: 20px;
        }
    }
`
// ----------------------------------------------------------------------------

export default Users
