import PropTypes from "prop-types"
import React from "react"
import { Link, useLocation } from "react-router-dom"
import styled from "styled-components"

import Logo from "../../../assets/vibes_logo.png"
import HeaderRight from "./HeaderRight"

// ----------------------------------------------------------------------------
export const HEADER_HEIGHT = 70
function _Header(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const { pathname: path } = useLocation()

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    const routes = [
        {
            name: "Utenti",
            path: "/",
        },
        {
            name: "Macchine",
            path: "/machines",
        },
        {
            name: "Sensori",
            path: "/sensors",
        },
        {
            name: "Mappa",
            path: "/sensors-map",
        },
        {
            name: "Tasks",
            path: "/tasks",
        },
        {
            name: "Allarmi",
            path: "/alarms",
        },
        {
            name: "Storico",
            path: "/machine-history",
        },

        {
            name: "Batch",
            path: "/batch-files",
        },
    ]

    function getLineLeft() {
        const routeIndex = routes.findIndex((route) => route.path === path)
        return routeIndex * linkWidth
    }

    function getLineShowing() {
        return true
    }

    function renderLogo() {
        return (
            <div className="logo">
                <img src={Logo} alt="Logo florveneto" width={132} height={42} />
            </div>
        )
    }

    function getClassName(routePath) {
        return `link ${path === routePath && "active"}`
    }

    function renderLinks() {
        return (
            <div className="links">
                <div className="link-row">
                    {routes.map((route) => (
                        <Link
                            key={route.path}
                            className={getClassName(route.path)}
                            to={route.path}
                        >
                            {route.name}
                        </Link>
                    ))}
                </div>
                {getLineShowing() && (
                    <div className="line" style={{ left: getLineLeft() }} />
                )}
            </div>
        )
    }

    function renderRight() {
        return <HeaderRight className="right" />
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            {renderLogo()}
            {renderLinks()}
            {renderRight()}
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Header.propTypes = {
    className: PropTypes.string.isRequired,
}

_Header.defaultProps = {}

// ----------------------------------------------------------------------------

const linkWidth = 100
const linkMargin = 25

const Header = styled(_Header)`
    & {
        width: 100vw;
        height: 70px;
        background-color: white;
        box-shadow: inset 0px -1px 0px #e2e2ea;

        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px ${({ theme }) => theme.spacing.s}px;

        position: relative;

        .logo {
            display: flex;
            align-items: center;
            justify-content: center;

            position: absolute;
            left: ${({ theme }) => theme.spacing.s}px;

            img {
                object-fit: contain;
            }
        }

        .links {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            position: relative;
            .link-row {
                display: flex;
                align-items: center;
                justify-content: center;
                .spacer {
                    width: ${linkMargin}px;
                }
                .link {
                    color: inherit;
                    width: ${linkWidth}px;
                    display: block;
                    text-align: center;
                    font-weight: 500;
                    transition: all 200ms ease;

                    &:hover {
                        color: ${({ theme }) => theme.colors.primary};
                    }

                    &.active {
                        color: ${({ theme }) => theme.colors.primary};
                        font-weight: 600;
                    }
                }
            }

            .line {
                height: 3px;
                width: ${linkWidth}px;
                background-color: ${({ theme }) => theme.colors.primary};
                margin-top: auto;
                position: absolute;
                bottom: 0;
                left: 0;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                transition: left 200ms ease;
            }
        }

        .right {
            position: absolute;
            right: ${({ theme }) => theme.spacing.s}px;
        }
    }
`
// ----------------------------------------------------------------------------

export default Header
