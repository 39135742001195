import "antd/dist/antd.css"
import "leaflet/dist/leaflet.css"

import "./index.css"

import { Chart as ChartJS, registerables } from "chart.js"
import { MatrixController, MatrixElement } from "chartjs-chart-matrix"
import React from "react"
import ReactDOM from "react-dom"

import App from "./App"

ChartJS.register(...registerables)
ChartJS.register(MatrixController, MatrixElement)

const canvasBgPlugin = {
    id: "customCanvasBackgroundColor",
    beforeDraw: (chart, args, options) => {
        const { ctx } = chart
        ctx.save()
        ctx.globalCompositeOperation = "destination-over"
        ctx.fillStyle = options.color || "#fff"
        ctx.fillRect(0, 0, chart.width, chart.height)
        ctx.restore()
    },
}

const canvasCachePlugin = {
    id: "canvasCachePlugin",
    afterRender: (chart, args, options) => {
        if (!chart.$rendered) {
            chart.$rendered = true
            const base64 = chart.toBase64Image()
            const { cacheId } = options
            try {
                localStorage.setItem(cacheId, base64)
            } catch {
                localStorage.removeItem(cacheId)
            }
        }
    },
}

ChartJS.register(canvasBgPlugin, canvasCachePlugin)

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root"),
)
