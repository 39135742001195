import { message } from "antd"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { FaRegUser } from "react-icons/fa"
import { FiAlertTriangle, FiEye, FiLink } from "react-icons/fi"
import styled from "styled-components"

import * as api from "../../../../api"
import AppButton from "../../../_shared/components/AppButton"
import FloatingButton from "../../../_shared/components/FloatingButton"
import PageTitle from "../../../_shared/components/PageTitle"
import Table from "../../../_shared/components/Table"
// import TableActions from "../../../_shared/components/TableActions"
import { useStateValue } from "../../../_shared/context/AppStateStore"
import useRestResource from "../../../_shared/hooks/useRestResource"
import useSearchFilter from "../../../_shared/hooks/useSearchFilter"
import ListMachineUsersBottomSheet from "./ListMachineUsersBottomSheet"
import MachineEventsSheet from "./MachineEventsBottomSheet"
import MachineSensorsSheet from "./MachineSensorsBottomSheet"
import MachineUserBottomSheet from "./MachineUserBottomSheet"
import MachinesBottomSheet from "./MachinesBottomSheet"
import getColumns from "./helpers/getColumns"

// ----------------------------------------------------------------------------

function _Machines(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [sort, setSort] = useState({
        id: 0,
        value: "",
        label: "",
        direction: "",
    })

    const [selectedItem, setSelectedItem] = useState()

    const [selectedAssignmentItem, setSelectedAssignmentItem] = useState(null)

    const [openMach, setOpenMach] = useState(false)
    const [openSens, setOpenSens] = useState(false)
    const [openEven, setOpenEvents] = useState(false)
    const [viewMachineUsers, setViewMachineUsers] = useState(null)

    const [{ token }] = useStateValue()
    const [machines, , , { fetch: fetchMachines }] = useRestResource(
        "/get_machines",
        token,
    )
    const [filteredMachines, filter, setFilter] = useSearchFilter(machines)

    const [pagination, setPagination] = useState({
        pageSize: 10,
        current: 1,
        total: machines?.length,
    })

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        if (machines) {
            setPagination({ ...pagination, total: machines.length })
        }
    }, [machines])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function handleSortChange(value) {
        setSort(value)
    }

    async function handleCreateMachine(values, selectedSensors) {
        try {
            await api.createResource("/new_machine", token, values)

            setSelectedItem(null)

            message.success("Macchina creata con successo")
        } catch {
            message.error(
                "Impossibile creare la macchina. Verificare i dati o riprovare più tardi",
            )
        }
    }

    function openSensors(row) {
        setSelectedAssignmentItem(row)
        setOpenSens(true)
    }

    function openMachineDetail(row) {
        setSelectedAssignmentItem(row)
        setOpenMach(true)
    }

    function openEvents(row) {
        setSelectedAssignmentItem(row)
        setOpenEvents(true)
    }

    function renderActions(row) {
        return (
            <div className="actions">
                <FloatingButton
                    onClick={() => openMachineDetail(row)}
                    icon={<FiLink />}
                />
                <FloatingButton
                    onClick={() => openSensors(row)}
                    icon={<FiEye />}
                />
                <FloatingButton
                    onClick={() => setViewMachineUsers(row)}
                    icon={<FaRegUser />}
                />
                <FloatingButton
                    onClick={() => openEvents(row)}
                    icon={<FiAlertTriangle />}
                />
            </div>
        )
    }

    async function handleReassign(sensor, newMachine) {
        try {
            const response = await api.createResource("/ensys_app", token, {
                cmd: "changeSensorMachine",
                data: JSON.stringify({
                    sensore: sensor.id,

                    macchinaNuova: newMachine,
                }),
            })

            JSON.parse(response)?.status === "ok"
                ? message.success("Operazione riuscita")
                : message.error("Operazione non riuscita")
            setOpenSens(false)
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error)
        }
    }

    function handleAssign(user) {
        const payload = {
            machineserial: selectedAssignmentItem?.serial,
            machinebrand: selectedAssignmentItem?.brand,
            username: user,
        }
        api.createResource("/associate_machine", token, payload)
            .then((response) => {
                fetchMachines()
                message.success("Operazione riuscita")
            })
            .catch((e) => {
                // eslint-disable-next-line no-console
                console.log(e)
                message.error("Qualcosa è andato storto")
            })
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------
    return (
        <div className={`${className}`}>
            <AppButton
                onClick={() => setSelectedItem({})}
                className="create-btn"
            >
                Crea macchina
            </AppButton>
            <PageTitle>Macchine</PageTitle>
            <Table
                dataSource={filteredMachines}
                columns={getColumns(renderActions)}
                pagination={pagination}
                setPagination={setPagination}
                onDelete={() => {}}
                onSearch={setFilter}
                searchValue={filter}
                sort={sort}
                disableSelection
                onSortChange={handleSortChange}
                rowKey={(row) => row.serial}
                locale={{
                    triggerDesc: "Ordina in ordine decrescente",
                    triggerAsc: "Ordina in ordine crescente",
                    cancelSort: "Annulla ordinamento",
                }}
            />
            <MachinesBottomSheet
                open={!!selectedItem}
                defaultValues={selectedItem}
                onCancel={() => setSelectedItem(null)}
                onConfirm={handleCreateMachine}
            />
            <MachineUserBottomSheet
                open={openMach}
                onCancel={() => setOpenMach(false)}
                machine={selectedAssignmentItem}
                onConfirm={handleAssign}
            />
            <MachineSensorsSheet
                open={openSens}
                onCancel={() => setOpenSens(false)}
                machine={selectedAssignmentItem}
                onConfirm={handleReassign}
            />
            <MachineEventsSheet
                open={openEven}
                onCancel={() => setOpenEvents(false)}
                machine={selectedAssignmentItem}
            />
            <ListMachineUsersBottomSheet
                open={!!viewMachineUsers}
                machineserial={viewMachineUsers?.serial}
                onClose={() => setViewMachineUsers(null)}
            />
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Machines.propTypes = {
    className: PropTypes.string.isRequired,
}

_Machines.defaultProps = {}

// ----------------------------------------------------------------------------

const Machines = styled(_Machines)`
    & {
        display: flex;
        flex-direction: column;
        .create-btn {
            width: 200px;
            align-self: flex-end;
            margin-bottom: 20px;
        }
        .actions {
            display: "flex";
            flex-direction: "row";
        }
    }
`
// ----------------------------------------------------------------------------

export default Machines
