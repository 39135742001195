import PropTypes from "prop-types"
import React from "react"
import { IoIosArrowBack as Arrow } from "react-icons/io"
import ReactPaginate from "react-paginate"
import styled from "styled-components"

// ----------------------------------------------------------------------------

function _Pagination(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, pagination, setPagination } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function handleChange({ selected }) {
        setPagination({ ...pagination, current: selected + 1 })
    }

    function renderPrev() {
        return (
            <div className="button prev">
                <Arrow />
            </div>
        )
    }

    function renderNext() {
        return (
            <div className="button rotate">
                <Arrow />
            </div>
        )
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    const pageCount =
        pagination?.total && pagination?.pageSize
            ? Math.ceil(pagination?.total / pagination?.pageSize)
            : 0

    return (
        <div className={`${className}`}>
            <ReactPaginate
                className="pagination-inner"
                previousLabel={renderPrev()}
                nextLabel={renderNext()}
                pageCount={pageCount}
                onPageChange={handleChange}
                pageRangeDisplayed={5}
                forcePage={pagination?.current - 1}
                activeClassName="page-active"
                breakClassName="page-break"
                pageClassName="page"
                pageLinkClassName="page-link"
                activeLinkClassName="page-link-active"
                breakLinkClassName="page-break-link"
                renderOnZeroPageCount={() => <></>}
            />
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Pagination.propTypes = {
    className: PropTypes.string.isRequired,
    pagination: PropTypes.shape({
        current: PropTypes.number,
        pageSize: PropTypes.number,
        total: PropTypes.number,
    }).isRequired,
    setPagination: PropTypes.func.isRequired,
}

_Pagination.defaultProps = {}

// ----------------------------------------------------------------------------

const Pagination = styled(_Pagination)`
    & {
        display: flex;
        align-items: center;
        justify-content: center;
        .pagination-inner {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: row;
            align-items: center;

            .button {
                width: 32px;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 8px;
                border: 1px solid ${({ theme }) => theme.colors.grey100};
                &.rotate {
                    transform: rotate(180deg);
                }
                &.prev {
                    margin-right: ${({ theme }) => theme.spacing.s}px;
                }
            }

            .page {
                margin-right: ${({ theme }) => theme.spacing.s}px;
            }
            .page-link {
                color: ${({ theme }) => theme.colors.grey700};
            }

            .page-link-active {
                color: ${({ theme }) => theme.colors.primary};
                font-weight: 700;
            }

            .page-break {
                margin-right: ${({ theme }) => theme.spacing.s}px;
            }

            .page-break-link {
                color: ${({ theme }) => theme.colors.grey700};
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default Pagination
