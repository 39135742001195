export default function useRestResourceReducer(state, action) {
    switch (action.type) {
        case "HYDRATE":
            return { ...state, ...action.payload }
        case "RESET":
            return action.payload
        case "INITIAL_FETCH_DONE":
            return { ...state, isInitialFetch: false }
        case "WAIT_FOR_FULFILLED":
            return {
                ...state,
                waitForFulfilled: true,
                filters: { ...state.filters, ...action.payload },
            }
        case "SHOULD_REFETCH":
            return {
                ...state,
                shouldRefetch: true,
                filters: { ...state.filters, ...action.payload },
            }
        case "FETCH_START":
            return { ...state, isLoading: true, shouldRefetch: false }
        case "FETCH_DONE":
            return {
                ...state,
                resource: action.payload,
                isLoading: false,
                error: null,
            }
        case "FETCH_FAIL":
            return { ...state, error: action.payload, isLoading: false }
        case "FILTERS_CHANGE":
            return {
                ...state,
                filters: { ...state.filters, ...action.payload },
            }
    }
}

export const useRestResourceActions = {
    setCachedResource: (resource) => ({
        type: "HYDRATE",
        payload: {
            isIinitalFetch: true,
            isLoading: false,
            resource: resource && resource,
            initialHydrationStatus: resource ? "success" : "fail",
        },
    }),
    resetHookState: (initialState) => ({
        type: "RESET",
        payload: initialState,
    }),
    initialFetchDone: () => ({ type: "INITIAL_FETCH_DONE" }),
    watiForFulfilled: (updatedFilters) => ({
        type: "WAIT_FOR_FULFILLED",
        payload: updatedFilters,
    }),
    shouldRefetch: (filters) => ({ type: "SHOULD_REFETCH", payload: filters }),
    fetchStart: () => ({ type: "FETCH_START" }),
    fetchDone: (res) => ({ type: "FETCH_DONE", payload: res }),
    fetchFail: (error) => ({ type: "FETCH_FAIL", payload: error }),
    changeFilters: (filters) => ({ type: "FILTERS_CHANGE", payload: filters }),
}
