export default function getColumns(renderActions) {
    return [
        {
            title: "Id",
            key: "id",
            dataIndex: "id",
            sorter: {
                compare: (a, b) => a.id - b.id,
                multiple: 1,
            },
        },
        {
            title: "Label",
            key: "label",
            dataIndex: "label",
        },
        {
            title: "Sensore",
            key: "sensor",
            dataIndex: "sensor",
        },
        {
            title: "State",
            key: "state",
            dataIndex: "state",
            //  sorter: (a, b) => a?.val?.localeCompare(b?.val),
        },
        {},
        {
            key: "actions",
            render: renderActions,
        },
    ]
}
