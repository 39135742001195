import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import BottomSheet from "../../../../_shared/components/BottomSheet"
import AppTable from "../../../../_shared/components/Table"
import { useStateValue } from "../../../../_shared/context/AppStateStore"
import useRestResource from "../../../../_shared/hooks/useRestResource"

// ----------------------------------------------------------------------------

function _UserMachinesBottomSheet(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, user, onClose } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [{ token }] = useStateValue()
    const [machines] = useRestResource("/get_usermachines", token)

    const userMachines =
        machines?.filter((machine) => machine.username === user?.name) ?? []

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <BottomSheet
            open={!!user}
            // onConfirm={onClose}
            onCancel={onClose}
            className={`${className}`}
            cancelButtonLabel="Chiudi"
            title="Macchine utente"
            rowKey={(r) => r.machineSerial}
            snapPoint={60}
        >
            <AppTable
                pagination={{ disabled: true }}
                columns={getColumns()}
                dataSource={userMachines}
                hideHeader
                hidePagination
                disableSelection
            />
        </BottomSheet>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_UserMachinesBottomSheet.propTypes = {
    className: PropTypes.string.isRequired,
}

_UserMachinesBottomSheet.defaultProps = {}

// ----------------------------------------------------------------------------

const UserMachinesBottomSheet = styled(_UserMachinesBottomSheet)`
    & {
    }
`
// ----------------------------------------------------------------------------

export default UserMachinesBottomSheet

function getColumns() {
    return [
        {
            title: "Brand",
            dataIndex: "machinebrand",
            key: "machineBrand",
        },
        {
            title: "Serial",
            dataIndex: "machineserial",
            key: "machineSerial",
        },
    ]
}
