import React from "react"

import Content from "./Content"
import Footer from "./Footer"
import Header from "./Header"
import Wrapper from "./Wrapper"

// ----------------------------------------------------------------------------

function Step(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { children } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return <>{children}</>
}

Step.Wrapper = Wrapper
Step.Header = Header
Step.Footer = Footer
Step.Content = Content

export default Step
