import { message } from "antd"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import styled from "styled-components"

import * as api from "../../../../../api"
import AppButton from "../../../../_shared/components/AppButton"
import BottomSheet from "../../../../_shared/components/BottomSheet"
import AppSelect from "../../../../_shared/components/FormItems/AppSelect"
import { useStateValue } from "../../../../_shared/context/AppStateStore"
import useRestResource from "../../../../_shared/hooks/useRestResource"

// ----------------------------------------------------------------------------

function _MachineSensorsBottomSheet(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, onCancel, onConfirm, open, machine } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [{ token }] = useStateValue()

    const [machines] = useRestResource("get_machines", token)

    useEffect(() => {
        machine && getSensors()
    }, [machine, open])

    const [sens, setSens] = useState(null)
    const [reassignSensor, setReassignSensor] = useState(null)
    const [newMachine, setNewMachine] = useState(null)

    async function getSensors() {
        const payload = {
            machineserial: machine.serial,
        }
        api.createResource("/get_sensor", token, payload)
            .then((response) => {
                setSens(response)
            })
            .catch((e) => {
                // eslint-disable-next-line no-console
                console.log(e)
                message.error("Qualcosa è andato storto")
            })
    }

    function getMachinesOptions() {
        return (
            machines?.map?.((machine) => ({
                key: machine?.serial,
                value: machine?.serial,
                label: `${machine?.tag} (${machine?.brand})`,
            })) ?? []
        )
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <BottomSheet
            title="Sensori associati"
            className={`${className}`}
            onCancel={onCancel}
            open={open}
        >
            {sens?.map((x) => (
                <div className="sensorLine">
                    {`ID:${x?.id}\t`}
                    {x === reassignSensor ? (
                        <div>
                            {" "}
                            <AppSelect
                                className="field"
                                value={newMachine?.machineserial}
                                onChange={(v) => setNewMachine(v)}
                                options={getMachinesOptions()}
                                label="Nuova macchina"
                            />
                            <AppButton onClick={() => onConfirm(x, newMachine)}>
                                Conferma
                            </AppButton>
                        </div>
                    ) : (
                        <AppButton
                            role="secondary"
                            onClick={() => setReassignSensor(x)}
                        >
                            Associa ad un'altra macchina
                        </AppButton>
                    )}
                </div>
            ))}
        </BottomSheet>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_MachineSensorsBottomSheet.propTypes = {
    className: PropTypes.string.isRequired,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
}

_MachineSensorsBottomSheet.defaultProps = {}

// ----------------------------------------------------------------------------

const MachineSensorBottomSheet = styled(_MachineSensorsBottomSheet)`
    & {
        .field {
            width: 200px;
            margin-right: 10px;
        }
        .sensorLine {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            display: flex;
            margin-bottom: 20px;
            width: 100%;
        }
    }
`
// ----------------------------------------------------------------------------

export default MachineSensorBottomSheet
