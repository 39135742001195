import { DatePicker, Input, message } from "antd"
import moment from "moment"
import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import styled from "styled-components"

import * as api from "../../../../api"
import PageTitle from "../../../_shared/components/PageTitle"
import AppTable from "../../../_shared/components/Table"
import TableActions from "../../../_shared/components/TableActions"
import { useStateValue } from "../../../_shared/context/AppStateStore"
import useAsyncMemo from "../../../_shared/hooks/useAsyncMemo"
import { useDelayedState } from "../../../_shared/hooks/useDelayedState"
import BatchBottomSheet from "./BatchBottomSheet"

// ----------------------------------------------------------------------------

function _Batches(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [sort, setSort] = useState("new")

    const [selectedItem, setSelectedItem] = useState()
    const [{ token }] = useStateValue()
    const [dateFilters, finalDateFilters, setDateFilters] = useDelayedState({
        date: [moment().subtract(1, "week"), moment()],
    })
    const [localFilters, setLocalFilters] = useState({
        axis: "",
        sensor: "",
    })
    const [batches, setBatches] = useState([])
    const [columns, setColumns] = useState([])
    const [loading, setLoading] = useState(true)
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    })

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    const [sensors, sensorsLoading] = useAsyncMemo(
        async () => {
            let r = []
            const machines = await api.getResource("/get_usermachines", token)
            for (const machine of machines) {
                const s = await api.createResource("/get_sensor", token, {
                    machineserial: machine.machineserial,
                })
                r = r.concat(s)
            }
            r = r.filter((item, index, self) => {
                return index === self.findIndex((t) => t.id === item.id)
            })
            return r
        },
        [token],
        true,
    )

    const filteredBatches = useMemo(() => {
        return batches
            .filter((batch) => {
                if (localFilters.sensor) {
                    return new RegExp(localFilters.sensor, "i").test(
                        batch.sensor,
                    )
                } else {
                    return true
                }
            })
            .filter((batch) => {
                if (localFilters.axis) {
                    return new RegExp(localFilters.axis, "i").test(batch.axis)
                } else {
                    return true
                }
            })
    }, [batches, localFilters])

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        fetchResource()
        //eslint-disable-next-line
    }, [finalDateFilters, sensors])

    function getDate(d, isStart = false) {
        if (!d) {
            return null
        }
        if (isStart) {
            return moment(d)
                .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                .toISOString()
        } else {
            return moment(d)
                .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
                .toISOString()
        }
    }

    // -------------------------------------
    // Component functions
    // -------------------------------------

    async function fetchResource() {
        if (!sensors) return
        const rows = []
        let dataColumns = new Set()
        setLoading(true)
        try {
            for (const s of sensors) {
                const payload = {
                    cmd: "countBatch",
                    data: JSON.stringify({
                        start: getDate(dateFilters.date?.[0], true),
                        end: getDate(dateFilters.date?.[1], false),
                        sensor_id: s.id,
                    }),
                }
                let response = await api.createResource(
                    "/ensys_app",
                    token,
                    payload,
                )
                try {
                    response = JSON.parse(response)
                    Object.entries(response.data).forEach(([axis, values]) => {
                        dataColumns = new Set([
                            ...dataColumns,
                            ...Object.keys(values),
                        ])
                        rows.push({
                            sensor: s.id,
                            axis,
                            ...values,
                        })
                    })
                } catch (err) {
                    // eslint-disable-next-line
                    console.error("[Error] in fetchResource: ", err)
                }
            }
            setBatches(rows)
            setColumns([...dataColumns])
        } catch (error) {
            // eslint-disable-next-line
            console.error("[Error] in fetchResource: ", error)
        } finally {
            setLoading(false)
        }
    }

    async function handleCreateBatch(values) {
        try {
            await api.createResource("/new_batch", token, values)

            setSelectedItem(null)

            message.success("Macchina creata con successo")
            fetchResource()
        } catch (error) {
            message.error(
                "Impossibile creare la macchina. Verificare i dati o riprovare più tardi",
            )
            // eslint-disable-next-line
            console.error("[Error] in handleCreateMachine: ", error)
        }
    }

    function renderActions(row) {
        return <TableActions />
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            <PageTitle>Conteggio batch</PageTitle>
            <div className="filters">
                <DatePicker.RangePicker
                    className="filter"
                    value={dateFilters.date}
                    onChange={(date) =>
                        setDateFilters({ ...dateFilters, date })
                    }
                    placeholder="Data"
                />
            </div>
            <AppTable
                dataSource={filteredBatches}
                columns={getTableColumns(
                    columns,
                    renderActions,
                    localFilters,
                    setLocalFilters,
                )}
                pagination={{
                    ...pagination,
                    total: filteredBatches?.length || 0,
                }}
                setPagination={(pagination) => setPagination(pagination)}
                disableSelection
                loading={sensorsLoading || loading}
                onDelete={() => {}}
                sort={sort}
                onSortChange={setSort}
                rowKey={(row) => row.serial}
                locale={{
                    triggerDesc: "Ordina in ordine decrescente",
                    triggerAsc: "Ordina in ordine crescente",
                    cancelSort: "Annulla ordinamento",
                }}
            />
            <BatchBottomSheet
                open={!!selectedItem}
                defaultValues={selectedItem}
                onCancel={() => setSelectedItem(null)}
                onConfirm={handleCreateBatch}
            />
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Batches.propTypes = {
    className: PropTypes.string.isRequired,
}

_Batches.defaultProps = {}

// ----------------------------------------------------------------------------

const Batches = styled(_Batches)`
    & {
        display: flex;
        flex-direction: column;
        .create-btn {
            width: 200px;
            align-self: flex-end;
            margin-bottom: 20px;
        }
    }
`
// ----------------------------------------------------------------------------

export default Batches

function getTableColumns(dataColumns = [], renderActions, filters, setFilters) {
    /** @type {import("antd").TableColumnsType } */

    const cols = [
        {
            key: "sensor",
            title: "Sensore",
            dataIndex: "sensor",
            filterDropdown: () => (
                <div>
                    <Input
                        placeholder="Cerca per sensore"
                        value={filters.sensor}
                        onChange={(e) =>
                            setFilters({ ...filters, sensor: e.target.value })
                        }
                    />
                </div>
            ),
        },
        {
            key: "axis",
            title: "Asse",
            dataIndex: "axis",
            filterDropdown: () => (
                <div>
                    <Input
                        placeholder="Cerca per asse"
                        value={filters.axis}
                        onChange={(e) =>
                            setFilters({ ...filters, axis: e.target.value })
                        }
                    />
                </div>
            ),
        },
        ...dataColumns.map((column) => ({
            key: column,
            title: column,
            dataIndex: column,
        })),
        {
            key: "actions",
            render: renderActions,
        },
    ]
    return cols
}
