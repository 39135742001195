import * as allCurves from "@visx/curve"
import { Group } from "@visx/group"
import {
    MarkerArrow,
    MarkerCross,
    MarkerX,
    MarkerCircle,
    MarkerLine,
} from "@visx/marker"
import generateDateValue, {
    DateValue,
} from "@visx/mock-data/lib/generators/genDateValue"
import { scaleTime, scaleLinear } from "@visx/scale"
import { LinePath } from "@visx/shape"
import { extent, max } from "d3-array"
import React, { useState } from "react"

const curveTypes = Object.keys(allCurves)
const lineCount = 1
const series = [
    [
        { date: new Date().setHours(0), value: 110 },
        { date: new Date().setHours(1), value: 200 },
        { date: new Date().setHours(2), value: 240 },
        { date: new Date().setHours(3), value: 250 },
        { date: new Date().setHours(4), value: 200 },
    ],
]

// const series2 = new Array(lineCount).fill(null).map((_, i) =>
//     // vary each series value deterministically
//     generateDateValue(5, /* seed= */ i / 72).sort(
//         (a, b) => a.date.getTime() - b.date.getTime()
//     )
// )

const allData = series.reduce((rec, d) => rec.concat(d), [])

// data accessors
const getX = (d) => d.date
const getY = (d) => d.value

// scales
const xScale = scaleTime({
    domain: extent(allData, getX),
})
const yScale = scaleLinear({
    domain: [0, max(allData, getY)],
})

export default function Example({ width = 1000, height = 600 }) {
    const [curveType, setCurveType] = useState("curveNatural")
    const svgHeight = height
    const lineHeight = svgHeight / lineCount

    // update scale output ranges
    xScale.range([0, width - 50])
    yScale.range([lineHeight - 2, 0])

    return (
        <div className="visx-curves-demo">
            <>
                <label>
                    Curve type &nbsp;
                    <select
                        onChange={(e) => setCurveType(e.target.value)}
                        value={curveType}
                    >
                        {curveTypes.map((curve) => (
                            <option key={curve} value={curve}>
                                {curve}
                            </option>
                        ))}
                    </select>
                </label>

                <br />
            </>

            <svg width={width} height={svgHeight}>
                <MarkerX
                    id="marker-x"
                    stroke="#333"
                    size={22}
                    strokeWidth={4}
                    markerUnits="userSpaceOnUse"
                />
                <MarkerCross
                    id="marker-cross"
                    stroke="#333"
                    size={22}
                    strokeWidth={4}
                    strokeOpacity={0.6}
                    markerUnits="userSpaceOnUse"
                />
                <MarkerCircle
                    id="marker-circle"
                    fill="#333"
                    size={2}
                    refX={2}
                />
                <MarkerArrow
                    id="marker-arrow-odd"
                    stroke="#333"
                    size={8}
                    strokeWidth={1}
                />
                <MarkerLine
                    id="marker-line"
                    fill="#333"
                    size={16}
                    strokeWidth={1}
                />
                <MarkerArrow id="marker-arrow" fill="#333" refX={2} size={6} />
                <rect
                    width={width}
                    height={svgHeight}
                    fill="#afafaf"
                    rx={14}
                    ry={14}
                />
                {width > 8 &&
                    series.map((lineData, i) => {
                        let markerStart = "url(#marker-x)"
                        if (i === 1) markerStart = "url(#marker-line)"
                        const markerEnd = "url(#marker-arrow)"

                        return (
                            <Group key={`lines-${i}`} top={200} left={20}>
                                <LinePath
                                    curve={allCurves[curveType]}
                                    data={lineData}
                                    x={(d) => xScale(getX(d)) ?? 0}
                                    y={(d) => yScale(getY(d)) ?? 0}
                                    stroke="#333"
                                    strokeWidth={2}
                                    strokeOpacity={1}
                                    shapeRendering="geometricPrecision"
                                    markerMid="url(#marker-circle)"
                                    markerStart={markerStart}
                                    markerEnd={markerEnd}
                                />
                            </Group>
                        )
                    })}
            </svg>
            <style jsx>{`
                .visx-curves-demo label {
                    font-size: 20px;
                }
            `}</style>
        </div>
    )
}
