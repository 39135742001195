import React, { createContext, useContext, useEffect, useReducer } from "react"

// ----------------------------------------------------------------------------

export const StateContext = createContext()

// ----------------------------------------------------------------------------

export const AppStateStore = ({
    reducer,
    initialState,
    storeKey,
    children,
}) => {
    const reducerValue = useReducer(
        reducer,
        JSON.parse(localStorage.getItem(`app-state-store.${storeKey}`)) ||
            initialState,
    )

    useEffect(() => {
        const [newState] = reducerValue

        localStorage.setItem(
            `app-state-store.${storeKey}`,
            JSON.stringify(newState),
        )

        return function cleanup() {
            localStorage.setItem(
                `app-state-store.${storeKey}`,
                JSON.stringify(newState),
            )
        }

        // eslint-disable-next-line no-unused-vars
        function cleanStore() {
            localStorage.removeItem(`app-state-store.${storeKey}`)
        }

        // eslint-disable-next-line
    }, [reducerValue])

    return (
        <StateContext.Provider value={reducerValue}>
            {children}
        </StateContext.Provider>
    )
}

// ----------------------------------------------------------------------------
// export read context hook
// ----------------------------------------------------------------------------

export const useStateValue = () => useContext(StateContext)

// ----------------------------------------------------------------------------
