import L from "leaflet"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet"
import styled from "styled-components"

import Chart from "../../Associations/Chart"
import { MapContext } from "./MapContext"
import PopupContent from "./Popup"
import { getClusterStatus, separateMarkers, statusColors } from "./helpers"

// ----------------------------------------------------------------------------

const markerIcon = {
    ok: (className, d = 40) =>
        L.icon({
            iconUrl: "./marker.svg",
            iconSize: new L.Point(d, d),
            className,
        }),
    err: (className, d = 40) =>
        L.icon({
            iconUrl: "./marker-err.svg",
            iconSize: new L.Point(d, d),
            className,
        }),
    cluster: ({ status = "ok", d = 40, numItems = 2 } = {}) =>
        L.divIcon({
            className: "cluster-wrapper",
            iconSize: new L.Point(d, d),
            html: `<div class="cluster ${status}">${numItems}</div>`,
        }),
}

function _Map(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, sensors } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [chartTaskId, setChartTaskId] = useState(null)

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function renderMarkers() {
        if (!sensors) {
            return
        }
        return sensors
            .filter((sensor) => !!sensor.geolocation)
            .map((sensor) => {
                return {
                    ...sensor,
                    latLng: sensor.geolocation
                        .split(",")
                        .map((x) => parseFloat(x)),
                }
            })
            .filter((sensor) => sensor.latLng.length === 2)
            .reduce(separateMarkers, [])
            .map((sensorGroup) => {
                // marker cluster
                const { latLng, id } = sensorGroup[0]
                const status = getClusterStatus(sensorGroup)
                return (
                    <Marker
                        key={id}
                        position={latLng}
                        icon={markerIcon.cluster({
                            numItems: sensorGroup.length,
                            status,
                        })}
                    >
                        <Popup minWidth={400} maxHeight={400}>
                            <PopupContent sensorGroup={sensorGroup} />
                        </Popup>
                    </Marker>
                )
            })
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <MapContext.Provider
            value={{
                openTaskChart: (id) => setChartTaskId(id),
            }}
        >
            <div className={`${className}`}>
                <MapContainer
                    className="map-inner"
                    center={[45.579587, 10.948955]}
                    zoom={8}
                    scrollWheelZoom
                >
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {renderMarkers()}
                </MapContainer>
                <Chart
                    taskId={chartTaskId}
                    onClose={() => setChartTaskId(null)}
                />
            </div>
        </MapContext.Provider>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Map.propTypes = {
    className: PropTypes.string.isRequired,
}

_Map.defaultProps = {}

// ----------------------------------------------------------------------------

const Map = styled(_Map)`
    & {
        height: 100%;
        .map-inner {
            height: 100%;

            .move {
                margin-left: 10px !important;
            }

            .cluster {
                border-radius: 50%;
                color: #fff;
                font-size: 12px;
                font-weight: bold;
                height: 40px;
                width: 40px;

                display: flex;
                justify-content: center;
                align-items: center;

                &.ok {
                    background-color: ${statusColors.ok};
                }

                &.err {
                    background-color: ${statusColors.error};
                }

                &.mixed {
                    background-color: ${statusColors.mixed};
                }
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default Map
