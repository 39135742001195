import { Button } from "antd"
import * as d3 from "d3"
import domToImage from "dom-to-image"
import PropTypes from "prop-types"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { FaSave } from "react-icons/fa"
import styled from "styled-components"

import { useMeasures } from "../../../../../_shared/hooks/useMeasures"
import ColorScale from "./ColorScale"
import {
    getDataImage,
    getDimensions,
    renderXTicks,
    renderyTicks,
} from "./helpers"

// ----------------------------------------------------------------------------

const MIN_COLOR = "#0121a4"
const MAX_COLOR = "#f7ff00"

function _Drift(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, data, index, analysis, cacheId } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [bind, { width: containerWidth, ready }] = useMeasures()

    const { height, width } = getDimensions(containerWidth, data)
    const ref = useRef(null)
    const [chartBase64, setChartBase64] = useState(null)

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    const [min, max] = useMemo(() => {
        let _min = null
        let _max = null

        data.forEach((x) => {
            x.forEach((y) => {
                if (_min === null || y < _min) {
                    _min = y
                }
                if (_max === null || y > _max) {
                    _max = y
                }
            })
        })

        return [_min, _max]
    }, [data])

    const colorScale = d3
        .scaleSequential()
        .interpolator(d3.interpolateRgbBasis([MIN_COLOR, MAX_COLOR]))
        .domain([min, max])

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        if (!ready || !data || !cacheId) {
            return
        }

        const cached = localStorage.getItem(cacheId)

        if (cached) {
            setChartBase64(cached)
        } else {
            const base64 = getDataImage(data, colorScale)
            setChartBase64(base64)
            try {
                localStorage.setItem(cacheId, base64)
            } catch {
                localStorage.removeItem(cacheId)
            }
        }

        //eslint-disable-next-line
    }, [data, cacheId, ready])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function handleExport() {
        const node = ref.current
        domToImage.toPng(node).then((dataUrl) => {
            const a = document.createElement("a")
            a.download = "drift.png"
            a.href = dataUrl
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
        })
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            <div className="chart-container" ref={ref}>
                <div
                    className="chart-group"
                    // Chart dimensions are computed based on max width of this container
                    // but after they are available, width should be restricted
                    style={{ width: width ? width : "100%" }}
                >
                    <div className="xticks">
                        {renderXTicks(index, 5, width)}
                    </div>
                    <div className="yticks">
                        {renderyTicks(
                            analysis?.scalaY?.min,
                            analysis?.scalaY?.max,
                            5,
                            height,
                        )}
                    </div>
                    <div className="chart-inner" {...bind()}>
                        {ready && (
                            <img
                                width={width}
                                height={height}
                                src={chartBase64}
                            />
                        )}
                    </div>
                </div>
                <ColorScale
                    min={min}
                    max={max}
                    minColor={MIN_COLOR}
                    maxColor={MAX_COLOR}
                />
            </div>
            <Button className="save-btn" onClick={handleExport}>
                <FaSave />
            </Button>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Drift.propTypes = {
    className: PropTypes.string.isRequired,
}

_Drift.defaultProps = {}

// ----------------------------------------------------------------------------

const Drift = styled(_Drift)`
    & {
        width: 100%;
        display: flex;
        flex-direction: column;

        .chart-container {
            display: flex;
            justify-content: center;
            gap: 30px;
            padding-top: 20px;
            padding-bottom: 150px;
            padding-left: 100px;
            padding-right: 20px;
            width: 100%;
            background-color: white;

            .chart-group {
                position: relative;
                height: fit-content;
                .chart-inner {
                    width: 100%;
                }

                .xticks {
                    position: absolute;
                    top: calc(100% + 10px);
                    left: 10px;
                    right: 10px;

                    & .xtick {
                        position: absolute;
                        transform-origin: center left;
                        transform: rotate(60deg);
                    }
                }
                .yticks {
                    position: absolute;
                    top: 0px;
                    bottom: 20px;
                    left: -10px;

                    .ytick {
                        position: absolute;
                        transform: translateX(-100%);
                    }
                }
            }
        }

        .save-btn {
            margin-top: 20px;
            margin-left: auto;
        }
    }
`
// ----------------------------------------------------------------------------

export default Drift
