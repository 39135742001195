import { useEffect, useState } from "react"

import { getResource } from "../../../api"
import { useStateValue } from "../context/AppStateStore"

export default function useLoginCheck() {
    const [checking, setChecking] = useState(true)
    const [{ token }, dispatch] = useStateValue()

    function checkToken() {
        return getResource("/get_users", token)
            .then(() => {
                return true
            })
            .catch(() => {
                return false
            })
    }

    useEffect(() => {
        if (!token) {
            setChecking(false)
        }

        checkToken().then((isValid) => {
            if (isValid) {
                setChecking(false)
            } else {
                dispatch({ type: "SIGN_OUT" })
                setChecking(false)
            }
        })

        //eslint-disable-next-line
    }, [token])

    return checking
}
