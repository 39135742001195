import PropTypes from "prop-types"
import React from "react"
import { Route, Switch } from "react-router-dom"
import styled from "styled-components"

import Layout from "../../_shared/components/Layout"
import Alarms from "./Alarms"
import Associations from "./Associations"
import Batches from "./Batches"
import MachineHistory from "./MachineHistory"
import Machines from "./Machines"
import Sensors from "./Sensors"
import SensorsMap from "./SensorsMap"
import Users from "./Users"

// ----------------------------------------------------------------------------

function _LoggedApp(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <Layout className={`${className}`}>
            <Switch>
                <Route exact path="/" component={Users} />
                <Route path="/machines" component={Machines} />
                <Route path="/sensors" component={Sensors} />
                <Route path="/sensors-map" component={SensorsMap} />
                <Route path="/batch-files" component={Batches} />
                <Route path="/tasks" component={Associations} />
                <Route path="/alarms" component={Alarms} />
                <Route path="/machine-history" component={MachineHistory} />
            </Switch>
        </Layout>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_LoggedApp.propTypes = {
    className: PropTypes.string.isRequired,
}

_LoggedApp.defaultProps = {}

// ----------------------------------------------------------------------------

const LoggedApp = styled(_LoggedApp)`
    & {
    }
`
// ----------------------------------------------------------------------------

export default LoggedApp
