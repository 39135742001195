import { Select } from "antd"
import PropTypes from "prop-types"
import React, { useMemo, useState } from "react"
import styled from "styled-components"

import FieldLabel from "../FieldLabel"
import ValidationMessage from "../ValidationMessage"

const { Option, OptGroup } = Select

// ----------------------------------------------------------------------------

function _AppSelect(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------
    const {
        label,
        value,
        onChange,
        placeholder,
        options,
        multiple,
        searchValue,
        onSearch,
        disabled,
        error,
        groupBy,
        allowClear,
        getPopupContainer,
        onBlur,
        onFocus,
        showArrow,
    } = props

    let message
    let invalid

    if (typeof error === "string") {
        message = error
        invalid = !!message
    } else {
        message = error?.message
        invalid = error?.invalid
    }

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    const finalOptions = useMemo(() => {
        if (groupBy) {
            const groups = []
            options?.forEach((o) => {
                const key = groupBy(o)
                if (!groups.includes(key)) groups.push(key)
            })
            return groups?.map((group) => {
                const items = options
                    ?.filter((opt) => groupBy(opt) === group)
                    ?.filter(
                        (item, index, array) =>
                            array.findIndex((i) => i.value === item.value) ===
                            index,
                    )
                return (
                    <OptGroup key={group} label={group}>
                        {items?.map((i, index) => {
                            // console.log(i)
                            return (
                                <Option
                                    key={`${group}-${i.value}-${index}`}
                                    value={i?.value}
                                >
                                    {i.label}
                                </Option>
                            )
                        })}
                    </OptGroup>
                )
            })
        } else {
            return renderOptions()
        }
        // eslint-disable-next-line
    }, [options])

    const [isFocused, setIsFocused] = useState(false)

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------
    function renderOptions() {
        return options?.map((option) => (
            <Option
                className="option"
                key={option?.value}
                value={option?.value}
            >
                {option?.label}
            </Option>
        ))
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------
    return (
        <div
            className={`${props.className} ${invalid ? "invalid" : ""} ${
                isFocused ? "focused" : ""
            }`}
        >
            {label && <FieldLabel className="">{label}</FieldLabel>}

            <Select
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                className="select"
                mode={multiple && "multiple"}
                searchValue={searchValue}
                onSearch={onSearch && onSearch}
                showSearch={!!(searchValue || onSearch || props.filterOption)}
                defaultActiveFirstOption={false}
                filterOption={props.filterOption ?? false}
                showArrow={showArrow === true}
                disabled={disabled}
                onFocus={(e) => {
                    onFocus && onFocus(e)
                    setIsFocused(true)
                }}
                onBlur={(e) => {
                    onBlur && onBlur(e)
                    setIsFocused(false)
                }}
                getPopupContainer={getPopupContainer ?? null}
                allowClear={allowClear}
            >
                {finalOptions}
            </Select>
            <ValidationMessage className="">{message}</ValidationMessage>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_AppSelect.propTypes = {
    className: PropTypes.string.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func,
    options: PropTypes.array,
    placeholder: PropTypes.string,
    multiple: PropTypes.bool,
    searchValue: PropTypes.string,
    onSearch: PropTypes.func,
    filterOption: PropTypes.any,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.any,
    height: PropTypes.number,
    borderRadius: PropTypes.number,
    preventBorder: PropTypes.bool,
    groupBy: PropTypes.func,
    allowClear: PropTypes.bool,
    getPopupContainer: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
}

_AppSelect.defaultProps = {}

// ----------------------------------------------------------------------------
const defaultHeight = 56
const defaultBorderRadius = 8
const defaultPaddingHorizontal = 23

const AppSelect = styled(_AppSelect)`
    & {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        /* min-width: 150px;
        max-width: 300px; */

        .label {
            display: block;
            font-size: 16px;
            line-height: 16px;
            color: ${({ theme }) => theme?.colors?.grey300};
            margin-bottom: 10px;
        }
        .select {
            min-height: ${({ height }) => height ?? defaultHeight}px;
            width: 100%;
            .ant-select-selection-item {
                /* background-color: ${({ theme }) => theme.colors.grey200}; */
                /* border: 1px solid ${({ theme }) => theme.colors.grey300}; */
                outline: none !important;
                box-shadow: none !important;
                color: ${({ theme }) => theme.colors.textDark};
                svg {
                    fill: ${({ theme }) => theme.colors.textDark};
                }
            }
            .ant-select-selector {
                height: ${({ height }) => height ?? defaultHeight}px;

                min-height: ${({ height }) => height ?? defaultHeight}px;
                padding: 0px
                    ${({ paddingHorizontal }) =>
                        paddingHorizontal ??
                        defaultPaddingHorizontal}px !important;
            }

            &.ant-select-open {
                .ant-select-selector {
                    /* background-color: ${({ theme }) =>
                        theme.colors.grey200}; */
                    border: 2px solid ${({ theme }) => theme.colors.inputBorder} !important;
                    outline: none !important;
                    box-shadow: none !important;
                    background: ${({ theme }) =>
                        theme?.colors?.menuBackgroundColor};
                }
            }

            .ant-select-selector {
                /* background-color: ${({ theme }) => theme.colors.grey200}; */
                border: 2px solid ${({ theme }) => theme.colors.inputBorder} !important;
                outline: none !important;
                box-shadow: none !important;
                border-radius: ${({ borderRadius }) =>
                    borderRadius ?? defaultBorderRadius}px !important;
                width: 100% !important;
                height: 100% !important;
                /* background: ${({ theme }) =>
                    theme?.colors?.menuBackgroundColor} !important; */
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding-left: 10px;
                span.ant-select-selection-search {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    padding: 0px 15px !important;
                    width: 100%;
                    left: ${({ paddingHorizontal }) =>
                        paddingHorizontal ?? defaultPaddingHorizontal};
                    right: ${({ paddingHorizontal }) =>
                        paddingHorizontal ?? defaultPaddingHorizontal};
                }
                span.ant-select-selection-placeholder {
                    /* color: ${({ theme }) =>
                        theme?.colors?.grey300} !important; */
                }
            }
            .ant-select-arrow {
                top: 45%;
                right: 24px;
                /* transform: translateY(-50%); */
                width: 20px;
                height: 20px;
                span svg {
                    fill: ${({ theme }) => theme?.colors?.textDark} !important;
                    color: ${({ theme }) => theme?.colors?.textDark} !important;
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default AppSelect
