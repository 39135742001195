import { Checkbox, Divider, Input, Tabs } from "antd"
import PropTypes from "prop-types"
import React, { useContext, useEffect } from "react"
import styled from "styled-components"

import { _WizardContext } from "../../../_context/wizardContext"

// ----------------------------------------------------------------------------

function _PerAxis(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const {
        className,
        parameterValues: parameters,
        parsedAlgorithm: algorithm,
        formState,
        setFormState,
    } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const { state } = useContext(_WizardContext)
    const { selectedVector } = state

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        initValues()
        //eslint-disable-next-line
    }, [parameters, algorithm, selectedVector])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function initValues() {
        const initialValues = []
        const p = parameters.perOgniAsse
        selectedVector.forEach((axis) => {
            const axisValues = {
                asse: axis,
                parametri: [],
            }
            p.forEach((parameter) => {
                const { par, data } = parameter
                axisValues.parametri.push({
                    par,
                    data,
                })
            })
            initialValues.push(axisValues)
        })
        setFormState(initialValues)
    }

    function handleChange(axisIndex, paramIndex, valueKey, changeEvent) {
        const value = changeEvent.target.value
        // a shallow copy causes updates to 1 axis to update all
        // for some reason
        // lodash's cloneDeep also causes the same issue
        const newValues = JSON.parse(JSON.stringify(formState))
        newValues[axisIndex].parametri[paramIndex].data[valueKey] = value
        setFormState(newValues)
    }

    function getValue(axisIndex, paramIndex, valueKey) {
        return formState[axisIndex].parametri[paramIndex].data[valueKey]
    }

    function renderInput(axisIndex, index, vk) {
        if (vk === "en") {
            return (
                <Checkbox
                    className="value-input"
                    checked={getValue(axisIndex, index, vk) === 1}
                    onChange={(e) => {
                        const checked = e.target.checked
                        const value = checked ? 1 : 0
                        handleChange(axisIndex, index, vk, {
                            target: { value },
                        })
                    }}
                >
                    {vk}
                </Checkbox>
            )
        }
        return (
            <>
                <span className="value-label">{vk}</span>
                <Input
                    className="value-input"
                    type="number"
                    value={getValue(axisIndex, index, vk)}
                    onChange={(e) => handleChange(axisIndex, index, vk, e)}
                />
            </>
        )
    }

    function renderTab(axis) {
        const axisIndex = formState.findIndex((v) => v.asse === axis)
        const axisValues = formState[axisIndex]

        if (!axisValues) return null

        return (
            <div className="axis-tab">
                {axisValues.parametri.map((param, index) => {
                    return (
                        <div className="par" key={`${axis}-${param.par}`}>
                            <span className="par-label">{param.par}</span>
                            {Object.keys(param.data).map((vk) => {
                                return (
                                    <div
                                        className="value"
                                        key={`${axis}-${param.par}-${vk}`}
                                    >
                                        {renderInput(axisIndex, index, vk)}
                                    </div>
                                )
                            })}
                            <Divider className="divider" />
                        </div>
                    )
                })}
            </div>
        )
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            <Tabs
                items={selectedVector.map((axis) => {
                    return {
                        key: axis,
                        label: axis,
                        children: renderTab(axis),
                    }
                })}
            />
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_PerAxis.propTypes = {
    className: PropTypes.string.isRequired,
}

_PerAxis.defaultProps = {}

// ----------------------------------------------------------------------------

const PerAxis = styled(_PerAxis)`
    & {
        .axis-tab {
            .par {
                .par-label {
                    display: block;
                    font-weight: bold;
                    margin-bottom: 10px;
                }
                .value {
                    margin-bottom: 10px;
                    .value-label {
                        display: block;
                    }
                    .value-input {
                        max-width: 400px;
                    }
                }

                &:last-child {
                    .divider {
                        display: none;
                    }
                }
            }
        }
        .save-btn {
            margin-top: 20px;
        }
    }
`
// ----------------------------------------------------------------------------

export default PerAxis
