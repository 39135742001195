import { Divider, Modal, Result, Spin, Tabs, message } from "antd"
import React, { useEffect, useState } from "react"
import styled from "styled-components"

import { useStateValue } from "../../../../_shared/context/AppStateStore"
import { exists } from "../../../../_shared/toolkit"
import Cartesian from "./Cartesian"
import Drift from "./Drift"
import { loadChartData } from "./helpers"
import useStatus from "./useStatus"

// ----------------------------------------------------------------------------

function Chart(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { taskId, onClose } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [{ token }] = useStateValue()
    const [ready, hasFailed] = useStatus(taskId, token)
    const [error, setError] = useState(false)

    const [loading, setLoading] = useState(false)

    /** @type {[import("./chartData").ChartData, Function]} */
    const [chartData, setChartData] = useState(null)

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        setError(false)
        //eslint-disable-next-line
    }, [taskId])

    useEffect(() => {
        if (exists(taskId) && ready) {
            setLoading(true)
            loadChartData(taskId, token)
                .then((data) => {
                    setChartData(data)
                })
                .catch((err) => {
                    // eslint-disable-next-line
                    console.error(err)
                    message.error("Errore durante il caricamento del grafico")
                    setError(true)
                })
                .finally(() => {
                    setLoading(false)
                })
        } else {
            setChartData(null)
        }
        //eslint-disable-next-line
    }, [taskId, ready])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function renderChart() {
        if (!exists(chartData)) {
            return null
        }

        if (chartData.tipologia === "cartesiano") {
            return (
                <Tabs>
                    {chartData.datiGrafico.data.map((d, i) => {
                        const analysis = chartData[`analisi_${i}`]
                        const index = chartData.datiGrafico.index
                        return (
                            <Tabs.TabPane
                                destroyInactiveTabPane
                                key={i}
                                tab={`Analisi ${i + 1}`}
                            >
                                <Cartesian
                                    data={d}
                                    index={index}
                                    analysis={analysis}
                                    cacheId={`c/${taskId}_${i}`}
                                />
                            </Tabs.TabPane>
                        )
                    })}
                </Tabs>
            )
        }

        if (chartData.tipologia === "drift") {
            return (
                <Tabs destroyInactiveTabPane>
                    {chartData.datiGrafico.data.map((charts, i) => {
                        const analysis = chartData[`analisi_${i}`]
                        const index = chartData.datiGrafico.index
                        return (
                            <Tabs.TabPane key={i} tab={`Analisi ${i + 1}`}>
                                {charts.map((d, di) => {
                                    const isLast = di === charts.length - 1
                                    return (
                                        <>
                                            <Drift
                                                index={index}
                                                analysis={analysis}
                                                data={d}
                                                key={index}
                                                cacheId={`d/${taskId}_${i}_${di}`}
                                            />
                                            {!isLast && (
                                                <Divider key={`div-${index}`} />
                                            )}
                                        </>
                                    )
                                })}
                            </Tabs.TabPane>
                        )
                    })}
                </Tabs>
            )
        }
    }

    function renderError() {
        return (
            <Result
                status="error"
                title="Errore durante il completamento del task"
            />
        )
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <Modal
            open={exists(taskId)}
            onOk={onClose}
            onCancel={onClose}
            cancelButtonProps={{ style: { display: "none" } }}
            title={chartData?.titolo || "Caricamento..."}
            width="70vw"
        >
            <Spin spinning={loading || (!ready && !hasFailed && !error)}>
                <Container>
                    {hasFailed || error ? renderError() : renderChart()}
                </Container>
            </Spin>
        </Modal>
    )
}

// ----------------------------------------------------------------------------

const Container = styled.div`
    & {
        min-height: 400px;
    }
`
// ----------------------------------------------------------------------------

export default Chart
