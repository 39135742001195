import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

// ----------------------------------------------------------------------------

function _ValidationMessage(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return <small className={`${className}`}>{props.children}</small>
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_ValidationMessage.propTypes = {
    className: PropTypes.string.isRequired,
    children: PropTypes.any,
}

_ValidationMessage.defaultProps = {}

// ----------------------------------------------------------------------------

const ValidationMessage = styled(_ValidationMessage)`
    & {
        display: block;
        color: ${({ theme }) => theme.colors.error};
        font-size: 11px;
        font-weight: 500;
        margin-top: 5px;
    }
`
// ----------------------------------------------------------------------------

export default ValidationMessage
