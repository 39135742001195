import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import { exists } from "../../../../toolkit"
import Button from "../../../AppButton"
import { useBottomSheet } from "../.."

// ----------------------------------------------------------------------------

function _Footer(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const {
        className,
        canConfirm,
        canDismiss,
        canGoBack,
        canGoNext,
        onPreview,
        canPreview,

        nextLabel = "Avanti",
        confirmLabel = "Conferma",
        dismissLabel = "Annulla",
        backLabel = "Indietro",

        onConfirm,
        onDismiss,
        onBack,
        onNext,
    } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const { back, confirm, current, dismiss, next, isLast } = useBottomSheet()

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function renderDismiss() {
        let doRender = false
        if (exists(canDismiss)) {
            doRender = canDismiss
        } else {
            doRender = current === 0
        }
        if (doRender) {
            return (
                <Button onClick={onDismiss || dismiss} role="delete">
                    {dismissLabel}
                </Button>
            )
        }
    }

    function renderBack() {
        let doRender = false
        if (exists(canGoBack)) {
            doRender = canGoBack
        } else {
            doRender = current > 0
        }
        if (doRender) {
            return (
                <Button onClick={onBack || back} role="delete">
                    {backLabel}
                </Button>
            )
        }
    }

    function renderNext() {
        let doRender = false
        if (exists(canGoNext)) {
            doRender = canGoNext
        } else {
            doRender = !isLast
        }
        if (doRender) {
            return (
                <Button onClick={onNext || next} role="confirm">
                    {nextLabel}
                </Button>
            )
        }
    }

    function renderConfirm() {
        let doRender = false
        if (exists(canConfirm)) {
            doRender = canConfirm
        } else {
            doRender = isLast
        }
        if (doRender) {
            return (
                <Button onClick={onConfirm || confirm} role="confirm">
                    {confirmLabel}
                </Button>
            )
        }
    }

    function renderPreview() {
        let doRender = false
        if (exists(canPreview)) {
            doRender = canPreview
        }
        if (doRender) {
            return (
                <Button onClick={onPreview} role="secondary">
                    Anteprima
                </Button>
            )
        }
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            {renderDismiss()}
            {renderBack()}
            {renderNext()}
            {renderPreview()}
            {renderConfirm()}
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Footer.propTypes = {
    className: PropTypes.string.isRequired,
}

_Footer.defaultProps = {}

// ----------------------------------------------------------------------------

const Footer = styled(_Footer)`
    & {
        margin-top: 20px;

        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 10px;
    }
`
// ----------------------------------------------------------------------------

export default Footer
