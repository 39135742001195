import { Button, Modal, message } from "antd"
import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"

import * as api from "../../../../../api"
import okIcon from "../../../../_shared/assets/icons/okIcon.png"
import SteppedBottomSheet from "../../../../_shared/components/SteppedBottomSheet"
import { useStateValue } from "../../../../_shared/context/AppStateStore"
import AlgorithmsStep from "./AlgorithmsStep"
import AxesStep from "./AxesStep"
import BaseStep from "./BaseStep"
import { _WizardContext } from "./_context/wizardContext"

const { Step } = SteppedBottomSheet

// ----------------------------------------------------------------------------

function _TaskBottomSheet(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, open, onCancel, machine, onPreview } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------
    const [{ token }] = useStateValue()
    const { state, dispatch } = useContext(_WizardContext)

    const { datas, selectedVector, sensor, fetchedAlgPar } = state

    const history = useHistory()

    const [openModal, setOpenModal] = useState(false)
    const [dataModal, setDataModal] = useState()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        machine && setMachine()
        machine && setSensor()
    }, [machine])

    function setMachine() {
        const payload = {
            userName: machine.owner,
            machineserial: machine.machineserial,
            machinebrand: machine.machinebrand,
        }
        dispatch({ type: "SET_MODEL", payload })
    }

    function setSensor() {
        dispatch({ type: "SET_SENSOR", payload: machine })
        dispatch({ type: "SET_TAG_ARRAY", payload: machine.tag })
    }

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function handleCancel() {
        onCancel()
        dispatch({ type: "RESET" })
    }

    async function setAlgToDo() {
        setLoading(true)
        try {
            const response = await api.createResource("/ensys_app", token, {
                cmd: "setAlgToDo",
                data: JSON.stringify({
                    customer_id: "Tommaso",
                    start: datas[0],
                    end: datas[1],
                    sensor_id: sensor.id,
                    assiScelti: selectedVector,
                    algoritmiScelti: fetchedAlgPar?.map((a) => ({
                        ...a,
                        title: undefined,
                        info: undefined,
                    })),
                    label: state.label,
                }),
            })

            const data = await JSON.parse(response)
            handleCancel()
            return data
        } catch (error) {
            // eslint-disable-next-line
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    async function handlePreview() {
        setLoading(true)
        try {
            const response = await api.createResource("/ensys_app", token, {
                cmd: "getPreview",
                data: JSON.stringify({
                    customer_id: "Tommaso",
                    start: datas[0],
                    end: datas[1],
                    sensor_id: sensor.id,
                    assiScelti: selectedVector,
                    algoritmiScelti: fetchedAlgPar?.map((a) => ({
                        ...a,
                        title: undefined,
                        info: undefined,
                    })),
                }),
            })
            const data = JSON.parse(response)
            const { id: taskId } = data
            onPreview(taskId)
        } catch (error) {
            // eslint-disable-next-line
            console.log(error)
            message.error("Errore nella preview del task")
        } finally {
            setLoading(false)
        }
    }

    function handleVisualization() {
        return setAlgToDo().then((data) => {
            setOpenModal(true)

            setDataModal({ ...data, label: state.label })
        })
    }

    const goToTask = () => {
        history.push("/tasks")
    }

    const handleOk = () => {
        setOpenModal(false)
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    const stepProps = {
        onPreview: handlePreview,
        loading,
        setLoading,
    }

    return (
        <>
            <SteppedBottomSheet
                className={`${className}`}
                open={open}
                onCancel={handleCancel}
                onConfirm={handleVisualization}
                snapPoint={80}
                loading={loading}
            >
                <Step>
                    <BaseStep {...stepProps} />
                </Step>
                <Step>
                    <AxesStep {...stepProps} />
                </Step>
                <Step>
                    <AlgorithmsStep {...stepProps} />
                </Step>
            </SteppedBottomSheet>
            <Modal
                visible={openModal}
                footer={[
                    <Button key="back" onClick={handleOk}>
                        Esci
                    </Button>,
                    <Button key="submit" type="primary" onClick={goToTask}>
                        Vai
                    </Button>,
                ]}
            >
                {/* <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                > */}
                <img
                    src={okIcon}
                    alt=""
                    style={{ height: "30px", width: "30px", margin: 5 }}
                />
                <p>
                    Operazione eseguita con successo, creato task :{" "}
                    <h2>{dataModal?.label || dataModal?.task_id}</h2>
                </p>
                <p>Vai alla pagina dei task per visualizzare</p>
                {/* </div> */}
            </Modal>
        </>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_TaskBottomSheet.propTypes = {
    className: PropTypes.string.isRequired,
}

_TaskBottomSheet.defaultProps = {}

// ----------------------------------------------------------------------------

const TaskBottomSheet = styled(_TaskBottomSheet)`
    & {
    }
`
// ----------------------------------------------------------------------------

export default TaskBottomSheet
