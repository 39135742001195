import { useEffect, useRef, useState } from "react"

/**
 * @returns {[Function, {width: number, height: number, ready: boolean}]}
 */
export function useMeasures() {
    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)
    const [ready, setReady] = useState(false)
    const ref = useRef(null)

    function bind() {
        return {
            ref,
        }
    }

    function getSize(elem) {
        if (!elem) {
            return
        }
        const rect = elem.getBoundingClientRect()
        setReady(true)
        setWidth(rect.width)
        setHeight(rect.height)
    }

    useEffect(() => {
        if (!ref.current) {
            return
        }

        getSize(ref.current)
        window.addEventListener("resize", () => getSize(ref.current))

        return () => {
            window.removeEventListener("resize", () => getSize(ref.current))
        }

        //eslint-disable-next-line
    }, [])

    return [
        bind,
        {
            width,
            height,
            ready,
        },
    ]
}
