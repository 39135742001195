import { Button } from "antd"
import PropTypes from "prop-types"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { Chart } from "react-chartjs-2"
import { FaSave } from "react-icons/fa"
import styled from "styled-components"

// ----------------------------------------------------------------------------

function _Cartesian(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    /**
     * @type {{chartData: import("../chartData").Cartesian}}
     */
    const { className, data, index, analysis, cacheId, minHeight } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const ref = useRef(null)

    const [mode, setMode] = useState("pending")
    const [cache, setCache] = useState(null)

    const chartData = useMemo(() => {
        /**
         * @type {import("react-chartjs-2").ChartProps["data"]>}
         */
        const computedData = {
            datasets: [...getDatasets(), ...getParameters()],
            labels: index,
        }
        return computedData
    }, [data, index, analysis])

    const chartOptions = useMemo(() => {
        /**
         * @type {import("react-chartjs-2").ChartProps["options"]>}
         */
        const computedOptions = {
            maintainAspectRatio: false,
            animation: false,

            scales: {
                x: {
                    ticks: {
                        autoSkip: true,
                        maxTicksLimit: 15,
                        minRotation: 60,
                    },
                },
            },
            plugins: {
                tooltip: {
                    enabled: false,
                },
                customCanvasBackgroundColor: {
                    color: "white",
                },
                canvasCachePlugin: {
                    cacheId,
                },
            },
            hover: {
                mode: null,
            },
        }
        return computedOptions
    }, [data, index, analysis, cacheId])

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        if (cacheId) {
            const data = localStorage.getItem(cacheId)
            if (!data) {
                setMode("full")
            } else {
                setCache(data)
                setMode(cache)
            }
        } else {
            setMode("full")
        }
        //eslint-disable-next-line
    }, [cacheId])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function getDatasets() {
        /**
         * @type {import("react-chartjs-2").ChartProps["data"]["datasets"]}
         */
        const datasets = data.map((functionData, index) => {
            return {
                label: analysis.legenda[index],
                data: functionData.map((y) => y),
                type: "line",
                pointRadius: 0,
                pointHitRadius: 0,
                order: 1,
            }
        })
        return datasets
    }

    function getParameters() {
        /**
         * @type {import("react-chartjs-2").ChartProps["data"]["datasets"]}
         */
        const datasets = (analysis.parametri ?? []).map((parameter) => {
            return {
                label: parameter.par,
                type: "line",
                data: new Array(index.length)
                    .fill(parameter.data.val)
                    .map((y, x) => ({ x, y })),
                borderWidth: 1,
                order: 0,
                pointRadius: 1,
                pointHitRadius: 0,
            }
        })
        return datasets
    }

    function handleExport() {
        const handler = ref.current
        const base64 = handler.toBase64Image()
        const a = document.createElement("a")
        a.href = base64
        a.download = "chart.png"
        a.click()
        a.remove()
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            <div className="chart-container" style={{ minHeight }}>
                {mode === "full" && (
                    <Chart
                        className="chart"
                        type="line"
                        data={chartData}
                        options={chartOptions}
                        ref={ref}
                    />
                )}
                {cache && <img className="chart-img" src={cache} />}
            </div>
            <Button className="save-btn" onClick={handleExport}>
                <FaSave />
            </Button>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Cartesian.propTypes = {
    className: PropTypes.string.isRequired,
}

_Cartesian.defaultProps = {}

// ----------------------------------------------------------------------------

const Cartesian = styled(_Cartesian)`
    & {
        width: 100%;
        display: flex;
        flex-direction: column;

        .chart-container {
            width: 100%;
            min-height: 50vh;
            .chart {
                width: 100%;
                height: 100%;
            }

            .chart-img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        .save-btn {
            margin-top: 12px;
            margin-left: auto;
        }
    }
`
// ----------------------------------------------------------------------------

export default Cartesian
