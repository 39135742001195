import { Input } from "antd"
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import styled from "styled-components"

// ----------------------------------------------------------------------------

function _Simple(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, parametersValues, formState, setFormState } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    // context state destructuring

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        const initialValues = []
        Object.keys(parametersValues).forEach((keyName, i) =>
            initialValues.push({ [keyName]: parametersValues[keyName] }),
        )
        if (initialValues) {
            setFormState(initialValues)
        }
        //eslint-disable-next-line
    }, [parametersValues])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function handleChange(val, keyName, index) {
        const params = [...formState]
        keyName[Object.keys(params[index])] = val
        setFormState(params)
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            <div>
                {formState?.map((keyName, i) => (
                    <div style={{ width: 200, margin: 10 }}>
                        {Object.keys(keyName)} :
                        <Input
                            value={keyName[Object.keys(keyName)]}
                            onChange={(val) =>
                                handleChange(val.target.value, keyName, i)
                            }
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Simple.propTypes = {
    className: PropTypes.string.isRequired,
}

_Simple.defaultProps = {}

// ----------------------------------------------------------------------------

const Simple = styled(_Simple)`
    & {
    }
`
// ----------------------------------------------------------------------------

export default Simple
