import { message } from "antd"
import dayjs from "dayjs"
import PropTypes from "prop-types"
import React, { useState } from "react"
import styled from "styled-components"

import { createResource } from "../../../../api"
import AppButton from "../../../_shared/components/AppButton"
import PageTitle from "../../../_shared/components/PageTitle"
import AppTable from "../../../_shared/components/Table"
import { useStateValue } from "../../../_shared/context/AppStateStore"
import useAsyncMemo from "../../../_shared/hooks/useAsyncMemo"
import useRestResource from "../../../_shared/hooks/useRestResource"
import FaultsAndSolutionsModal from "./FaultsAndSolutionsModal"

// ----------------------------------------------------------------------------

function _Alarms(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [{ token }] = useStateValue()
    const [machines, machinesLoading] = useRestResource(
        "/get_usermachines",
        token,
    )
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    })

    const [addFaultModalOpen, setAddFaultModalOpen] = useState(false)

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    const [sensors, sensorsLoading] = useAsyncMemo(
        async () => {
            if (!machines || !machines.length) {
                return
            }

            const promises = []
            for (const m of machines) {
                promises.push(
                    createResource(`get_sensor`, token, {
                        machineserial: m.machineserial,
                    }),
                )
            }
            let s = []
            const res = await Promise.all(promises)
            for (const r of res) {
                if (r && r.length) {
                    s = s.concat(r)
                }
            }

            s = s.filter((i, index, self) => {
                return self.findIndex((t) => t.id === i.id) === index
            })

            return s
        },
        [machines],
        true,
    )

    const [alarms, alarmsLoading, reloadAlarms] = useAsyncMemo(
        async () => {
            if (!sensors || !sensors.length) {
                return
            }

            const promises = []
            for (const s of sensors) {
                promises.push(
                    createResource(`ensys_app`, token, {
                        cmd: "getEvents",
                        data: JSON.stringify({
                            sensor: s.id,
                            predicted_events: 1,
                            real_events: 0,
                        }),
                    }).catch(() => {}),
                )
            }
            const res = await Promise.all(promises)
            let a = []
            for (let r of res) {
                try {
                    r = JSON.parse(r)
                    const { predicted_events: events = [] } = r.data
                    a = a.concat(events)
                } catch {}
            }
            return a
        },
        [sensors],
        true,
    )

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function handleAddFaults(formValues) {
        const { righeSel, guasto, soluzione } = formValues
        const commandPayload = JSON.stringify({
            guasto,
            soluzione,
            righeSel: righeSel.map(JSON.parse),
        })
        return createResource("ensys_app", token, {
            cmd: "setSolutionPredict",
            data: commandPayload,
        })
            .then(() => {
                message.success("Operazione completata")
                reloadAlarms()
                setAddFaultModalOpen(false)
            })
            .catch((err) => {
                message.error("Operazione fallita")
                // eslint-disable-next-line
                console.log(err)
            })
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            <AppButton
                onClick={() => setAddFaultModalOpen(true)}
                className="create-btn"
            >
                Inserisci tipologia guasto
            </AppButton>
            <PageTitle>Allarmi intelligenti</PageTitle>
            <AppTable
                loading={alarmsLoading || machinesLoading || sensorsLoading}
                pagination={{ ...pagination, total: alarms?.length ?? 0 }}
                dataSource={alarms}
                rowKey={(i) => JSON.stringify(i)}
                hideHeader
                onChange={(pag) => setPagination(pag)}
                setPagination={setPagination}
                columns={getColumns()}
                disableSelection
            />
            <FaultsAndSolutionsModal
                open={addFaultModalOpen}
                onCancel={() => setAddFaultModalOpen(false)}
                onConfirm={handleAddFaults}
                rows={alarms}
            />
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Alarms.propTypes = {
    className: PropTypes.string.isRequired,
}

_Alarms.defaultProps = {}

// ----------------------------------------------------------------------------

const Alarms = styled(_Alarms)`
    & {
        display: flex;
        flex-direction: column;
        .create-btn {
            width: 300px;
            align-self: flex-end;
            margin-bottom: 20px;
        }
    }
`
// ----------------------------------------------------------------------------

export default Alarms

function getColumns() {
    return [
        {
            key: "sensor",
            title: "Sensore",
            dataIndex: "sensor",
            sorter: ({ sensor: a }, { sensor: b }) => a - b,
        },
        {
            key: "time",
            title: "Data",
            dataIndex: "time_begin",
            render: (time) => dayjs(time).format("DD/MM/YYYY HH:mm:ss"),
            sorter: ({ time: a }, { time: b }) =>
                dayjs(a).unix() - dayjs(b).unix(),
        },
        {
            key: "type",
            title: "Tipologia",
            dataIndex: "eventtype",
            sorter: ({ eventtype: a }, { eventtype: b }) => a.localeCompare(b),
        },
        {
            key: "guasto",
            title: "Guasto",
            dataIndex: "guasto",
            sorter: ({ guasto: a }, { guasto: b }) => {
                if (a && b) {
                    return a.localeCompare(b)
                }
                return 0
            },
        },
        {
            key: "solizione",
            title: "Soluzione guasto",
            dataIndex: "soluzione_guasto",
            sorter: ({ soluzione_guasto: a }, { soluzione_guasto: b }) => {
                if (a && b) {
                    return a.localeCompare(b)
                }
                return 0
            },
        },
    ]
}
